import { GridFilterOperator, useGridApiContext } from "@mui/x-data-grid";
import {RadioGroup, Radio, FormControlLabel} from '@mui/material';
import { useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Menu, IconButton } from '@mui/material';

export function CustomColumnMenu({columnField}: any){
    const [anchorEl, setAnchorEl] = useState(null);
    const apiRef = useGridApiContext();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const applyCustomFilter = (filterValue: any) => {
        apiRef.current.setFilterModel({
          items: [
            {
              columnField,
              operatorValue: 'custom',
              value: filterValue,
            },
          ],
        });
        apiRef.current.setState({...apiRef.current.state, filters: {columnField, value: filterValue}})
        handleClose();
    };


    return (
        <div style={{width: '100%', backgroundColor: 'white'}}>
          <div style={{textTransform: 'capitalize', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            {columnField}
            <IconButton onClick={handleClick}>
                <FilterAltIcon />
            </IconButton>
          </div>
          <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                <BooleanSearchComponent item={columnField} applyValue={applyCustomFilter}/>
          </Menu>
        </div>
    )

    
}

export const booleanSearchSurvey: GridFilterOperator<any, number>[] = [
    {
      label: 'Custom Filter',
      value: 'custom',
      getApplyFilterFn: (filterItem: any) => {
        if(filterItem.value === 'all' || filterItem.value === undefined){
          return null;
        }
        return (value) => {
          if(filterItem.value === 'true')
            return value?.row?.survey === true;
          else
            return value?.row?.survey === false;
        };
      },
      InputComponent: BooleanSearchComponent,
      InputComponentProps: { type: 'boolean' },
    },
];

export const booleanSearchExam: GridFilterOperator<any, number>[] = [
    {
      label: 'Custom Filter',
      value: 'custom',
      getApplyFilterFn: (filterItem: any) => {
        if(filterItem.value === 'all' || filterItem.value === undefined){
          return null;
        }
        return (value) => {
          if(filterItem.value === 'true')
            return value?.row?.exam === true;
          else
            return value?.row?.exam === false;
        };
      },
      InputComponent: BooleanSearchComponent,
      InputComponentProps: { type: 'boolean' },
    },
];


export function BooleanSearchComponent({item, applyValue}: any){
  
    const apiRef = useGridApiContext();

    const handleChange = (val: any) => {
      applyValue(val)
    }

    const getVal = () => {
        if((apiRef.current.state as any).filters !== undefined){
            if((apiRef.current.state as any).filters.columnField === item && (apiRef.current.state as any).filters.value  === 'true'){
                return true;
            }else if((apiRef.current.state as any).filters.columnField === item && (apiRef.current.state as any).filters.value === 'false'){
                return false;
            }else{
                return 'all';
            }
        }
        else{
            return 'all'
        }
    }
  
    return (
      <div style={{marginRight: 'auto', marginLeft: 'auto', paddingLeft: '15px'}}>
        <RadioGroup
          sx={{display: 'flex'}}
          defaultValue={getVal()}
          onChange={(e: any)=> handleChange(e.target.value)}
          value={item.value}
          name="radio-buttons-group"
        >
          <FormControlLabel control={<Radio />} value={true} label='True' sx={{borderRadius: '0'}} />
          <FormControlLabel control={<Radio />} value={false} label='False' sx={{borderRadius: '0'}} />
          <FormControlLabel control={<Radio />} value={'all'} label='All' sx={{borderRadius: '0'}} />
        </RadioGroup>
  
      </div>
    )
  
  }
