import {
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AttendanceContext } from "../../../../../../../context/AttendanceContext";
import { AttendanceStudentsContext } from "../../../../../../../context/AttendanceStudentsContext";


const sxTableCell: React.CSSProperties = {
    fontSize: "14px",
    fontFamily: 'Visby CF Bold, sans-serif',
    color: "#000000",
    padding:"12px 16px",
}

export default function Header() {

  return (
    <TableHead>
      <TableRow>
          <TableCell sx={[sxTableCell, { fontWeight: "700", width: '2%' }]} align="left">
            №
          </TableCell>
          <TableCell sx={[sxTableCell]} align="left">
              Müəllim / Müəllim köməkçisi / Mentor
          </TableCell>
          <TableCell sx={[sxTableCell, { width: '13%', position: 'relative'}]} align="left">
            Dərsdə iştirak vaxtı
          </TableCell>
      </TableRow>
    </TableHead>
  )
}