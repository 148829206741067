import Header from '../../../../components/core-components/header';
import { APP_PREFIX_PATH } from '../../../../configs/Appconfig';
import { DataGrid, getDefaultGridFilterModel, GridColDef, gridFilterModelSelector, GridFilterOperator } from '@mui/x-data-grid';
import { Checkbox } from '@mui/material';
import { useCreateExamSheet, useCreateSurveySheet, useGetAllExamModules } from '../../../../api/workers';
import uuid from 'react-uuid';
import Progress from '../../../../components/custom-components/progress';
import { useEffect, useState } from 'react';
import { booleanSearchExam, booleanSearchSurvey, CustomColumnMenu } from './BooleanFilters';
import { GroupNameSearch } from './GroupNameFilter';

const columns: GridColDef[] = [
  { 
    field: 'className', 
    headerName: 'Group Name',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: (params) => <GroupNameSearch columnField={params.field} />
  },
  { 
    field: 'programName', 
    headerName: 'Program Name',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true
  },
  { 
    field: 'moduleName', 
    headerName: 'Module Name',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true
  },
  { 
    field: 'startDate', 
    headerName: 'Start Date',
    flex: 1,
    disableColumnMenu: true
  },
  { 
    field: 'endDate', 
    headerName: 'End Date',
    flex: 1,
    disableColumnMenu: true
  },
  { 
    field: 'survey',
    headerName: 'Survey',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    filterOperators: booleanSearchSurvey,
    renderHeader: (params) => <CustomColumnMenu columnField={params.field} />,
    renderCell: (params) => {
        return (
          <SurveyCheckBox moduleId={params.row.moduleId} classId={params.row.classId} survey={params.row.survey} />
        );
    },
  },
  { 
    field: 'exam', 
    headerName: 'Exam',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    filterOperators: booleanSearchExam,
    renderHeader: (params) => <CustomColumnMenu columnField={params.field} />,
    renderCell: (params) => {
        if(params.row.programName === "Programming Backend" || params.row.programName === "Programming Frontend"){
            return (
              <ExamCheckBox moduleId={params.row.moduleId} classId={params.row.classId} exam={params.row.exam} />
            );
        }else{
            return(
                <span>-</span>
            )
        }
    },
},
]

export default function Index() {
    const {data: data, isLoading} = useGetAllExamModules();
    const [flattenedData, setFlattenedData] = useState<any>([]);

    useEffect(()=> {
        if(data){
            setFlattenedData(flattenModules(data));
        }
    }, [data]);

  return (
    <>
        <Header links={[{name: 'Home', url: APP_PREFIX_PATH}]} current='Exam Survey Planning'/>
        {
            isLoading ? (
                <Progress/>
            ) : 
            data ? (
                <DataGrid
                  rows={flattenedData}
                  columns={columns}
                  rowsPerPageOptions={[20, 50, 100]}
                  sx={{height: '75vh', 
                    '& .MuiDataGrid-filterIcon': {
                        display: 'none', // Hide the filter icon
                    },
                  }}
                />
            ) : null
            
        }
    </>
  )
}

function SurveyCheckBox({moduleId, classId, survey}: any){
  const mutation = useCreateSurveySheet((oldData: any, newData: any) => newData);
  
  const handleChange = async (e: any)=> {
      try{
          const res = await mutation.mutateAsync([{moduleId: moduleId, classId: classId, isSurvey: e.target.checked}])
      }catch(err: any){
          console.log(err)
      }
  }
  return (
      <Checkbox
        defaultChecked={survey}
        onChange={handleChange}
        sx={{ padding: "0" }}
      />
  )
}

function ExamCheckBox({moduleId, classId, exam}: any){
    const mutation = useCreateExamSheet((oldData: any, newData: any) => newData);
    
    const handleChange = async (e: any)=> {
        try{
            const res = await mutation.mutateAsync([{moduleId: moduleId, classId: classId, isExam: e.target.checked}])
        }catch(err: any){
            console.log(err)
        }
    }

    return (
        <Checkbox
          defaultChecked={exam}
          onChange={handleChange}
          sx={{ padding: "0" }}
        />
    )
}


const flattenModules = (data: any) => {
    return data?.flatMap((classData: any) =>
      classData.modules?.map((module: any) => ({
        id: uuid(),
        classId: classData.classId,
        moduleId: module.moduleId,
        className: classData.className,
        programName: classData.programName,
        moduleName: module.modulName,
        startDate: module.startDate,
        endDate: module.endDate,
        survey: module.isSurvey || false,
        exam: module.isExam || false,
      }))
    );
  };