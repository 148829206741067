import { FormControl } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { sxFormControl } from "../../../pages/app-views/HumanResourcesManagement/Holidays/styles";
import { sxDate } from "../../../pages/app-views/MrpManagement/mrp/filter/style";
import azLocale from "dayjs/locale/az";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Controller } from "react-hook-form";

interface IDatePickerModel{
    defaultValue?: any,
    name: any,
    control: any,
    label: string,
    error?: any
}

export default function Index({defaultValue, name, control, label, error=false}: IDatePickerModel) {
    let capitalizedMonthNames: any[] = [];
    if (azLocale.months && Array.isArray(azLocale.months)) {
      capitalizedMonthNames = azLocale.months.map((monthName) => {
        return monthName.charAt(0).toUpperCase() + monthName.slice(1);
      });
    }

    const customAzLocale = {
        ...azLocale,
        months: capitalizedMonthNames,
    };

    function DateIcon() {
        return <CalendarTodayOutlinedIcon color="primary" />;
    }

  return (
    <Controller
        render={({ field: { onChange, value } }) => (
            <FormControl fullWidth sx={sxFormControl}>
              <LocalizationProvider
                locale={customAzLocale}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={label}
                  format="DD MMMM YYYY"
                  value={value}
                  onChange={onChange}
                  sx={{...sxDate}}
                  components={{
                    OpenPickerIcon: DateIcon,
                  }}
                />
              </LocalizationProvider>
            </FormControl>
        )}
        defaultValue={defaultValue}
        name={name}
        control={control}
    />
    
  )
}
