export const sxModalContainer = {
  "& .MuiDialog-paper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "32px",
    padding: "32px",
    borderRadius: "16px",
    background: "#FFF",
    width: "600px",
    boxShadow: "0px 23px 39px 0px rgba(0, 0, 0, 0.08)",
  },
};
export const sxTitleBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
};
export const sxModalTitle = {
  fontFamily: "Visby CF Bold",
  fontSize: "34px",
  lineHeight: "40px",
  letterSpacing: "0.4px",
  padding: 0,
};
export const sxModalDescription = {
  fontFamily: "Visby CF Regular",
  fontSize: "14px",
  padding: 0,
}
export const sxModalBottom={
  borderRadius: "12px",
  fontFamily: "Visby CF Bold, sans-serif",
  textTransform: "none",
}
