import React from "react";
import { Box } from "@mui/material";
import Breadcrumbs from "../../custom-components/breadcrumbs";
import BreadcrumbsLink from "../../custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../custom-components/page-title";

interface IHeaderModel{
    links: ILinkModel[],
    current: string
}

interface ILinkModel{
    name: string,
    url: string
}

function Header({links, current} : IHeaderModel) {
  return (
    <>
      <Box
        marginBottom={"24px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Breadcrumbs>
          {
            links.map((elem: any, index: number)=> (
                <BreadcrumbsLink key={index} name={elem.name} url={elem.url} />
            ))
          }
            <BreadcrumbsTypography name={current} />
          </Breadcrumbs>
          <PageTitle name={current} />
        </Box>
      </Box>
    </>
  );
}

export default Header;