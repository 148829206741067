import { Box} from "@mui/system";
import React, { useState } from "react";
import {Typography} from "@mui/material"

export interface ModalProps {
    open?: boolean;
    value?: number;
    onSelectStatus?: (status: string) => void; 
}

export default function StatusModal({ open, value,onSelectStatus}: ModalProps) { 

    const isOdd = value !== undefined && value % 2 !== 0;
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

    const handleStatusSelect = (status: string) => {
      if (onSelectStatus) {
          onSelectStatus(status);
      }
  };

    return (
        <>
            {open && isOdd && (
                

                <Box
                  sx={{
                    display: "flex",
                    width:"160px",
                    padding: "8px 10px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "9px",
                    borderRadius: "8px",
                    background: "#FFF",
                    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                    marginLeft:"780px",
                    position:"absolute",
                    zIndex:"1",
                    marginTop:"-7px",
                    cursor:"pointer"
                  }}
                >
                  {/* Gözləmədə */}
                  <Box
                  onClick = {()=>handleStatusSelect("wait")}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px", // space between the svg and text
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#E7873C" />
                    </svg>
                    <Typography
                    
                      sx={{
                        color: "var(--Natural-natural-800, #131112)",
                        fontFamily: "Visby CF Bold",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                        
                      }}
                    >
                      Gözləmədə
                    </Typography>
                    
                  </Box>
                  <div style={{width: "140px",  
                                 height: "1px",
                                 background:" #E9E9E9"}}   ></div>
                
                  {/* Təsdiqlənib */}
                  <Box
                  onClick = {()=>handleStatusSelect("apply")}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#4BA458" />
                    </svg>
                    <Typography
                      sx={{
                        color: "var(--Natural-natural-800, #131112)",
                        fontFamily: "Visby CF Bold",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                      }}
                    >
                      Təsdiqlənib
                    </Typography>
                  </Box>
                  <div style={{width: "140px",  
                                 height: "1px",
                                 background:" #E9E9E9"}}   ></div>
                
                  {/* Qəbul edilməyib */}
                  <Box
                  onClick = {()=>handleStatusSelect("reject")}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#D54939" />
                    </svg>
                    <Typography
                      sx={{
                        color: "var(--Natural-natural-800, #131112)",
                        fontFamily: "Visby CF Bold",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                      }}
                    >
                      Qəbul edilməyib
                    </Typography>
                  </Box>
                </Box>
                
            )}
        </>
    );
}
