import { Fragment, Key, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Box, Drawer, Checkbox } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Card from './components/card/card';
import Header2 from './components/header/header2';
import { datagridSx } from './components/datagrid/datagrid';
import CustomPagination from './components/pagination/CustomPagination';
import PageTitle from '../../../../../components/custom-components/page-title';
import Breadcrumbs from '../../../../../components/custom-components/breadcrumbs';
import BreadcrumbsLink from '../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink';
import BreadcrumbsTypography from '../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography';
import PageHeader from '../../../../../components/custom-components/page-header';
import { APP_PREFIX_PATH } from '../../../../../configs/Appconfig';
// import Modal from './components/modal/Modal';
import Dialog from "@mui/material/Dialog";
import Popup from './components/grouppopup';
import { useGetAllRequests, useGetMyRequests, useGetRequests, useOpenAction } from '../../../../../api/requests';
import { formatDate } from '../../../../../utils/dateFunctions';
import { Request } from './interfaces/interface';
import { GetAcademicColumns } from './components/columns';
import { useSelector } from 'react-redux';
import { UserHasPermission } from '../../../../../utils/hooks/userPermissions';
import { allLeaveRequestsView, myLeaveRequestsView } from '../../../../../permissions';
import Modal from './_components/modal';
import { useSearchParams } from 'react-router-dom';
import { useGetStudentAttendanceLimit } from '../../../../../api/students';
import AttendanceCard from '../myrequests/_components/attendanceLimitCard';


export default function Myrequests() {
  const userRole = useSelector((state: any) => state.auth.user.roles[0]);
  const [crumb, setCrumb] = useState("");
  const {data:currentRequests, refetch: refetchCurrentRequests} = useGetRequests(true);
  const [requests, setRequests] = useState<any>([]);
  const [currents, setCurrents] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredSorgular, setFilteredSorgular] = useState<any>([]);
  const [isEditableFilter, setIsEditableFilter] = useState<any>({isEditable: null});
  
  const {data: allRequests, refetch: refetchAll, dataUpdatedAt} = useGetAllRequests(isEditableFilter);
  const {data: myRequests, refetch: refetchMy} = useGetMyRequests();
  const {data: attendanceLimit, refetch: refetchAttendanceLimit} = useGetStudentAttendanceLimit(false);
  const canUserViewAllPermissions = UserHasPermission(allLeaveRequestsView);
  const canUserViewHisPermissions = UserHasPermission(myLeaveRequestsView);

  useEffect(()=> {
    if(userRole === 'Student Office')
    {
      setCrumb("Tələbə icazələri");
    }
    else if (userRole === 'Müəllim')
    {
      setCrumb("Mənim icazələrim");
    }

    else if (userRole === 'Akademik şöbə')
    {
      setCrumb("");
    }

    else if (userRole === 'Proqram rəhbəri')
    {
      setCrumb("Müəllim icazələri")
    }

    else if (userRole === 'Tələbə')
      {
        setCrumb("Tələbə icazələri")
      }
    
  }, [userRole])

  useEffect(()=> {
      if( Array.isArray(allRequests)) {
        setRequests(
          allRequests?.map((req: any) => ({
            id: req.id,
            title: req.title,
            status: req.status,
            startdate: req.startDate,
            enddate: req.endDate,
            description: req.type.name,
            files: req.files,
            isTargetPermissionGroupActionAccess: req.isTargetPermissionGroupActionAccess,
            requesterUser: `${req.requesterUser?.name} ${req.requesterUser?.surname}`,
            type: req.type,
            percentage: 0,
            note: req.note,
            role: req.postion,
            isEditable: req.isEditable,
            classes: req.classes
        
          }))
        )
      }
  }, [allRequests, dataUpdatedAt])

  useEffect(()=> {
    if(myRequests) {
      setRequests(
        myRequests?.map((req: any) => ({
          id: req.id,
          title: req.title,
          status: req.status,
          startdate: req.startDate,
          enddate: req.endDate,
          description: req.type.name,
          files: req.files,
          isTargetPermissionGroupActionAccess: req.isTargetPermissionGroupActionAccess,
          requesterUser: `${req.requesterUser?.name} ${req.requesterUser?.surname}`,
          type: req.type,
          percentage: 0,
          note: req.note,
          isEditable: req.isEditable
        }))
      )
    }
  }, [myRequests, searchParams])

  useEffect(()=> {
    if(currentRequests){
      setCurrents(
        currentRequests?.map((req: any) => ({
          id: req.id,
          title: req.title,
          status: req.status,
          startdate: req.startDate,
          enddate: req.endDate,
          description: req.type.name,
          files: req.files,
          isTargetPermissionGroupActionAccess: req.isTargetPermissionGroupActionAccess,
          requesterUser: `${req.requesterUser?.name} ${req.requesterUser?.surname}`,
          type: req.type,
          percentage: 0,
          note: req.note,
          totalDays: req.totalDays,
          isEditable: req.isEditable
        }))
      )
    }
  }, [currentRequests])

  useEffect(()=> {
    if(userRole === "Tələbə"){
      refetchAttendanceLimit();
    }
  }, [userRole])

  useEffect(()=> {
    if(canUserViewAllPermissions && searchParams.get("pr") !== "true"){
      refetchAll();
    }else if(canUserViewHisPermissions || searchParams.get("pr") === "true" ){
      refetchMy();
      refetchCurrentRequests();
    }
  }, [searchParams])

  const [pageSize, setPageSize] = useState<number>(3);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = useState(1);
  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [request, setRequest] = useState<Request>();
  const [needsOpen, setNeedsOpen]=useState<boolean>(false);

  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const rowHeightRef = useRef(84);
  const headerHeightRef = useRef(44);
  const footerHeightRef = useRef(56);

  const rowCount = useMemo(() => pageSize + 0.7, [pageSize]);
  const boxHeight = useMemo(() => {
    return (
      rowHeightRef.current * rowCount +
      headerHeightRef.current +
      footerHeightRef.current
    );
  }, [rowCount]);

  useEffect(()=> {
    if(userRole !== "Müəllim" && userRole !== "Mentor" && userRole !== "Tələbə" && searchParams.get("pr") !== "true" ){
      refetchAll()
    }
  }, [isEditableFilter])

  useEffect(()=> {
    if(requests){
      setFilteredSorgular(
        requests
        ?.filter(
          (request: any) =>
            request.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            request?.requesterUser.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .filter(
          (request: any) =>
            !selectedStatus || selectedStatus.includes(request.status) 
        )
      )
    }
  }, [requests, selectedStatus,searchQuery, searchParams ])

  const filteredSorgularbyDate = currents?.filter((request: any) => {
    return (request)
  });

  const totalRows = filteredSorgular?.length || 0;
  const totalPages = useMemo(() => Math.ceil(totalRows / pageSize), [totalRows, pageSize]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchParams(searchParams.get("pr") === "true" ? {pr: "true"} : {})
    setSelectedRequest(null);
  };

  useEffect(()=> {
    if(searchParams.get("id") && requests?.find((elem: any)=> elem.id == searchParams.get("id")) ){
      setRequest(requests?.find((elem: any)=> elem.id == searchParams.get("id")));
      setOpenModal(true);
    }
  }, [searchParams, requests])

  const handleRowClick = (params: any) => {
    if (params.field === 'title' || params.field === 'startdate' || params.field === 'status' || params.field === 'note'|| params.field === 'role' || params.field === 'requesterUser') {
      setRequest(params.row);
      setSearchParams({ pr: searchParams.get("pr")!, id: params.row?.id})
      setOpenModal(true);
    }
  };


  const [selectedClassId, setSelectedClassId] = useState<any>(null);

  const handleGroupClick = (classId: number) => {
    {
      setOpenModal(false);
      setOpenPopUp(true);
      setSelectedClassId(classId);
    }
  }

  const handleOpenModal = async (open: boolean, request: any) => {
      setNeedsOpen(true);
      setRequest(request);
      setOpenModal(open);
  };

  useEffect(() => {
    // Eğer filteredSorgularbyDate boş değilse pageSize = 3, boşsa pageSize = 5
    if (filteredSorgularbyDate && filteredSorgularbyDate.length > 0) {
      setPageSize(3);
    } else {
      setPageSize(5);
    }
  }, [filteredSorgularbyDate]);

  const shouldShowPageTitle = filteredSorgularbyDate && filteredSorgularbyDate.length > 0;

 
    const handleStatusChange = (status: string | null) => {
      if (status) {
        const statuses = status.split(","); // Virgülle ayrılmış stringi diziye çevir
        setSelectedStatus(status);
      } else {
        setSelectedStatus(null); // Statü seçilmediyse null yap
      }
    };

  return (
    <Box>
      <PageHeader>
        <Box>
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="İcazələr" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name={ searchParams.get("pr") !== "true" ? crumb : "Mənim icazələrim"} />
          </Breadcrumbs>
          <Box>
            {shouldShowPageTitle ? <PageTitle name="Cari icazələr" /> : null}

          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}></Box>
      </PageHeader>
      {
        userRole === "Mentor" || userRole === "Müəllim" || userRole === "Tələbə" ? (
          <Grid 
            container
            spacing={2}
            // style={{height: '200px', overflowX: 'scroll'}}
          >
            {filteredSorgularbyDate?.map((elem: {
              status: string | null;
              description: string | null;
              enddate: string;
              startdate: string;
              title: string | null; id: string | null;
              files: string[];
              type: string;
              percentage: number;
              totalDays: string;
            },
              key: Key | null | undefined) => (
              <Fragment key={key}>
                
                <Grid item xs={12} md={3} lg={3} sx={{width: '20%'}} >
                  <Card
                    status={elem.status}
                    enddate={formatDate(elem.enddate)}
                    startdate={formatDate(elem.startdate)}
                    title={elem.title}
                    totalDays={elem.totalDays}
                    id={elem.id}
                    description={elem.description}
                    files={elem.files}
                    openModal={() => handleOpenModal(true, elem)}
                    percentage={elem.percentage}
                    type={elem.type} />
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={12} md={3} lg={3} sx={{width: '20%'}} >
                  {
                    userRole === "Tələbə" && (attendanceLimit as any)?.programName && (attendanceLimit as any)?.totalLimit > 0 ? (
                      <AttendanceCard title={(attendanceLimit as any)?.programName ?? "-"} percentage={ ((attendanceLimit as any)?.leaveRequestCount && (attendanceLimit as any)?.totalLimit) ? Math.floor(((attendanceLimit as any)?.leaveRequestCount * 100) / (attendanceLimit as any)?.totalLimit) : 0 } total={(attendanceLimit as any)?.totalLimit ?? '-'} />
                    ) : null
                  }
                </Grid>
          </Grid>
        ) : null
      }

      <Header2 searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedStatus={selectedStatus}
        onStatusChange={handleStatusChange} />

      <Box sx={{ width: "100%", height: boxHeight, border: '0px' }}>
        {
          userRole === 'Akademik şöbə' ? (
            <div style={{width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '20px', alignItems: 'center'}}>
              <Checkbox checked={isEditableFilter.isEditable} onChange={(e: any)=> setIsEditableFilter({isEditable: e.target.checked ? true : null})} />
              <span>Təsdiq gözləyən icazələr</span>
            </div>
          ) : null
        }
        <DataGrid
          rows={filteredSorgular?.slice((page - 1) * pageSize, page * pageSize) || []}
          columns={ searchParams.get("pr") === "true" ? GetAcademicColumns(handleRowClick, handleGroupClick) : GetAcademicColumns(handleRowClick, handleGroupClick) }
          pageSize={pageSize}
          rowHeight={rowHeightRef.current}
          headerHeight={headerHeightRef.current}
          disableSelectionOnClick
          onCellClick={handleRowClick}
          sx={{
            ...datagridSx,
            border: 'none', width: "110%", right: '100px', cursor: "pointer", overflowX: "auto"
          }}
          disableColumnFilter
          disableColumnMenu
          components={{

            Pagination: () => (
              <CustomPagination
                count={totalPages}
                page={page}
                onPageChange={handlePageChange}
              />
            ),
          }}

        />
      </Box>
      <Drawer
        anchor="right"
        open={openModal}
        PaperProps={{
          sx: { width: "30%", minWidth: '500px' },
        }}
        onClose={handleCloseModal}
      >
        <Modal
          onClose={handleCloseModal}
          request={request}
          needsOpen = {needsOpen}
        />

      </Drawer>
      <Dialog open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            borderRadius: "24px",
          },
        }}
      >
        <Popup
          open={openPopUp}
          onClose={() => setOpenPopUp(false)}
          classId={selectedClassId}
        />
      </Dialog>
    </Box>
  );
}


