import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Popper,
  FormControl,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import { BootstrapDialog } from "./style";
import InputAdornment from "@mui/material/InputAdornment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Module } from "../../../pages/app-views/shared/continuity/models/Module";
import { AddInput } from "../../../pages/app-views/shared/continuity/models/AddInput";
import { HeldModule } from "../../../pages/app-views/shared/continuity/models/HeldModule";
import { CategoryHours } from "../../../pages/app-views/shared/continuity/models/CategoryHours";

interface ModalComponentProps {
  open: boolean;
  onClose: () => void;
  addInputs: AddInput[];
  setAddInputs: React.Dispatch<React.SetStateAction<AddInput[]>>;
  modules: Module[];
  setHeldModules: React.Dispatch<React.SetStateAction<HeldModule[]>>;
  categoryHours: CategoryHours[];
  // heldModules:HeldModule[]
}
export const AddModuleModal: React.FC<ModalComponentProps> = ({
  open,
  onClose,
  modules,
  addInputs,
  setAddInputs,
  setHeldModules,
  categoryHours,
}) => {
  const [selectedModuleIds, setSelectedModuleIds] = useState<number[]>([]);

  const options = (modules as any)?.modules ? (modules as any).modules?.reduce((acc: any, module:any) => {
    // Ana modülü başlık olarak ekleyelim
    acc.push({
      id: module.id,
      moduleName: `${module.version} - ${module.name}`,
      isGroup: true, // Ana modülü belirtmek için bir özellik ekleyin
    });

    // Eğer alt modüller varsa, onları da ekleyelim
    if (module.subModules && module.subModules.length > 0) {
      module.subModules.forEach((subModule: any) => {
        acc.push({
          id: subModule.id,
          moduleName: `${subModule.version} - ${subModule.name}`,
          subModuleName: `${subModule.version} - ${subModule.name}`,
          isSubModule: true, // Alt modülü belirtmek için bir özellik ekleyin
        });
      });
    }

    return acc;
  }, [] as { id: number; moduleName: string; subModuleName?: string; isGroup?: boolean; isSubModule?: boolean }[]) : [];
  const handleSelectChange = (
    index: number,
    moduleId: number,
    moduleName: string
  ) => {
    const newInputValues = [...addInputs];
    newInputValues[index].moduleId = moduleId;
    newInputValues[index].moduleName = moduleName;
    setAddInputs(newInputValues);
  };
  const handleHourChange = (index: number, hour: string) => {
    const newInputValues = [...addInputs];
    newInputValues[index].totalHours = +hour;
    setAddInputs(newInputValues);
  };
  const handleAddInput = () => {
    setAddInputs([...addInputs, {}]);
  };

  const handleRemoveInput = (index: number) => {
    const newInputValues = [...addInputs];
    newInputValues.splice(index, 1);
    setAddInputs(newInputValues);
  };

  const handleSave = () => {
    setHeldModules(addInputs);
    onClose();
  };
  useEffect(() => {
    const ids = addInputs?.map((input) => input.moduleId ?? 0);
    setSelectedModuleIds(ids);
  }, [addInputs]);
  const filteredOptions = options?.filter(
    (option: any) => !selectedModuleIds.includes(option.id)
  );
 
  const isGreatherThanTotalSessionHour = () => {
    const totalHours=addInputs?.reduce((sum,x)=>sum+x.totalHours!,0)
    return (
      totalHours<1 || totalHours >
      (categoryHours.find((item) => item.category === "Theoric")?.hour ||
        categoryHours.find((item) => item.category === "Practice")?.hour ||
        categoryHours.find((item) => item.category === "Lab")?.hour ||
        0)
    );
  };

  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          className="closeBtn"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: 24,
            height: 24,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DialogTitle id="customized-dialog-title">
            Modulu daxil edin
          </DialogTitle>
          <Typography gutterBottom>
            Bugün keçirilmiş modul və ya əlavə dərsi daxil edin, zəhmət olmasa.
          </Typography>
        </DialogContent>
        <DialogContent>
          {addInputs?.map((item, index) => (
            <Box key={index} display="flex" alignItems="center" gap="8px">
              <Autocomplete
                value={
                  options?.find((module: any) => module.id === item.moduleId) || null
                }
                id={`grouped-demo-${index}`}
                options={filteredOptions}
                groupBy={(option) => (option.isGroup ? option.moduleName : "")}
                getOptionLabel={(option) =>
                  option.isGroup
                    ? option.moduleName
                    : option.subModuleName || ""
                }
                sx={{
                  width: 330,
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Modulu seçin və yazın" />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => {
                  if (!option.isGroup) {
                    return (
                      <li key={option.id.toString()} {...props}>
                        {option.moduleName}
                      </li>
                    );
                  }
                  return null;
                }}
                onChange={(event, item) => {
                  if (item) {
                    handleSelectChange(index, item.id, item.subModuleName!);
                  }
                }}
              />
              <TextField
                sx={{
                  width: "135px",
                  "& .MuiInputBase-input": {
                    padding: "12px 24px",
                    color:isGreatherThanTotalSessionHour()?"red":""
                  },
                  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  '& input[type="number"]': {
                    MozAppearance: "textfield",
                  },
                }}
                type="number"
                placeholder="Saat"
                value={item.totalHours===0?undefined:item.totalHours}
                onChange={(ev: any) => handleHourChange(index, ev.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                onClick={() => handleRemoveInput(index)}
                sx={{
                  display: addInputs.length > 1 ? "block" : "none",
                  padding: "0",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
               <img src="/icons/remove-recyecle.svg" />
              </IconButton>
            </Box>
          ))}
          <Typography
            onClick={() => handleAddInput()}
            className="addModule"
            component="span"
          >
            + Digər modul əlavə et
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              addInputs.some((x) => x.moduleId===undefined) ||
              addInputs.some((x,index) => x.totalHours===undefined || x.totalHours!<0 ||x.totalHours!<0 ||isGreatherThanTotalSessionHour())
            }
            variant="contained"
            className="saveBtn"
            sx={{
              background: "#854693",
              color: "#FFF",
              padding: "8px 24px",
              borderRadius: "12px",
              fontFamily: "Visby CF Bold, sans-serif",
              textTransform: "none",
              "&:hover": {
                background: "#6e3d7d",
              },
            }}
            onClick={handleSave}
          >
            Yadda saxla
          </Button>
          <Button
            className="clsBtn"
            sx={{
              background: "#F3EDF4",
              padding: "8px 24px",
              borderRadius: "12px",
              fontFamily: "Visby CF Bold, sans-serif",
              textTransform: "none",
            }}
            onClick={onClose}
          >
            Bağla
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
