import LessonPage from '../LessonPage';
import LabPage from '../LabPage';
import { useGetSessionClassById, useGetSessionClassByIdAdmin } from '../../../../../../api/classes';
import StudentsTable from '../StudentsTable';
import { useEffect, useState, useContext } from 'react';
import { AttendanceStudentsContext } from '../../../../../../context/AttendanceStudentsContext';
import { AttendanceContext } from '../../../../../../context/AttendanceContext';
import Progress from '../../../../../../components/custom-components/progress';
import  TeacherMentorAttendance from '../../admin/components/TeacherMentorAttendance';
import { useParams } from 'react-router-dom';

export default function Index({tabValue, currentClass, isAdmin = false}: any) {

    const {date} = useParams();

    const [fetchedClass, setFetchedClass] = useState<any>();
    const {data: session, isLoading, refetch} = useGetSessionClassById(currentClass.id, tabValue === 0 ? 'Theoric' : 'Lab', false);
    const {data: sessionAdmin, isLoading: isLoadingAdmin, refetch: refetchAdmin} = useGetSessionClassByIdAdmin(currentClass.id, date as any, tabValue === 0 ? 'Theoric' : 'Lab' , false);
    const {students, setStudents, setOriginalData} = useContext(AttendanceStudentsContext);
    const {addTotalHours, setStartTime, setEndTime, setIsActive, setIsJoined, setWorkers, setModules} = useContext(AttendanceContext);

    useEffect(()=> {
        if(isAdmin){
            refetchAdmin();
        }else{
            refetch();
        }
    }, [isAdmin, date, tabValue, currentClass])

    useEffect(()=> {
        if(sessionAdmin){
            setStudents(sessionAdmin?.studyAttendances?.map((student: any)=> {return {...student, note: student.note, totalHours: student.totalHours, isChecked: false, isDisabled: student.status === "Excused"}}));
            setOriginalData(sessionAdmin?.studyAttendances?.map((student: any)=> {return {...student, note: student.note, totalHours: student.totalHours, isChecked: false, isDisabled: student.status === "Excused"}}));
            addTotalHours(sessionAdmin?.sessionTotalHours);
            setWorkers(sessionAdmin.workers);
            setModules(sessionAdmin.heldModules ?? []);
            setFetchedClass(sessionAdmin);
        } else if(session){
            setStudents(session.studyAttendances?.map((student: any)=> {return {...student, note: student.note, totalHours: student.totalHours, isChecked: false, isDisabled: student.status === "Excused"}}));
            setOriginalData(session.studyAttendances?.map((student: any)=> {return {...student, note: student.note, totalHours: student.totalHours, isChecked: false, isDisabled: student.status === "Excused"}}));
            addTotalHours(session.sessionTotalHours);
            // addTotalHours(0);
            setStartTime(session.startTime);
            setEndTime(session.endTime);
            setIsActive(false)
            setModules(session.heldModules);
            setIsJoined(session.workers?.find((x: any)=> x.workerType === "Mentor")?.isJoined);
            setFetchedClass(session)
        }
    }, [sessionAdmin, session])

  return (
    <div>
        {
            (isLoading || isLoadingAdmin) ? (
                <Progress/>
            ) :
            (fetchedClass?.studyAttendances) ?
            (
                <>
                    {
                        tabValue === 0 ? (
                            <>
                            {
                                (fetchedClass?.category !== "Theoric") ? (
                                    <span>No lesson for today</span>
                                ) : (
                                    <>
                                        {
                                            isAdmin ? (
                                                <TeacherMentorAttendance
                                                    teacher={fetchedClass.workers?.find((x: any)=> x.workerType === "Teacher")}
                                                    mentor={fetchedClass.workers?.find((x: any)=> x.workerType === "Mentor")}
                                                    maxHours={fetchedClass.sessionTotalHours}
                                                />
                                            ) : (
                                                <div style={{marginBottom: '30px'}}>
                                                    <LessonPage canStarted={fetchedClass.startTime ? false : fetchedClass.canStarted } refetch={refetch} programId={currentClass.programId} mentor={fetchedClass?.workers.find((x: any)=> x.workerType === "Mentor")} teacher={fetchedClass?.workers?.find((x: any)=> x.workerType === "Teacher")} classId={currentClass.id} classTimeSheetId={fetchedClass?.classTimeSheetId}/>
                                                </div>
                                            )
                                        }
                                        <StudentsTable type={'Theory'} students={students}/>
                                    </>
                                )
                            }
                            </>
                        ) : (
                            <>
                            {
                                (fetchedClass?.category !== "Lab") ? (
                                    <span>No lab for today</span>
                                ) : (
                                    <>
                                    {
                                        isAdmin ? (
                                            <TeacherMentorAttendance
                                                teacher={fetchedClass.workers?.find((x: any)=> x.workerType === "Teacher")}
                                                mentor={fetchedClass.workers?.find((x: any)=> x.workerType === "Mentor")}
                                                maxHours={fetchedClass.sessionTotalHours}
                                            />
                                        ) : (
                                            <div style={{marginBottom: '30px'}}>
                                                <LabPage refetch={refetch} isLessonActive={ fetchedClass.endTime ? true : fetchedClass.startTime ? false : !fetchedClass.canStarted } classId={currentClass.id} mentor={fetchedClass?.workers?.find((x: any)=> x.workerType === "Mentor")} teacher={fetchedClass?.workers?.find((x: any)=> x.workerType === "Teacher")} classTimeSheetId={fetchedClass?.classTimeSheetId}/>
                                            </div>
                                        )
                                    }
                                        <StudentsTable type={'Lab.'} students={students}/>
                                    </>
                                 )
                            }
                                
                            </>
                        )
                    }
                </>
            ) 
            : (
                <div>Sessiya yoxdur!</div>
            )
        }
        
    </div>
  )
}
