import {
  useDelete,
    useFetch, usePost, useUpdate,
} from '../utils/api/reactQuery';
import { apiRoutes } from '../apiRoutes';
import { IWorkerLoginClassModel } from '../types';

export const useGetAllExamModules = () =>
useFetch<any[]>(apiRoutes.workers.allExamModules);

export const useCreateExamSheet = (
updater: (oldData: any, newData: any) => any) => 
usePost<any, any>(apiRoutes.workers.createExamSheet, undefined, updater);

export const useCreateSurveySheet = (
updater: (oldData: any, newData: any) => any) => 
usePost<any, any>(apiRoutes.workers.createSurveySheet, undefined, updater);

export const useAddWorker = () => 
  usePost<any,any>(apiRoutes.workers.addWorker,undefined);

export const useGetWorkerLoginClass = () =>
useFetch<IWorkerLoginClassModel[]>(apiRoutes.workers.loginClasses, undefined, [apiRoutes.workers.base, undefined]);