import {
    useDelete, 
      useFetch, usePost, useUpdate 
   } from "../utils/api/reactQuery";
import { apiRoutes } from "../apiRoutes";
// import { IHolidayModel } from '../types';
import { HolidayModel } from "../pages/app-views/HumanResourcesManagement/Holidays/models";

export const useGetHolidaysList = () =>
  useFetch<any[]>(apiRoutes.holidays.getAll, undefined, [apiRoutes.holidays.base, undefined]);

export const useDeleteHoliday = () => 
  useDelete(apiRoutes.holidays.delete);

export const useCreateHoliday = ( () => 
  usePost<any, HolidayModel>(apiRoutes.holidays.create, undefined));

export const useUpdateHoliday = (id: number, updater: (oldData: any, newdata: any)=> any) => 
  useUpdate<HolidayModel, HolidayModel>(apiRoutes.holidays.update(id), undefined, updater);

export const useGetHolidayById = (id: number) => 
  useFetch<any>(apiRoutes.supports.getById(id));