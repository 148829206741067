import ModalHeader from "../components/modal-header";
import { Button, Typography, Snackbar, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { sxBtn } from "../style";
import TableSetProgram from "./components/table-set-program";
import { useState, useEffect } from "react";
import { ClassService } from "../../../../../network/services/classService/ClassService";
import Progress from "../../../../../components/custom-components/progress";
import { BaseService } from "../../../../../network/services/base/BaseService";
import React from 'react';

interface ISetProgramDetailModel {
  closeSetProgram: any;
  classId: string;
  selectedSeans: string;
  closeModal: any;
  groupName: string;
  studentsHeaderDetail: any;
}

const classService = new ClassService();
const baseService = new BaseService("api");

export default function Index({
  closeSetProgram,
  classId,
  selectedSeans,
  closeModal,
  groupName,
  studentsHeaderDetail,
}: ISetProgramDetailModel) {
  const [modules, setModules] = useState<any[]>([]);
  const [newModules, setNewModules] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    text: "",
  });

  const [boxHeight, setBoxHeight] = useState<number>(180);

  useEffect(() => {
    // Set initial height based on screen width
    if (window.innerWidth >= 1920) {
      setBoxHeight(400);
    } else {
      setBoxHeight(180);
    }

    // Update height on window resize
    const handleResize = () => {
      if (window.innerWidth >= 1920) {
        setBoxHeight(300);
      } else {
        setBoxHeight(180);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  useEffect(() => {
    getData();
    async function getData() {
      setLoading(true);
      let teacherId: any;
      let mentorId: any;
      async function getRoles() {
        const res = await baseService.getAll(`api/Roles`);
        mentorId = res.data.find((x: any) => x.name === "mentor").id;
        teacherId = res.data.find((x: any) => x.name === "muellim").id;
      }
      await getRoles();
      classService
        .getById(`${classId}/modules-workers?sessionId=${selectedSeans}`)
        .then((res: any) => {
          res.data.modules = res.data.modules.sort(
            (a: any, b: any) => parseInt(b?.version) - parseInt(a?.version)
          );
          res.data.modules = res.data.modules.map((item: any) => {
            item.subModules.sort((a: any, b: any) => {
              return (
                parseInt(b?.version.split(".")[1]) -
                parseInt(a?.version.split(".")[1])
              );
            });
            if (!item.workers.some((x: any) => x.role === "muellim")) {
              item.workers.push({ role: "muellim", roleId: teacherId });
            }

            if (!item.workers.some((x: any) => x.role === "mentor")) {
              item.workers.push({ role: "mentor", roleId: mentorId });
            }
            item.subModules = item.subModules?.map((elem: any) => {
              if (!elem.workers.some((x: any) => x.role === "muellim")) {
                elem.workers.push({ role: "muellim", roleId: teacherId });
              }

              if (!elem.workers.some((x: any) => x.role === "mentor")) {
                elem.workers.push({ role: "mentor", roleId: mentorId });
              }
              return { ...elem, isChecked: false };
            });
            item.isChecked = false;
            return item;
          });

          setModules(res.data.modules);
          setNewModules(
            res.data.extraModules?.map((elem: any) => ({
              ...elem,
              id: elem.extraModuleId,
              isExtraModule: true,
              isChecked: false,
            }))
          );
        })
        .catch((err: any) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [classId]);

  const handleSave = async () => {
    try {
      setBtnLoading(true);
      const modulesToUpdate: any[] = [];

      modules.forEach((module: any) => {
        let workersToPush: any = [];
        module.workers.forEach((worker: any) => {
          if (worker.id !== null && worker.id !== undefined) {
            workersToPush.push({
              workerId: worker.id,
              roleId: worker.roleId,
            });
          }
        });
        let moduleToPush = {
          moduleId: module.id,
          workers: workersToPush,
          startDate: module.startDate,
          endDate: module.endDate,
        };
        modulesToUpdate.push(moduleToPush);
        module.subModules?.forEach((subModule: any) => {
          let subModuleWorkerToPush: any = [];
          subModule.workers.forEach((worker: any) => {
            if (worker.id !== null && worker.id !== undefined) {
              subModuleWorkerToPush.push({
                workerId: worker.id,
                roleId: worker.roleId,
              });
            }
          });
          let subModuleToPush = {
            moduleId: subModule.id,
            workers: subModuleWorkerToPush,
            startDate: subModule.startDate,
            endDate: subModule.endDate,
          };
          modulesToUpdate.push(subModuleToPush);
        });
      });
      const newModulesToCreate: any = [];
      const extraModulesToUpdate: any = [];
      newModules.forEach((module: any) => {
        if (!module.isExtraModule) {
          let workersToPush: any = [];
          module.workers.forEach((worker: any) => {
            if (worker.id !== null && worker.id !== undefined) {
              workersToPush.push({
                workerId: worker.id,
                roleId: worker.roleId,
              });
            }
          });
          let moduleToPush = {
            extraModuleName: module.name,
            workers: workersToPush,
            startDate: module.startDate,
            endDate: module.endDate,
            hours: module.hours,
          };
          newModulesToCreate.push(moduleToPush);
        } else if (module.isExtraModule) {
          let workersToPush: any = [];
          module.workers.forEach((worker: any) => {
            if (worker.id !== null || worker.id !== undefined) {
              workersToPush.push({
                workerId: worker.id,
                roleId: worker.roleId,
              });
            }
          });
          let moduleToPush = {
            extraModuleId: module.id,
            workers: workersToPush,
            startDate: module.startDate,
            endDate: module.endDate,
          };
          extraModulesToUpdate.push(moduleToPush);
        }
      });
      const res = await baseService.update(
        {
          modules: modulesToUpdate,
          extraModules: extraModulesToUpdate,
          newExtraModules: newModulesToCreate,
        },
        `api/Classes/${classId}/modules-workers`
      );
      if (res.status) {
        setSnackbar({ open: true, text: "Yadda saxlanılıb" });
        setTimeout(() => {
          closeModal();
        }, 1500);
      } else {
        setSnackbar({ open: true, text: "Xəta baş verdi. Bir daha dənəyin." });
      }
    } catch (err) {
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <>
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
     <ModalHeader
       tittle={`Proqramı tənzimlə ${groupName}`}
       description="Qrupun açılması üçün ilk öncə seans və proqramı təyin edin."
       studentsHeaderDetail={studentsHeaderDetail}
     >
      {
        btnLoading ? (
          <LoadingButton sx={sxBtn} loading variant="outlined">
            Yadda saxla
          </LoadingButton>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={sxBtn}
            onClick={handleSave}
          >
            Yadda saxla
          </Button>
        )}
        <Button
          sx={[{ background: "#F3EDF4", color: "#0E0D0D" }, sxBtn]}
          onClick={closeSetProgram}
        >
          Ləğv et
        </Button>
      </ModalHeader>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh',pb:3 }}>
  {loading ? (
    <Progress />
  ) : (
    <>
      <Typography sx={{ mt: 3, mb: 2 }}>Əlavə edilmiş modullar</Typography>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', height:"20px" }}>
        <TableSetProgram
          hasDelete={true}
          modules={newModules}
          setModules={setNewModules}
          isTableAdd={true}
        />
      </Box>
      <Typography sx={{ mt: 2, mb: 3 }}>Modullar</Typography>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', height:boxHeight }}>
        <TableSetProgram
          customSX={{ mb: 2 }}
          modules={modules}
          setModules={setModules}
          
        />
      </Box>
    </>
  )}
</Box>




      <Snackbar
        sx={{ backgroundColor: "green" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={snackbar.open}
        onClose={() => setSnackbar({ open: false, text: "" })}
        message={snackbar.text}
      />
      </Box>
    </>
  );
}
