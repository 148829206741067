import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { sxTableCell } from "./style";
import { SubModule } from "../../models/SubModule";
import { Module } from "../../models/Module";

interface TableHeaderProps {
  indeterminate: boolean;
  checked: string[];
  checkedDataLength: number;
  setChecked: React.Dispatch<React.SetStateAction<string[]>>;
  copiedData: Module[];
  setCopiedData: React.Dispatch<React.SetStateAction<Module[]>>;
}

function TableHeader(props: TableHeaderProps) {
  const { indeterminate, checked, checkedDataLength, setChecked, copiedData, setCopiedData } =
    props;

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("asc");
  const [sortedColumn, setSortedColumn] = React.useState<keyof Module | undefined>(undefined);

  function handleSort(columnName: keyof Module) {
    // Sıralama düzenini kontrol et
    if (columnName === sortedColumn) {
      // Eğer aynı sütuna tekrar tıklanıyorsa, sıralama düzenini tersine çevir
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Başka bir sütuna tıklanıyorsa, sıralama düzenini varsayılana döndür
      setSortOrder("asc");
      setSortedColumn(columnName);
    }
  
    // Verileri sırala
    const sortedData = [...copiedData];
    sortedData.sort((a, b) => {
      if (columnName === "version") {
        // Modülün versiyonlarını ve alt modüllerin versiyonlarını topla
        const aVersions: number[] = [parseFloat(a.version), ...a.subModules.map((subModule: Module) => parseFloat(subModule.version))];
        const bVersions: number[] = [parseFloat(b.version), ...b.subModules.map((subModule: Module) => parseFloat(subModule.version))];
  
        // Versiyonları sıralamaya uygun şekilde karşılaştır
        if (sortOrder === "asc") {
          aVersions.sort((x:any, y:any) => x - y);
          bVersions.sort((x:any, y:any) => x - y);
        } else {
          aVersions.sort((x:any, y:any) => y - x);
          bVersions.sort((x:any, y:any) => y - x);
        }
  
        // İlk versiyonu al ve karşılaştır
        const aValue = aVersions[0];
        const bValue = bVersions[0];
  
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      } else {
        // Diğer sütunlar için varsayılan sıralama düzenini kullan
        const aValue = parseFloat(a[columnName]);
        const bValue = parseFloat(b[columnName]);
  
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
  
    // Sıralanmış verileri kullanarak tabloyu güncelle
    setCopiedData(sortedData);
  }
  
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell sx={sxTableCell} align="left">
            <Checkbox
              sx={{ padding: "0" }}
              indeterminate={indeterminate}
              checked={checked.length === checkedDataLength}
              onChange={(event) => {
                if (event.target.checked) {
                  setChecked(
                    copiedData?.flatMap((item) => {
                      if (item.subModules) {
                        return [
                          item.id,
                          ...item.subModules.map(
                            (subModule: SubModule) => subModule.id
                          ),
                        ];
                      } else {
                        return [item.id];
                      }
                    })
                  );
                } else {
                  setChecked([]);
                }
              }}
            />
          </TableCell>
          <TableCell sx={sxTableCell} align="left" style={{display:'flex',alignItems:"center",justifyContent:"space-between"}}>
            Modulun adı   <button
            style={{
              backgroundColor:"transparent",
              border:"none",
              cursor:"pointer"
            }}
             onClick={() => handleSort('version')}><img src='/icons/sort-down.svg'/></button>
          </TableCell>
          <TableCell sx={sxTableCell} align="left">
            Alt modullar
          </TableCell>
          <TableCell sx={sxTableCell} align="left">
            Ümumi saat
          </TableCell>
          <TableCell sx={sxTableCell} align="left">
            Müəllim
          </TableCell>
          <TableCell sx={sxTableCell} align="left">
            Mentor/ M.K
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

export default TableHeader;
