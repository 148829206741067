import { Box } from "@mui/system";
import React, { useState, useRef, useEffect } from "react";
import { Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';

export interface ModalProps {
  open?: boolean;
  value?: number;
  onSelectStatus?: (status: string) => void;
  onClose: () => void;
}

export default function StatusModal({ open, value, onSelectStatus, onClose }: ModalProps) {

  const isOdd = value !== undefined && value % 2 !== 0;
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleStatusSelect = (status: string) => {
    const updatedStatuses = selectedStatuses.includes(status)
      ? selectedStatuses.filter((s) => s !== status) // Eğer zaten seçilmişse çıkar
      : [...selectedStatuses, status]; // Aksi halde ekle
  
    setSelectedStatuses(updatedStatuses);
  
    // Tüm seçili statüleri virgülle ayırarak onSelectStatus'a gönder
    if (onSelectStatus) {
      onSelectStatus(updatedStatuses.join(","));
    }
  };
  
  useEffect(() => {
    console.log("Selected statuses:", selectedStatuses);
  }, [selectedStatuses]);



  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  return (
    <>
      {open && (


        <Box
          ref={modalRef}
          sx={{
            display: "flex",
            width: "170px",
            padding: "8px 10px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "9px",
            borderRadius: "8px",
            background: "#FFF",
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
            left: "75%",
            position: "absolute",
            zIndex: "1",
            marginTop: "-7px",
            "@media (min-width: 1900px)": {
              left: "calc(50% + 25%)",
            },
            "@media (max-width: 1900px)": {
              left: "calc(50% + 25%)",
            },
            cursor: "pointer",
          }}
        >

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%"
            }}
          >
            <Checkbox
              checked={selectedStatuses.includes("Pending")}
              onChange={() => handleStatusSelect("Pending")}
              sx={{
                padding: 0,
                color: "#6B696A",
                "&.Mui-checked": { color: "#468CBC" },
              }}
            />
            <Typography
              sx={{
                color: "var(--Natural-natural-800, #131112)",
                fontFamily: "Visby CF Bold",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0.4px",
              }}
            >
              Gözləmədə
            </Typography>
          </Box>
          <div
            style={{
              width: "140px",
              height: "1px",
              background: "#E9E9E9",
            }}
          ></div>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Checkbox
              checked={selectedStatuses.includes("Approved")}
              onChange={() => handleStatusSelect("Approved")}
              sx={{
                padding: 0,
                color: "#6B696A",
                "&.Mui-checked": { color: " #468CBC" },
              }}
            />
            <Typography
              sx={{
                color: "var(--Natural-natural-800, #131112)",
                fontFamily: "Visby CF Bold",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0.4px",
              }}
            >
              Təsdiqlənib
            </Typography>
          </Box>
          <div
            style={{
              width: "140px",
              height: "1px",
              background: "#E9E9E9",
            }}
          ></div>


          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Checkbox
              checked={selectedStatuses.includes("Rejected")}
              onChange={() => handleStatusSelect("Rejected")}
              sx={{
                padding: 0,
                color: "#6B696A",
                "&.Mui-checked": { color: "#468CBC" },
              }}
            />
            <Typography
              sx={{
                color: "var(--Natural-natural-800, #131112)",
                fontFamily: "Visby CF Bold",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0.4px",
              }}
            >
              Qəbul edilməyib
            </Typography>
          </Box>
        </Box>


      )}
    </>
  );
}
