import React from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box } from '@mui/system';


function StyledDropzone(props:any) {
  const onDrop = React.useCallback((acceptedFiles: any) => {
    //file 
    console.log(acceptedFiles);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <>
    <div  {...getRootProps()} style={{ 
        border: '1px dashed #AACAE0', 
        textAlign: 'center',
        color: 'gray',
        marginBottom: '20px',
        cursor: 'pointer',
        backgroundColor: isDragActive ? '#f0f0f0' : ' #FAFDFF' ,
        width: "626px",
        height: "170px",
        padding: "24px",
        gap:" 12px",
        borderRadius: "16px ",
        opacity: "0px",}}>

          <CloudUploadIcon sx={{color:"#468CBC"}}></CloudUploadIcon>
      <input {...getInputProps()}  />
      {
        isDragActive ?
          <p style={{color:"#000000", fontSize:"14px"}}>Faylı yükləyin ya da buraya çəkib atın
          <h4>PDF, Word, JPG or PNG</h4>
          <h4>Max. 3MB</h4>
          </p> :
          <Box sx={{marginTop:"-10px"}}>
          <p style={{color:"#000000"}}><span style={{color: "#468CBC", fontFamily:"Visby CF Bold",textDecoration:"underline"}}>Faylı yükləyin</span> ya da buraya çəkib atın
           <Box sx={{marginTop:"-12px"}}><h4>PDF, Word, JPG or PNG</h4></Box> 
           <Box sx={{marginTop:"-12px"}}> <h4>Max. 3MB</h4></Box>
          </p></Box>
      }
      
    </div>
    </>
  );
}

export default StyledDropzone;
