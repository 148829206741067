// TabContent.tsx
import React from 'react';
import { Tabs, Tab, Box, Grid, Typography } from '@mui/material';
import styles from './modal.module.css';

interface TabContentProps {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  actions: { id: string; desc: string; date: string; name: string }[];
  comments: { id: string; desc: string; date: string; name: string }[];
}

const TabContent: React.FC<TabContentProps> = ({
  value,
  onChange,
  actions,
  comments,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '24px' }}
        className={`${styles.step}`}
      >
        <Tabs value={value} onChange={onChange} centered>
          <Tab
            label={
              <Typography
                sx={{
                  fontFamily:
                    value === 1 ? 'Visby CF Regular' : 'Visby CF Bold',
                  fontSize: '16px',
                  color: 'black',
                  textTransform: 'none',
                }}
              >
                Sorğu hərəkətləri
              </Typography>
            }
          />
          <Tab
            label={
              <Typography
                sx={{
                  fontFamily:
                    value === 0 ? 'Visby CF Regular' : 'Visby CF Bold',
                  fontSize: '16px',
                  color: 'black',
                  textTransform: 'none',
                }}
              >
                Rəylər
              </Typography>
            }
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Grid style={{overflowY: 'scroll'}} >
          {actions.map((action) => (
            <Box
              key={action.id}
              sx={{
                marginBottom: '10px',
                display: ' flex',
                padding: '10px 12px',
                gap: '4px',
                borderRadius: '4px',
                borderLeft: ' 2px solid var(--Primary-primary-500, #854693)',
                background: 'var(--Natural-natural-15, #F7F7F7) ',
              }}
            >
              <Typography
                sx={{ width: '100%', fontSize: '12px' }}
                className={`${styles.typo3}`}
              >
                <span
                  style={{
                    fontFamily: 'Visby CF Bold',
                    color: 'black',
                    fontSize: '12px',
                  }}
                >
                  {action.name}{' '}
                </span>
                {action.desc}
              </Typography>
              <Typography
                sx={{ fontSize: '12px', color: '#6B696A' }}
                className={`${styles.datetext}`}
              >
                {action.date}
              </Typography>
            </Box>
          ))}
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1} style={{ paddingBottom: '90px'}}>
        <Grid>
          {comments?.map((comment) => (
            <Box
              key={comment.id}
              className={`${styles.comment}`}
              sx={{ marginBottom: '10px', flex: '1 0 0'}}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <div
                  style={{
                    width: '38px',
                    height: '38px',
                    borderRadius: '52px',
                    background:
                      'url(/path-to-image) lightgray 50% / cover no-repeat',
                  }}
                ></div>
                <Box>
                  <Typography
                    sx={{ fontFamily: 'Visby CF Bold', fontSize: '14px' }}
                  >
                    {comment.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Visby CF Regular',
                      color: 'var(--Natural-natural-300, #0E0D0D)',
                      fontSize: '12px',
                    }}
                  >
                    {comment.date}
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: '#4E4C4D',
                  fontFamily: 'Visby CF Regular',
                  width: '100%',
                  wordBreak: 'break-all',
                  fontSize: '12px',
                  paddingBottom: '10px',
                }}
              >
                {comment.desc}
              </Typography>
            </Box>
          ))}
        </Grid>
      </TabPanel>
    </div>
  );
};

function TabPanel(props: {
  children?: React.ReactNode;
  value: number;
  index: number;
  style?: any;
}) {
  const { children, value, index, style, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3, pb: 3 }}>{children}</Box>}
    </div>
  );
}

export default TabContent;
