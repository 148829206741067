import React, { useContext, useRef, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Grid,
  Paper,
  IconButton,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Email from "@mui/icons-material/EmailOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import ReCAPTCHA from "react-google-recaptcha";

import Errors from "../components/Errors";
import { AuthContext } from "../../../../contexts/AuthContext";
import Progress from "../../../../components/custom-components/progress";
import { AxiosError } from "axios";

interface LoginValues {
  email: string;
  password: string;
}

function LoginForm() {
  const recaptchaRef: any = useRef();
  const [submitStatus, setSubmitStatus] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [error, setError] = useState("");
  const [token, setToken] = useState<string | null>("");
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginValues>();
  const [clicked, setClicked] = useState(false);
  const handleFormSubmit = async (data: LoginValues) => {
    const model = { ...data, token: token };
    setSubmitStatus(true);
    try {
      setError("");
      const res = await authContext?.login(model);
      if(res.status == 200){
          navigate("/")
      }
    } catch (error) {
      console.log(error);
      if ((error as AxiosError).response?.status === 401) {
        setError("Daxil etdiyiniz şifrə yanlışıdr.");
      } else if ((error as AxiosError).response?.status === 404) {
        setError("Daxil etdiyiniz mailə uyğun Istifadəçi tapılmadı.");
      } else {
        setError("Xəta baş verdi. Yenidən sınayın.");
      }
    } finally {
      setSubmitStatus(false);
    }
    // } else {
    //   setError("Evvəlcə testi edin.");
    // }
  };
  // const handleRecaptchaChange = (recaptchaToken: string | null) => {
  //   setToken(recaptchaToken);
  //   console.log(recaptchaToken);
  // };
  const handlecapt = () => {
    setClicked(true);
  };

  const [activeInput, setActiveInput] = useState("");

  const handleInputFocus = (id: string) => {
    setActiveInput(id);
  };

  const handleInputBlur = () => {
    setActiveInput("");
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: "Emailini qeyd et",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.email}
              InputLabelProps={{
                style: { color: "#0E0D0D" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email
                      color="disabled"
                      sx={{
                        color: activeInput === "email" ? "#468CBC" : undefined,
                      }}
                    />
                  </InputAdornment>
                ),
                onFocus: () => handleInputFocus("email"),
                onBlur: handleInputBlur,
                sx: {
                  borderRadius: 3,
                  "& fieldset": {
                    borderColor: "grey.500",
                  },
                  "&:hover fieldset": {
                    borderColor: "#468CBC !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#468CBC !important",
                    borderWidth: 1,
                    backgroundColor: "transparent",
                  },
                },
                value: field.value.trim().toLowerCase(),
                onChange: (e) =>
                  field.onChange(e.target.value.trim().toLowerCase()),
              }}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: "Şifrəni qeyd et",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              id="password"
              label="Şifrə"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              fullWidth
              margin="normal"
              error={!!errors.password}
              InputLabelProps={{
                style: { color: "#0E0D0D" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          color="disabled"
                          sx={{
                            color:
                              activeInput === "password"
                                ? "#468CBC"
                                : undefined,
                          }}
                        />
                      ) : (
                        <Visibility
                          color="disabled"
                          sx={{
                            color:
                              activeInput === "password"
                                ? "#468CBC"
                                : undefined,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                onFocus: () => handleInputFocus("password"),
                onBlur: handleInputBlur,
                sx: {
                  borderRadius: 3,
                  "& fieldset": {
                    borderColor: "grey.500",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#468CBC !important",
                    borderWidth: 1,
                    backgroundColor: "transparent",
                  },
                },
              }}
            />
          )}
        />

        <Link to="/auth/forgotpassword">
          <Typography
            py={2}
            sx={{ color: "#468CBC" }}
            fontFamily={"Visby CF DemiBold, sans-serif"}
            component={"p"}
          >
            Şifrə təyin et
          </Typography>
        </Link>
        <Box py={2}>
          {/* for deploy */}
          {/* <ReCAPTCHA
              onChange={handlecapt}
              sitekey={"6LfEWyAlAAAAADDTwtPs_sBjf3liA2g09vflDXz3"}
            /> */}
          {/* for local host */}
          {/* <ReCAPTCHA
            ref={recaptchaRef}
            onChange={handleRecaptchaChange}
            sitekey={"6LctQhgnAAAAAHS8LjZvPEBdsEVz54CPwwbs_AF9"}
          /> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Button
            sx={{
              borderRadius: "12px",
              backgroundColor: "#4BA458",
              textTransform: "none",
              height: "40px",
              minWidth: "150px",
              marginTop: "20px",
            }}
            type="submit"
            variant="contained"
            color="success"
            disabled={submitStatus}
          >
            {submitStatus ? <Progress size={20} /> : "Getdik"}
          </Button>
        </Box>

        {errors.email?.message && <Errors message={errors.email?.message} />}
        {error && <Errors message={error} />}

        {errors.password?.message && (
          <Errors message={errors.password?.message} />
        )}
      </form>
    </>
  );
}

export default LoginForm;
