import { useContext, useEffect, useState } from "react";
import { AttendanceContext } from "../../../../../../context/AttendanceContext";
import { Kpi } from "../../../../EducationSystemManagement/groups/components/kpi";


export default function LessonDuration() {

  const {totalHours, startTime} = useContext(AttendanceContext);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(1);
  const [end, setEnd] = useState(0);
  

  useEffect(()=> {
    const interval =  setInterval(()=> {
      if(totalHours){
        if(hour < totalHours.length){
          if(minute == 59){
            setMinute(0);
            setHour(hour + 1)
          }else{
            setMinute(minute + 1)
          }
        }
      }
    }, 60000);

    return () => clearInterval(interval)
  }, [totalHours, minute, hour]);

  // useEffect(()=> {
  //   // if(totalHours && startTime){
  //     console.log('here')
  //     const curr: any = new Date();
  //     const elapsedTime = curr - startTime;
  //     const elapsedHours = Math.floor(elapsedTime / (1000 * 60 * 60));
  //     const elapsedMinutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
  //     console.log('elapsedHours', elapsedMinutes)
  //   // }

  // }, [totalHours, startTime])

  return (
    <>
      <Kpi
        value={`${hour}:${minute < 10 ? `0${minute}` : minute}`}
        color="#4BA458"
        tagTitle="Dərs müddəti"
        iconColor="#C7E3CB"
      />
    </>
  )
}
