import React, { useEffect, useState } from "react";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import { Box, Typography,TextField } from "@mui/material";
import { sxBtn } from "./style";
import { SelectChangeEvent } from "@mui/material/Select";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Alert from "../../../../components/custom-components/alert";
import { localeText } from "../../../../configs/Locales";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Slide, IconButton, Button } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import { TransitionProps } from "@mui/material/transitions";
import { StudentHeaderDetail } from "./models/StudentDetail";
import { StudentTableDetail } from "./models/StudentDetail";
import { BaseService } from "../../../../network/services/base/BaseService";
import ClassDetailModal from "./class-detail-modal";
import { ROLES } from "../../../../constants/auth/roles";
import { TabHeader } from "./components/tab-header";
import { StatusQuery } from "./models/StatusQuery";
import { ClassesCount } from "./models/ClassesCount";
import { ApiInstance } from "../../../../network/services/core/apiInstance";
import { CustomizedProgressBars } from "../../../../components/custom-components/customized-progress";
import {
  change,
  changeStatusTabs,
} from "../../../../store/reducers/groupFilterReducer";
import Progress from "../../../../components/custom-components/progress";
import Header from "./components/header";
import { ProgramService } from "../../../../network/services/programService/ProgramService";
import DataGrid from "../../../../components/custom-components/data-grid";
import ClassSession from "./class-session";
import { ModalState } from "./set-program-detail/models/ModalState";
import ModalHeader from "./components/modal-header";
import SetProgram from "./set-program-detail";
import SetSesion from "./class-session/set-session";
import { PostData } from "./set-program-detail/models/PostData";
import { PostClassSessions } from "./models/PostClassSessions";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialStatusQuery: StatusQuery = {
  TabValue: 0,
  StatusValue: "active",
};
const initialPostData: PostData = {
  modules: [],
  sessionId: "",
};
function Groups() {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const [postData, setPostData] = useState<PostData>(initialPostData);
  const [sessionsData, setSessionsData] = useState<PostClassSessions>({
    startDate: "2023-11-22T09:02:10.711Z",
    endDate: "2023-11-22T09:02:10.711Z",
    sessions: [],
  });
  const [modalState, setModalState] = useState<ModalState>(ModalState.Detail);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [selectedSeans, setSelectedSeans] = useState<string>("");
  const [programsList, setProgramsList] = useState<string[]>([]);
  const [program, setProgram] = useState<string[]>([]);
  const [classes, setClasses] = useState<any[]>([]);
  const [classesCountByStatus, setClassesCountByStatus] = useState<
    ClassesCount[]
  >([]);
  const [data, setData] = useState<any[]>([]);
  const [step, setStep] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [studentsHeaderDetail, setStudentsHeaderDetail] = useState<
    StudentHeaderDetail | undefined
  >(undefined);
  const [studentsTableDetail, setStudentsTableDetail] = useState<
    StudentTableDetail[]
  >([]);
  const [classId, setClassId] = useState<any>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [classesStatusQuery, setClassesStatusQuery] =
    useState<StatusQuery>(initialStatusQuery);
  const dispatch = useDispatch();
  const classFilters = useSelector((state: any) => state.groupFilter.filters);
  let [searchParams, setSearchParams] = useSearchParams();

  const programService = new ProgramService();
  const classService = new BaseService(
    `api/Classes?${
      classFilters.status ? `status=${classFilters.status}&` : ""
    }${
      classFilters.programId ? `programId=${classFilters.programId.id}&` : "" 
    }${
      classFilters.studyCount ? `studyCount=${classFilters.studyCount}&` : ""
    }${
      classFilters.studyCountStatus
        ? `studyCountStatus=${classFilters.studyCountStatus.id}&`
        : ""
    }${
      classFilters.startDate
        ? `startDate=${classFilters.startDate.format("YYYY-MM-DD")}&`
        : ""
    }${
      classFilters.endDate
        ? `endDate=${classFilters.endDate.format("YYYY-MM-DD")}&`
        : ""
    }${
      classFilters.teacherId ? `teacherId=${classFilters.teacherId.id}&` : ""
    }${classFilters.mentorId ? `mentorId=${classFilters.mentorId.id}&` : ""}${
      classFilters.startAttendancePercentage
        ? `startAttendancePercentage=${classFilters.startAttendancePercentage}&`
        : ""
    }${
      classFilters.endAttendancePercentage
        ? `endAttendancePercentage=${classFilters.endAttendancePercentage}&`
        : ""
    }`
  );
  const classStatusCountService = new BaseService(`/api/classes/count`);
  const handleClose = () => {
    setStep(1);
    setClassId("");
    setOpenModal(false);
    setStudentsTableDetail([]);
    setStudentsHeaderDetail(undefined);
    handleModalState(ModalState.Detail);
    setSearchParams({group: ''})
  };
  const columns: GridColDef[] = [
    {
      field: "className",
      headerName: "Qrup",
      flex: 0.7,
    },
    {
      field: "programName",
      headerName: "Proqram adı",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Başlanğıc tarixi",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "endDate",
      headerName: "Bitiş tarixi",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "instructor",
      headerName: "Müəllim",
      flex: 1,
      renderCell: (params) => {
        const instructors = params?.row?.workers?.filter(
          (worker: any) => worker?.role === ROLES.MUELLIM
        );
        if (!instructors || instructors.length === 0) {
          return (
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
            >
              -
            </Typography>
          ); // Eğer mentors null
        }
        const firstInstructor = `${instructors[0].name} ${instructors[0].surname[0]}`;
        const otherInstructorCount = instructors.length - 1;
        if (otherInstructorCount > 0) {
          return (
            <Box>
              <Typography sx={{ fontSize: "14px" }}>
                {firstInstructor}
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                və {otherInstructorCount} daha
              </Typography>
            </Box>
          );
        } else {
          return (
            <Typography sx={{ fontSize: "14px" }}>{firstInstructor}</Typography>
          );
        }
      },
    },
    {
      field: "mentor",
      headerName: "Mentor/ M.K",
      flex: 1,
      renderCell: (params) => {
        const mentors = params?.row?.workers?.filter(
          (worker: any) => worker?.role === ROLES.MENTOR
        );
        if (!mentors || mentors.length === 0) {
          return (
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
            >
              -
            </Typography>
          ); // Eğer mentors null
        }
        const firstMentor = `${mentors[0].name} ${mentors[0].surname[0]}`;
        const otherMentorsCount = mentors.length - 1;
        if (otherMentorsCount > 0) {
          <Box>
            <Typography sx={{ fontSize: "14px" }}>{firstMentor}</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              və {firstMentor} daha
            </Typography>
          </Box>;
        } else {
          return (
            <Typography sx={{ fontSize: "14px" }}>{firstMentor}</Typography>
          );
        }
      },
    },
    {
      field: "Hours",
      headerName: "D.S",
      flex: 1,
      hide: classesStatusQuery.StatusValue === "new",
      sortable: true,
      filterable: true,
      renderCell: (params) => {
        const currentHour = params?.row?.currentHour;
        const totalHours = params?.row?.totalHour;
        if (currentHour > 0 && totalHours > 0) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
                fontWeight: 700,
              }}
            >
              <>
                <CustomizedProgressBars
                  currentHour={currentHour}
                  totalHours={totalHours}
                />
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Visby CF Bold, sans-serif",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                  }}
                >
                  {`${currentHour}/${totalHours}`}
                </Typography>
              </>
            </Box>
          );
        } else {
          return (
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
            >
              -
            </Typography>
          );
        }
      },
    },
    // {
    //   field: "operations",
    //   headerName: "Əməliyyatlar",
    //   // hide: classesStatusQuery.StatusValue === "new",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexWrap: "wrap",
    //           alignItems: "center",
    //           justifyContent: "space-between",
    //           width: "100%",
    //         }}
    //       >
    //         <Tooltip title="Program təyin et">
    //           <Link to={`${APP_PREFIX_PATH}/program/set/${params.row.id}`}>
    //             <EditOutlinedIcon color={"secondary"} sx={sxEditIcon} />
    //           </Link>
    //         </Tooltip>
    //         <Tooltip title="Sessiya təyin et">
    //           <Link to={`${APP_PREFIX_PATH}/classes/${params.row.id}`}>
    //             <AccessTimeOutlinedIcon color={"secondary"} sx={sxEditIcon} />
    //           </Link>
    //         </Tooltip>
    //         <Tooltip title="Davamiyyət">
    //           <Link
    //             to={`${APP_PREFIX_PATH}/classes/${params.row.id}/class-sessions`}
    //           >
    //             <Forward10OutlinedIcon color={"secondary"} sx={sxEditIcon} />
    //           </Link>
    //         </Tooltip>
    //         <Tooltip title="Tələbələr">
    //           <Groups3OutlinedIcon
    //             color={"secondary"}
    //             sx={sxEditIcon}
    //             onClick={() => handleClickStudentsDetail(params.row.id)}
    //           />
    //         </Tooltip>
    //       </Box>
    //     );
    //   },
    // },
  ];
  const filteredClasses = data.filter((item) => {
    return item?.className?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handlePostClick = (modules: any) => {
    handleClose();
    const modifiedData = modules
      .map((item: any) => {
        const { subModules, ...rest } = item;
        const subModuleObjects = subModules.map((subModule: any) => ({
          ...subModule,
        }));
        return [rest, ...subModuleObjects];
      })
      .flat();
    const subModuleObjects = modifiedData.flatMap((data: any) => {
      const { workers} = data;
      if (!workers || workers.length === 0) {
        return [{ moduleId:data.id,startDate:data.startDate,endDate:data.endDate, workerId: 0, roleId: 0 }];
      }
      return workers.map((worker: any) => ({
        moduleId:data.id,
        workerId: worker.id || 0,
        roleId: worker.roleId || 0,
        startDate:data.startDate,
        endDate:data.endDate
      }));
    });
    ApiInstance.post(`api/classes/${classId}/modules-workers`, subModuleObjects)
      .then((res) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanıldı",
          severity: "success" as AlertColor,
        });
      })
      .catch((err) => {
        console.log(err);
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanılmadı",
          severity: "error" as AlertColor,
        });
      });
  };
  const handleEditSessionClick = () => {
    handleClose();
    ApiInstance.put(`api/classes/${classId}/sessions`, sessionsData)
      .then((res) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanıldı",
          severity: "success" as AlertColor,
        });
      })
      .catch((err) => {
        console.log(err);
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanılmadı",
          severity: "error" as AlertColor,
        });
      });
  };

  const handleChangeStatusQuery = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    if (newValue === 2) {
      dispatch(changeStatusTabs("close"));
      setClassesStatusQuery({
        ...classesStatusQuery,
        TabValue: 2,
        StatusValue: "close",
      });
    } else if (newValue === 1) {
      dispatch(changeStatusTabs("new"));
      setClassesStatusQuery({
        ...classesStatusQuery,
        TabValue: 1,
        StatusValue: "new",
      });
    } else {
      dispatch(change(""));
      setClassesStatusQuery({ ...initialStatusQuery });
    }
  };
  const handleChangeSeans = (event: SelectChangeEvent) => {
    const selectedValue: string = event.target.value;
    setSelectedSeans(selectedValue);
    setPostData({
      ...postData,
      sessionId: event.target.value,
    });
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getPrograms(), getClassesStatusCount()])
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    setLoading(true);
    Promise.all([getClasses()])
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [classesStatusQuery, classFilters]);

  useEffect(() => {
    if (program.length == 0) {
      setData(classes);
    } else {
      const filteredData = classes.filter((item) =>
        program.includes(item.programName)
      );
      setData(filteredData);
    }
  }, [program, classes]);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const getPrograms = async () => {
    await programService
      .getAll()
      .then((res) => {
        const list = [...res.data.map((program: any) => program.name)];
        list.sort(function (a, b) {
          return a.localeCompare(b);
        });
        setProgram([]);
        setProgramsList(list);
      })
      .catch((err) => console.log(err));
  };
  const getClassesStatusCount = async () => {
    await classStatusCountService
      .getAll()
      .then((res) => {
        setClassesCountByStatus(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getClasses = async () => {
    await classService
      .getAll()
      .then((res) => {
        setClasses(res.data);
      })
      .catch((err) => console.log(err));
  };
  const handleModalState = (state: ModalState) => {
    setModalState(state);
  };
  const getStudentsTableDetail = async (id: GridRowId) => {
    try {
      const getStudentsByClassId = new BaseService(
        `/api/Classes/${id}/students-details`
      );
      const res = await getStudentsByClassId.getAll();
      setStudentsTableDetail(res.data);
    } catch (error) {
      return console.log(error);
    }
  };
  const getStudentsHeaderDetail = async (id: GridRowId) => {
    try {
      const getClassesDetails = new BaseService("/api/Classes");
      const res = await getClassesDetails.getById(`${id}`);
      setStudentsHeaderDetail(res.data);
      setSelectedSeans(res.data.session.id);
      setPostData({
        ...postData,
        sessionId: res.data.session.id,
      });
    } catch (error) {
      return console.log(error);
    }
  };

  
  const handleClickStudentsDetail = (params: { id: GridRowId }) => {
    setModalIsLoading(true);
    Promise.all([
      getStudentsTableDetail(params.id),
      getStudentsHeaderDetail(params.id),
    ])
      .catch((err) => console.log(err))
      .finally(() => setModalIsLoading(false));
    setClassId(params.id);
    setSearchParams({group: params.id.toString()})
    setOpenModal(true);
  }

  useEffect(()=> {
    if(searchParams.get('group')){
      handleClickStudentsDetail({id: searchParams.get('group')!})
    }
  }, [])

  const handleCloseSetProgram = () => {
    setModalState(ModalState.Detail);
  };
  

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
            <Header
              programs={programsList}
              program={program}
              setProgram={setProgram}
            />
            <TabHeader
              classesStatusQuery={classesStatusQuery}
              handleChangeStatusQuery={handleChangeStatusQuery}
              classesCountByStatus={classesCountByStatus}
            />
            <Box sx={{ mb: 2, position: 'absolute', right: '400px', top: '108px' }}>
              <TextField
                sx={{width:"182px",padding:"6px 20px",alignItems:"center",borderRadius:"16px",background:"#F7F7F7",border:"none"}}
                autoFocus
                variant="standard"
                placeholder="Qrup axtar..."
                value={searchQuery}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{width:"24px",height:"24px",color:"#0E0D0D"}}/>
                    </InputAdornment>
                  )
                }}
                onChange={(event: any) =>
                  setSearchQuery(event.target.value.trimStart())
                }
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <DataGrid
                handleClickStudentsDetail={handleClickStudentsDetail}
                data={filteredClasses}
                columnsData={columns}
                pageSizeData={pageSize}
                setPageSize={setPageSize}
                disableColumnMenu={false}
                localeText={localeText}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                  pagination: {
                    labelRowsPerPage: "Səhifədəki sətir sayı:",
                  },
                }}
              />
            </Box>
            <Dialog
              open={openModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              fullScreen
              sx={{
                "& .css-hz1bth-MuiDialog-container": {
                  backdropFilter: "blur(2px)",
                  position: "relative",
                },
                "& .MuiPaper-rounded.MuiPaper-root.MuiPaper-elevation": {
                  padding: "40px 100px",
                  maxWidth: "none",
                  marginTop: "150px",
                  borderRadius: " 32px 32px 0px 0px",
                  background: "var(--other-white, #FFF)",
                  position: "static",
                  scrollbarWidth: "thin", // Firefox için gizli scroll çubuğu
                  "::-webkit-scrollbar": {
                    width: "0.0em", // Diğer tarayıcılar için gizli scroll çubuğu
                  },
                  "::-webkit-scrollbar-thumb": {
                    backgroundColor: "#000000",
                  },
                },
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 40,
                  top: 20,
                  color: "#FFFFFF",
                }}
              >
                <CloseIcon />
              </IconButton>
              {classesStatusQuery.StatusValue === "new" && openModal ? (
                <ClassSession
                  handlePostClick={handlePostClick}
                  step={step}
                  setStep={setStep}
                  selectedSeans={selectedSeans}
                  handleChangeSeans={handleChangeSeans}
                  classId={classId}
                  onClose={handleClose}
                  studentsHeaderDetail={studentsHeaderDetail}
                />
              ) : modalState === ModalState.Detail ? (
                <ClassDetailModal
                  handleModalState={handleModalState}
                  onClose={handleClose}
                  classId={classId}
                  studentsTableDetail={studentsTableDetail}
                  studentsHeaderDetail={studentsHeaderDetail}
                  modalIsLoading={modalIsLoading}
                />
              ) : modalState === ModalState.Program ? (
                <>
                  <SetProgram
                    selectedSeans={selectedSeans}
                    closeSetProgram={handleCloseSetProgram}
                    closeModal = {handleClose}
                    classId={classId}
                    groupName={studentsHeaderDetail?.name ?? ''}
                    studentsHeaderDetail={studentsHeaderDetail}
                  />
                </>
              ) : modalState === ModalState.Session ? (
                <>
                  <Box
                    display="inline-flex"
                    height="696"
                    flexDirection="column"
                    gap="24px"
                  >
                    <ModalHeader
                      tittle={`${studentsHeaderDetail?.name} Qrup seansı tənzimlə`}
                      studentsHeaderDetail={studentsHeaderDetail}
                    >
                      <Button
                        onClick={() => handleEditSessionClick()}
                        variant="contained"
                        color="primary"
                        sx={sxBtn}
                      >
                        Yadda saxla
                      </Button>
                      <Button
                        onClick={() => handleModalState(ModalState.Detail)}
                        sx={[
                          { background: "#F3EDF4", color: "#0E0D0D" },
                          sxBtn,
                        ]}
                      >
                        Ləğv et
                      </Button>
                    </ModalHeader>
                    <SetSesion
                      sessionsData={sessionsData}
                      setSessionsData={setSessionsData}
                      selectedSeans={selectedSeans}
                      handleChangeSeans={handleChangeSeans}
                      DatePicker={true}
                      classId={classId}
                    />
                  </Box>
                </>
              ) : (
                ""
              )}
            </Dialog>
          {alertOpen && (
            <Alert
              open={alertOpen}
              text={alertInside.text}
              severity={alertInside.severity}
              handleClose={handleCloseAlert}
            />
          )}
        </>
      )}
    </>
  );
}

export default Groups;
