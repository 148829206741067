import {
    useDelete,
      useFetch, usePost, useUpdate,
  } from '../utils/api/reactQuery';
  import { apiRoutes } from '../apiRoutes';
import { IProgramModel } from '../types';


export const useGetProgramModulesList = (id: number) =>
  useFetch<IProgramModel>(apiRoutes.programs.modules(id), undefined, [apiRoutes.programs.base, undefined]);
