import { Box, styled } from "@mui/system";
import React from "react";
import{Typography} from '@mui/material'
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";

export interface PopUpProps {
    open?: boolean;

}

export default function Popup ({open}: PopUpProps) {


  const columns: GridColDef[] = [
    { field: 'no', 
      headerName: '№',
      flex: 1,
      sortable: false, // sıralama devre dışı bırakıldı
     
    },

    {
      field: 'name',
      headerName: 'Tələbə adı',
      flex: 14,
    }
  ];

  const rows = [
    { id: 1, no: 1, name: "Farid Bayramov" },
    { id: 2, no: 2, name: 'Ayşən İsmayılova'  },
    { id: 3, no: 3, name: 'Məmməd Kərimov' } ,
    { id: 4, no: 4,  name: 'Səbinə Quliyeva' } ,
    { id: 5, no: 5, name: 'Ramin Rüstəmov' } ,
    { id: 6, no: 6, name: "Farid Bayramov" },
    { id: 7, no: 7, name: 'Ayşən İsmayılova'  },
    { id: 8, no: 8, name: 'Məmməd Kərimov' } ,
    { id: 9, no: 9,  name: 'Səbinə Quliyeva' } ,
    { id: 10, no: 10, name: 'Ramin Rüstəmov' } ,
  ];

  
  const CustomScrollbar = styled('div')(({ theme }) => ({
    '&::-webkit-scrollbar': {
      width: '12px',
      height: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#468CBC',
      borderRadius: '10px',
      border: '3px solid #FFF', // boşluk eklemek için
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#E9E9E9',
      borderRadius: '10px',
    },
  }));

    return (
        <>
        <Box sx={{display: "flex",

height: "600px",
paddingTop: "80px",
flexDirection: "column",
alignItems: "center",
gap: "12px",
borderRadius: "24px",
background: "#FFF",
width:"814px",
overflowX:"none"
}}>

<Box sx={{
gap:"16px",
padding: "28px 28px 16px 28px",
justifyContent: "space-between",
alignItems: "flex-start",
marginTop:"-100px",
marginLeft:"-500px",
overflowX:"none"
}}>

<Box sx={{ display: "flex", alignItems: "center" }}>
  <Typography sx={{ fontSize: "34px", fontFamily: "Visby CF Bold" }}>Q401</Typography>
  <div
    style={{
      display: "flex",
      borderRadius: "17px",
      backgroundColor: "#468CBC",
      padding: "8px 12px 8px 16px",
      alignItems: "center", // Align content inside the div
      gap: "8px",
      marginLeft: "12px", // Optional: Add some space between the text and the div
    }}
  >
    <Typography sx={{ fontSize: "14px", fontFamily: "Visby CF Bold", color: "white" }}>
      Cyber Security
    </Typography>
  </div>
</Box>
<Box sx={{display:"flex",alignItems:"center",gap:"16px", marginTop:"16px"}}>
    <Typography sx={{fontSize:"14px",fontFamily:"Visby CF Bold"}}>20 Mar 2023 - 20 Dec 2024</Typography>
    <div style={{borderRadius:"17px",backgroundColor:"#E9E9E9",

display: "flex",
padding: "4px 12px",
alignItems: "flex-start",
gap: "8px",width:"69px"
    }}><Typography sx={{fontSize:"12px",fontFamily:"Visby CF Bold"}}>1. Seans</Typography></div>
</Box>


        </Box>

        <Box sx={{display:" flex",
        overflowX:"none",
        marginLeft:"650px",
        marginTop:"-112px",
flexDirection: "column",
justifyContent:" center",
alignItems: "flex-end",
gap: " 32px",
flexShrink:0}}><svg cursor={"pointer"} onClick={()=> {open=false}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M18 6L6 18" stroke="#BABABA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 6L18 18" stroke="#BABABA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<Box sx={{display:"flex",alignItems:"center", gap:"12px",padding: "0px 8px"}}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM4.47604 12C4.47604 16.1554 7.84463 19.524 12 19.524C16.1554 19.524 19.524 16.1554 19.524 12C19.524 7.84463 16.1554 4.47604 12 4.47604C7.84463 4.47604 4.47604 7.84463 4.47604 12Z" fill="#C6DBEA"/>
  <path d="M12 2.23802C12 1.00199 13.0106 -0.0211433 14.2252 0.208064C16.549 0.646606 18.7094 1.76601 20.4184 3.44834C22.6632 5.65813 23.949 8.66195 23.9985 11.8115C24.048 14.9611 22.8571 18.0038 20.6828 20.283C18.5086 22.5622 15.5253 23.8951 12.3769 23.9941C9.22853 24.093 6.16747 22.9501 3.85439 20.8119C1.54131 18.6737 0.161703 15.7117 0.0133212 12.5653C-0.13506 9.41882 0.959681 6.34018 3.06128 3.9938C4.66123 2.20749 6.74707 0.954642 9.03881 0.37105C10.2366 0.0660313 11.3094 1.0237 11.387 2.25728C11.4647 3.49087 10.5041 4.52332 9.34745 4.95911C8.22526 5.38191 7.20994 6.07078 6.39545 6.98014C5.07775 8.45131 4.39135 10.3816 4.48439 12.3544C4.57742 14.3272 5.44243 16.1844 6.89273 17.525C8.34302 18.8657 10.2623 19.5823 12.2363 19.5203C14.2104 19.4582 16.0809 18.6225 17.4441 17.1934C18.8074 15.7644 19.5541 13.8566 19.523 11.8818C19.492 9.90705 18.6858 8.02367 17.2783 6.63813C16.4083 5.78171 15.3517 5.15795 14.2052 4.80645C13.0235 4.44415 12 3.47404 12 2.23802Z" fill="#468CBC"/>
</svg> <Typography sx={{fontSize:"18px", fontFamily:"Visby CF Bold"}}> 125/130</Typography></Box>
</Box>
<Box sx={{height: "500px",border: '0px',overflowX:"none"}}>

      <DataGrid        
                        columns={columns}
                        disableSelectionOnClick
                        
                        sx={{overflowX:"hidden",
                           "& .MuiDataGrid-columnSeparator": {
            display: "none", // Başlıklar arasındaki çizgileri kaldırır
          },
                            border: 'none', width: "200%", right: '200px', cursor: "pointer",
                            "& .MuiDataGrid-virtualScroller": {
                              overflowX:"hidden",
            "&::-webkit-scrollbar": {
              width: "6px",
              
            },
            "& .MuiDataGrid-cell:focus": {
            outline: "none", // Hücre odaklandığında kenarlık oluşturmayı engeller
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
            border: "none" // Hücre odaklandığında kenarlık oluşturmayı engeller
          },
          "& .MuiDataGrid-columnHeaders": {
    border: "none", // Başlıkların kenarlığını kaldırır
  },
            "& .MuiDataGrid-sortIcon": {
            color: "black", // Sıralama işaretinin rengini değiştirir
            fontSize: "20px", // Sıralama ikonunun boyutunu değiştirir
          },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#468CBC",
              borderRadius: "10px",
              
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#E9E9E9",
              borderRadius: "10px",
            },
                        }}}
                        disableColumnFilter
                        disableColumnMenu
                         rows={rows}        
      />
    </Box>
        </Box>
        </>
    )
}