import { Fragment, Key, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Box, Drawer } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Card from './components/card/card';
import Header2 from './components/header/header2';
import { datagridSx } from './components/datagrid/datagrid';
import CustomPagination from './components/pagination/CustomPagination';
import PageTitle from '../../../../../components/custom-components/page-title';
import Breadcrumbs from '../../../../../components/custom-components/breadcrumbs';
import BreadcrumbsLink from '../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink';
import BreadcrumbsTypography from '../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography';
import PageHeader from '../../../../../components/custom-components/page-header';
import { APP_PREFIX_PATH } from '../../../../../configs/Appconfig';
import Modal from './components/modal/Modal';
import Dialog from "@mui/material/Dialog";
import Popup from './components/grouppopup';
import { useGetRequests } from '../../../../../api/requests';
import { calculateDateRange, formatDate } from '../../../../../utils/dateFunctions';
import { Request } from './interfaces/interface';
import { GetAcademicColumns } from './components/columns';
import { useSelector } from 'react-redux';
import { useGetWorkerLoginClass } from '../../../../../api/workers';

export default function Myrequests() {



  const user = useSelector((state: any) => state.auth.user);
  const [current, setCurrent] = useState<boolean>(false);
  const { data: apiRequests } = useGetRequests();
  useGetWorkerLoginClass();
  const {data:currentRequests} = useGetRequests(true);



  const requests = apiRequests?.map((req: any) => ({
    id: req.id,
    title: req.title,
    status: req.status,
    startdate: req.startDate,
    enddate: req.endDate,
    description: req.type.name,
    files: req.files,
    requesterUser: `${user.name} ${user.surname}`,
    type: req.type,
    percentage: 0,
    note: req.note

  }));

  const currents = currentRequests?.map((req: any) => ({
    id: req.id,
    title: req.title,
    status: req.status,
    startdate: req.startDate,
    enddate: req.endDate,
    description: req.type.name,
    files: req.files,
    requesterUser: `${user.name} ${user.surname}`,
    type: req.type,
    percentage: 0,
    note: req.note

  }));
  const [pageSize, setPageSize] = useState<number>(3);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = useState(1);
  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [request, setRequest] = useState<Request>();

  const [isVisible, setIsVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const rowHeightRef = useRef(84);
  const headerHeightRef = useRef(44);
  const footerHeightRef = useRef(56);

  const rowCount = useMemo(() => pageSize + 0.7, [pageSize]);
  const boxHeight = useMemo(() => {
    return (
      rowHeightRef.current * rowCount +
      headerHeightRef.current +
      footerHeightRef.current
    );
  }, [rowCount]);

  const filteredSorgular = requests
    ?.filter(
      (request: any) =>
        request.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request?.requesterUser.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter(
      (request: any) =>
        !selectedStatus || selectedStatus.includes(request.status) 
    );

  const today = new Date();
  const filteredSorgularbyDate = currents?.filter((request: any) => {
 
    return (request)

  });

  const totalRows = filteredSorgular?.length || 0;
  const totalPages = useMemo(() => Math.ceil(totalRows / pageSize), [totalRows, pageSize]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRequest(null);
  };

  const handleRowClick = (params: any) => {
    if (params.field === 'title' || params.field === 'startdate' || params.field === 'status' || params.field === 'note'|| params.field === 'role' || params.field === 'requesterUser') {
      setRequest(params.row);
      setOpenModal(true);
    }
  };


  const [selectedClassId, setSelectedClassId] = useState<any>(null);

  const handleGroupClick = (classId: number) => {
    {
      setOpenModal(false);
      setOpenPopUp(true);
      setSelectedClassId(classId);
    }
  }

  const handleOpenModal = (open: boolean, request: any) => {
    setRequest(request);
    setOpenModal(open);
  };

  useEffect(() => {
    // Eğer filteredSorgularbyDate boş değilse pageSize = 3, boşsa pageSize = 5
    if (filteredSorgularbyDate && filteredSorgularbyDate.length > 0) {
      setPageSize(3);
    } else {
      setPageSize(5);
    }
  }, [filteredSorgularbyDate]);

  const shouldShowPageTitle = filteredSorgularbyDate && filteredSorgularbyDate.length > 0;

 
    const handleStatusChange = (status: string | null) => {
      if (status) {
        const statuses = status.split(","); // Virgülle ayrılmış stringi diziye çevir
        setSelectedStatus(status);
      } else {
        setSelectedStatus(null); // Statü seçilmediyse null yap
      }
    };
  



  return (
    < Box  >
      <PageHeader>
        <Box >
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="İcazələr" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Mənim icazələrim" />
          </Breadcrumbs>
          <Box>
            {shouldShowPageTitle ? <PageTitle name="Cari icazələr" /> : null}

          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}></Box>
      </PageHeader>

      <Grid container spacing={2}  >
        {filteredSorgularbyDate?.map((elem: {
          status: string | null;
          description: string | null;
          enddate: string;
          startdate: string;
          title: string | null; id: string | null;
          files: string[];
          type: string;
          percentage: number;
          

        },
          key: Key | null | undefined) => (
          <Fragment key={key}>
            <Grid item xs={12} md={3} lg={3} >
              <Card
                status={elem.status}
                enddate={formatDate(elem.enddate)}
                startdate={formatDate(elem.startdate)}
                title={elem.title}
                daterange={calculateDateRange(elem.startdate, elem.enddate)}
                id={elem.id}
                description={elem.description}
                files={elem.files}
                openModal={() => handleOpenModal(true, elem)}
                percentage={elem.percentage}
                type={elem.type} />
            </Grid>
          </Fragment>
        ))}
      </Grid>

      <Header2 searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedStatus={selectedStatus}
        onStatusChange={handleStatusChange} />

      <Box sx={{ width: "100%", height: boxHeight, border: '0px' }}>
        <DataGrid
          rows={filteredSorgular?.slice((page - 1) * pageSize, page * pageSize) || []}
          columns={GetAcademicColumns(handleRowClick, handleGroupClick, setIsVisible, isVisible)}
          pageSize={pageSize}
          rowHeight={rowHeightRef.current}
          headerHeight={headerHeightRef.current}
          disableSelectionOnClick
          onCellClick={handleRowClick}
          sx={{
            ...datagridSx,
            border: 'none', width: "111.5%", right: '100px', cursor: "pointer", overflowX: "auto"
          }}
          disableColumnFilter
          disableColumnMenu
          components={{

            Pagination: () => (
              <CustomPagination
                count={totalPages}
                page={page}
                onPageChange={handlePageChange}
              />
            ),
          }}

        />
      </Box>
      <Drawer
        anchor="right"
        open={openModal}
        onClose={handleCloseModal}
      >
        <Modal
          id={request?.id}
          status={request?.status}
          start_date={request?.startdate}
          end_date={request?.enddate}
          type={request?.title}
          files={request?.files}
          requesterUser={request?.requesterUser}
          group={request?.group}
          title={request?.description}
          note={request?.note}
          onClose={handleCloseModal} />

      </Drawer>
      <Dialog open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            borderRadius: "24px",
          },
        }}
      >
        <Popup
          open={openPopUp}
          onClose={() => setOpenPopUp(false)}
          classId={selectedClassId}
        />
      </Dialog>
    </Box>
  );
}


