import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import { Box } from '@mui/system';
import { columns } from './tableColumns';
import DataGrid from "../../../../components/custom-components/data-grid";
import { TransitionProps } from "@mui/material/transitions";
import { Dialog, Slide } from "@mui/material";
import SupportDetail from './components/SupportDetail';
import { useSearchParams } from "react-router-dom";
import { useGetSupportsList } from '../../../../api/supports';
import Progress from '../../../../components/custom-components/progress';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Support() {
  const {data: data, isLoading} = useGetSupportsList();
  const [pageSize, setPageSize] = useState<number>(10);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedSupport, setSelectedSupport] = useState<number | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    setOpenModal(false);
    setSearchParams({})
    setSelectedSupport(null); 
  };

  const handleOpen = (supportId: number) => {
    setOpenModal(true);
    setSearchParams({supportId: supportId.toString()})
    setSelectedSupport(supportId);
  }

  useEffect(()=> {
    if(searchParams.get('supportId')){
      handleOpen(+(searchParams.get('supportId')!))
    }
  }, [])

  return (
    <>
      <Header/>
      <Box>
        {
          isLoading ? (
            <Progress />
          ) : (
            <DataGrid
              data={data}
              columnsData={columns}
              rowsPerPageOptions={[10]}
              pageSizeData={pageSize}
              setPageSize={setPageSize}
              autoHeight
              onRowClick={(elem: any) => handleOpen(elem.id)}
            />
          )
        }
        
      </Box>
      {
        openModal ? (
          <Dialog
                  open={openModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                  fullScreen
                  sx={{
                    "& .css-hz1bth-MuiDialog-container": {
                      backdropFilter: "blur(2px)",
                      position: "relative",
                      justifyContent: "end"
                    },
                    "& .MuiPaper-rounded.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation24": {
                      padding: "40px 30px",
                      maxWidth: "50%",
                      background: "var(--other-white, #FFF)",
                      position: "static",
                      scrollbarWidth: "thin", // Firefox için gizli scroll çubuğu
                      "::-webkit-scrollbar": {
                        width: "0.0em", // Diğer tarayıcılar için gizli scroll çubuğu
                      },
                      "::-webkit-scrollbar-thumb": {
                        backgroundColor: "#000000",
                      },
                    },
                  }}
                >
                  <SupportDetail supportId={selectedSupport} onClose={handleClose}/>
            </Dialog>
        ) : null
      }
    </>
  )
}
