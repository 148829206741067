import React, { useEffect, useState } from "react";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import { Box, Typography, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "../../../../components/custom-components/alert";
import { localeText } from "../../../../configs/Locales";
import moment from "moment";
import { Dialog, Slide, IconButton, Button } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import { BaseService } from "../../../../network/services/base/BaseService";
import { ROLES } from "../../../../constants/auth/roles";
import { TabHeader } from "./components/tab-header";
import { StatusQuery } from "./models/StatusQuery";
import { ClassesCount } from "./models/ClassesCount";
import { CustomizedProgressBars } from "../../../../components/custom-components/customized-progress";
import {
  change,
  changeStatusTabs,
  setClassesStatusQuery,
} from "../../../../store/reducers/groupFilterReducer";
import Progress from "../../../../components/custom-components/progress";
import Header from "./components/header";
import { ProgramService } from "../../../../network/services/programService/ProgramService";
import DataGrid from "../../../../components/custom-components/data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../../configs/Appconfig";


function Groups() {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [programsList, setProgramsList] = useState<string[]>([]);
  const [program, setProgram] = useState<string[]>([]);
  const [classes, setClasses] = useState<any[]>([]);
  const [classesCountByStatus, setClassesCountByStatus] = useState<
    ClassesCount[]
  >([]);
  const [data, setData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const dispatch = useDispatch();
  const classFilters = useSelector((state: any) => state.groupFilter.filters);
  const classesStatusQuery = useSelector((state: any) => state.groupFilter.classesStatusQuery);
  const navigate = useNavigate();
  const programService = new ProgramService();
  const classService = new BaseService(
    `api/Classes?${
      classFilters.status ? `status=${classFilters.status}&` : ""
    }${
      classFilters.programId ? `programId=${classFilters.programId.id}&` : ""
    }${
      classFilters.studyCount ? `studyCount=${classFilters.studyCount}&` : ""
    }${
      classFilters.studyCountStatus
        ? `studyCountStatus=${classFilters.studyCountStatus.id}&`
        : ""
    }${
      classFilters.startDate
        ? `startDate=${classFilters.startDate.format("YYYY-MM-DD")}&`
        : ""
    }${
      classFilters.endDate
        ? `endDate=${classFilters.endDate.format("YYYY-MM-DD")}&`
        : ""
    }${
      classFilters.teacherId ? `teacherId=${classFilters.teacherId.id}&` : ""
    }${classFilters.mentorId ? `mentorId=${classFilters.mentorId.id}&` : ""}${
      classFilters.startAttendancePercentage
        ? `startAttendancePercentage=${classFilters.startAttendancePercentage}&`
        : ""
    }${
      classFilters.endAttendancePercentage
        ? `endAttendancePercentage=${classFilters.endAttendancePercentage}&`
        : ""
    }`
  ); 
  const classStatusCountService = new BaseService(`/api/classes/count`);
  const columns: GridColDef[] = [
    {
      field: "className",
      headerName: "Qrup",
      flex: 0.7,
    },
    {
      field: "programName",
      headerName: "Proqram adı",
      flex: 1,
    },
    {
      field: "studyCount",
      headerName: "Tələbə",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Başlanğıc tarixi",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "endDate",
      headerName: "Bitiş tarixi",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "instructor",
      headerName: "Müəllim",
      flex: 1,
      renderCell: (params) => {
        const instructors = params?.row?.workers?.filter(
          (worker: any) => worker?.role === "Teacher"
        );
        if (!instructors || instructors.length === 0) {
          return (
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
            >
              -
            </Typography>
          ); // Eğer mentors null
        }
        const firstInstructor = `${instructors[0].name} ${instructors[0].surname[0]}`;
        const otherInstructorCount = instructors.length - 1;
        if (otherInstructorCount > 0) {
          return (
            <Box>
              <Typography sx={{ fontSize: "14px" }}>
                {firstInstructor}
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                və {otherInstructorCount} daha
              </Typography>
            </Box>
          );
        } else {
          return (
            <Typography sx={{ fontSize: "14px" }}>{firstInstructor}</Typography>
          );
        }
      },
    },
    {
      field: "mentor",
      headerName: "Mentor/ M.K",
      flex: 1,
      renderCell: (params) => {
        const mentors = params?.row?.workers?.filter(
          (worker: any) => worker?.role === "Mentor"
        );
        if (!mentors || mentors.length === 0) {
          return (
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
            >
              -
            </Typography>
          ); // Eğer mentors null
        }
        const firstMentor = `${mentors[0].name} ${mentors[0].surname[0]}`;
        const otherMentorsCount = mentors.length - 1;

        if (otherMentorsCount > 0) {
          <Box>
            <Typography sx={{ fontSize: "14px" }}>{firstMentor}</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              və {otherMentorsCount} daha
            </Typography>
          </Box>;
        } else {
          return (
            <Typography sx={{ fontSize: "14px" }}>{firstMentor}</Typography>
          );
        }
      },
    },
    {
      field: "Hours",
      headerName: "D.S",
      flex: 1,
      hide: classesStatusQuery === 1,
      sortable: true,
      filterable: true,
      renderCell: (params) => {
        const currentHour = params?.row?.currentHour;
        const totalHours = params?.row?.totalHour;
        if (currentHour > 0 && totalHours > 0) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
                fontWeight: 700,
              }}
            >
              <>
                <CustomizedProgressBars
                  currentHour={currentHour}
                  totalHours={totalHours}
                />
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Visby CF Bold, sans-serif",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                  }}
                >
                  {`${currentHour}/${totalHours}`}
                </Typography>
              </>
            </Box>
          );
        } else {
          return (
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
            >
              -
            </Typography>
          );
        }
      },
    },
  ];
  const filteredClasses = data.filter((item) => {
    return item?.className?.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const handleChangeStatusQuery = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    if (newValue === 2) {
      dispatch(changeStatusTabs("close"));
      dispatch(setClassesStatusQuery(2));
    } else if (newValue === 1) {
      dispatch(changeStatusTabs("new"));
      dispatch(setClassesStatusQuery(1));
    } else {
      dispatch(change(""));
      dispatch(setClassesStatusQuery(0));
    }
  };
  useEffect(() => {
    setLoading(true);
    Promise.all([getPrograms(), getClassesStatusCount()])
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    setLoading(true);
    Promise.all([getClasses()])
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [classesStatusQuery, classFilters]);

  useEffect(() => {
    if (program.length == 0) {
      setData(classes);
    } else {
      const filteredData = classes.filter((item) =>
        program.includes(item.programName)
      );
      setData(filteredData);
    }
  }, [program, classes]);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const getPrograms = async () => {
    await programService
      .getAll()
      .then((res) => {
        const list = [...res.data.map((program: any) => program.name)];
        list.sort(function (a, b) {
          return a.localeCompare(b);
        });
        setProgram([]);
        setProgramsList(list);
      })
      .catch((err) => console.log(err));
  };
  const getClassesStatusCount = async () => {
    await classStatusCountService
      .getAll()
      .then((res) => {
        setClassesCountByStatus(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getClasses = async () => {
    await classService
      .getAll()
      .then((res) => {
        setClasses(res.data);
      })
      .catch((err) => console.log(err));
  };
  const handleClickStudentsDetail = (params: { id: GridRowId }) => {
    if (classesStatusQuery === 1) {
      navigate(`${APP_PREFIX_PATH}/set-program/${params.id}`);
    } else {
      navigate(`${APP_PREFIX_PATH}/group-detail/${params.id}`);
    }
  };
  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          <Header
            programs={programsList}
            program={program}
            setProgram={setProgram}
          />
          <TabHeader
            classesStatusQuery={classesStatusQuery}
            handleChangeStatusQuery={handleChangeStatusQuery}
            classesCountByStatus={classesCountByStatus}
          />
          <Box
            sx={{ mb: 2, position: "absolute", right: "250px", top: "108px" }}
          >
            <TextField
              sx={{
                width: "182px",
                padding: "6px 20px",
                alignItems: "center",
                borderRadius: "16px",
                background: "#F7F7F7",
                border: "none",
              }}
              autoFocus
              variant="standard"
              placeholder="Qrup axtar..."
              value={searchQuery}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ width: "24px", height: "24px", color: "#0E0D0D" }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={(event: any) =>
                setSearchQuery(event.target.value.trimStart())
              }
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              handleClickStudentsDetail={handleClickStudentsDetail}
              data={filteredClasses}
              columnsData={columns}
              pageSizeData={pageSize}
              setPageSize={setPageSize}
              disableColumnMenu={false}
              localeText={localeText}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
                pagination: {
                  labelRowsPerPage: "Səhifədəki sətir sayı:",
                },
              }}
            />
          </Box>
          {alertOpen && (
            <Alert
              open={alertOpen}
              text={alertInside.text}
              severity={alertInside.severity}
              handleClose={handleCloseAlert}
            />
          )}
        </>
      )}
    </>
  );
}

export default Groups;
