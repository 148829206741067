import React, { useState } from "react";
import Header from "./components/header/Header";
import { Grid, TextField } from "@mui/material";
import "dayjs/locale/az";
import { FormControl } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import localizedFormat from "dayjs/plugin/localizedFormat";
import azLocale from "dayjs/locale/az";
import { useNavigate} from "react-router-dom";
import { sxDate } from "../../MrpManagement/mrp/filter/style";
import { sxField } from "../../MrpManagement/mrp/components/modal/style";
import Button from "../../../../components/core-components/inputs/button";
import { APP_PREFIX_PATH } from "../../../../configs/Appconfig";
import Errors from "../../../auth-views/login/components/Errors";
import { sxFormControl } from "./styles";
import { useQueryClient } from "react-query";
import { useCreateHoliday } from "../../../../api/holidays";
import { apiRoutes } from "../../../../apiRoutes";


function Add() {
  const [startDate, setStartDate] = useState<any | null>("");
  const [description, setDescription] = useState<string>("");
  const [endDate, setEndDate] = useState<any | null>("");
  const [startDateError, setStartDateError] = useState<string>("");
  const [endDateError, setEndDateError] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<string>("");
  const createHolidayMutation = useCreateHoliday();
  const queryClient =useQueryClient();
  
  const validateInputs = () => {
    let isValid = true;

    if (!startDate) {
      setStartDateError("Başlanğıc tarixini seçin.");
      isValid = false;
    }

    if (!endDate) {
      setEndDateError("Bitiş tarixini seçin.");
      isValid = false;
    }

    if (!description) {
      setDescriptionError("Başlıq əlavə edin.");
      isValid = false;
    }

    return isValid;
  };

  const navigate = useNavigate();
  //const holidayService = new BaseService(`/api/holidays/`);
  dayjs.extend(localizedFormat);

  let capitalizedMonthNames: any[] = [];

  if (azLocale.months && Array.isArray(azLocale.months)) {
    capitalizedMonthNames = azLocale.months.map((monthName) => {
      return monthName.charAt(0).toUpperCase() + monthName.slice(1);
    });
  }

  const customAzLocale = {
    ...azLocale,
    months: capitalizedMonthNames,
  };
  function DateIcon() {
    return <CalendarTodayOutlinedIcon color="primary" />;
  }
  const handleSubmit = async () => {
    if (validateInputs()) {
      const formattedStartDate = dayjs(startDate!.$d!).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate!.$d!).format("YYYY-MM-DD");
      const data = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        description: description,
      };


      try {
        await createHolidayMutation.mutateAsync(data, {
          onSuccess: () => {
            queryClient.invalidateQueries(apiRoutes.holidays.getAll);
            navigate(`${APP_PREFIX_PATH}/holidays`);
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    dayjs.locale(customAzLocale);
  }, []);


  return (
    <>
        <Header />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl sx={sxFormControl}>
              <TextField
                onChange={(e) => {
                  setDescription(e.target.value);
                  setDescriptionError("");
                }}
                label="Başlıq"
                sx={sxField}
              />
              {descriptionError && <Errors message={descriptionError} />}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {" "}
            <FormControl fullWidth sx={sxFormControl}>
              <LocalizationProvider
                locale={customAzLocale}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={"Tarixdən"}
                  format="DD MMMM YYYY"
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setStartDateError("");
                  }}
                  sx={sxDate}
                  components={{
                    OpenPickerIcon: DateIcon,
                  }}
                />
              </LocalizationProvider>
              {startDateError && <Errors message={startDateError} />}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={sxFormControl}>
              <LocalizationProvider
                locale={customAzLocale}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={"Tarixinədək"}
                  format="DD MMMM YYYY"
                  value={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setEndDateError("");
                  }}
                  sx={sxDate}
                  components={{
                    OpenPickerIcon: DateIcon,
                  }}
                />
              </LocalizationProvider>
              {endDateError && <Errors message={endDateError} />}
            </FormControl>
          </Grid>
          <Grid xs={6} item>
            {" "}
            <Button onClick={handleSubmit}>Əlavə et</Button>
            
          </Grid>
        </Grid>
    </>
  );
}
export default Add;