import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertColor, FormControl, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Errors from "../../../../auth-views/login/components/Errors";
import { sxField } from "../../../MrpManagement/mrp/components/modal/style";
import Alert from "../../../../../components/custom-components/alert";
import Button from "../../../../../components/core-components/inputs/button";
import Progress from "../../../../../components/custom-components/progress";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
import { useAddWorker } from "../../../../../api/workers";
import { useQueryClient } from "react-query";
import { apiRoutes } from "../../../../../apiRoutes";


interface IFormikWorkerModel {
  name: string;
  surname: string;
  email: string;
  fincode: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Ad əlavə edin"),
  surname: Yup.string().required("Soyad əlavə edin"),
  email: Yup.string()
    .email("Email düzgün deyil")
    .matches(/^[a-zA-Z0-9_.+-]+@code.edu.az$/, "Code Academy emaili qeyd edin")
    .required("Email əlavə edin"),
  fincode: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, "Finkod xüsusi simvollardan ibarət olmamalıdır!")
    .length(7, "Finkod tam olaraq 7 simvol uzunluğunda olmalıdır!")
    .required("Fincode əlavə edin"),
});

function AddForm() {
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertInside, setAlertInside] = React.useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const addWorker = useAddWorker();

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleAdd = async (values: IFormikWorkerModel) => {
    setLoading(true);

    try {
      await addWorker.mutateAsync(values, {
        onSuccess: () => {
          queryClient.invalidateQueries(apiRoutes.workers.getAll);
          navigate(`${APP_PREFIX_PATH}/worker-classes`);
          setAlertInside({
            text: "İşçi əlavə edildi",
            severity: "success",
          });
          setAlertOpen(true);
        },
      });
    } catch (err: any) {
      if (err.statusCode === 409) {
        setAlertInside({
          text: "Bu email ilə işçi əlavə olunub",
          severity: "error",
        });
      } else {
        setAlertInside({
          text: "Xəta baş verdi",
          severity: "error",
        });
      }
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Progress />;
  }

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          surname: "",
          email: "",
          fincode: "",
        } as IFormikWorkerModel}  // Type assertion here
        validationSchema={validationSchema}
        onSubmit={handleAdd}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl
                  sx={{
                    display: "flex",
                  }}
                >
                  <Field
                    as={TextField}
                    name="name"
                    label="Ad"
                    sx={sxField}
                    error={touched.name && Boolean(errors.name)}
                    helperText={<ErrorMessage name="name" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  sx={{
                    display: "flex",
                  }}
                >
                  <Field
                    as={TextField}
                    name="surname"
                    label="Soyad"
                    sx={sxField}
                    error={touched.surname && Boolean(errors.surname)}
                    helperText={<ErrorMessage name="surname" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  sx={{
                    display: "flex",
                  }}
                >
                  <Field
                    as={TextField}
                    name="email"
                    label="Email"
                    sx={sxField}
                    error={touched.email && Boolean(errors.email)}
                    helperText={<ErrorMessage name="email" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  sx={{
                    display: "flex",
                  }}
                >
                  <Field
                    as={TextField}
                    name="fincode"
                    label="Fincode"
                    sx={sxField}
                    error={touched.fincode && Boolean(errors.fincode)}
                    helperText={<ErrorMessage name="fincode" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit">
                  Əlavə et
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default AddForm;
