import { useContext, useEffect, useState } from "react";
import ClassSelection from "./ClassSelection";
import LessonDuration from "./LessonDuration";
import { useGetWorkerLoginClass } from "../../../../../../api/workers";
import { Button } from "@mui/material";
import Calendar from '../../admin/components/Calendar';
import Modules from "../../admin/components/Modules";
import { useCancelSession, useGetClassById, useSaveAttendance } from "../../../../../../api/classes";
import { useParams, useSearchParams } from "react-router-dom";
import { AttendanceContext } from "../../../../../../context/AttendanceContext";
import { AttendanceStudentsContext } from "../../../../../../context/AttendanceStudentsContext";
import Alert from "../../../../../../components/custom-components/alert";
import { AlertColor } from '@mui/material/Alert';
import { useQueryClient } from "react-query";
import { apiRoutes } from "../../../../../../apiRoutes";

export default function Index({setClass, isAdmin = false, tabValue} : any) {

    const {id, date} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const mutation = useSaveAttendance(id as any);
    const cancelMutation = useCancelSession(id as any, date as any);
    const {modules, workers} = useContext(AttendanceContext);
    const {students} = useContext(AttendanceStudentsContext);
    const {data: workerLogin, isLoading} = useGetWorkerLoginClass();
    const {data: classAdmin, refetch: refetchAdmin} = useGetClassById(id as any, false);
    const [currentClass, setCurrentClass] = useState<any>(null);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertInside, setAlertInside] = useState({
      text: '',
      severity: 'success' as AlertColor,
    });

    useEffect(()=> {
        if(currentClass){
          setClass(currentClass);
          setSearchParams({class: currentClass.id})
        }
    }, [currentClass])

    useEffect(()=> {
      if(isAdmin){
        refetchAdmin();
      }
    }, [isAdmin])

    useEffect(()=> {
      if(classAdmin){
        setCurrentClass({
          attendanceHours: (classAdmin as any)?.currentHours,
          id: id,
          name: (classAdmin as any)?.name,
          totalHours: (classAdmin as any)?.totalHours,
          programId: (classAdmin as any)?.program?.id
        })
      }
    }, [classAdmin])

    const handleSave = async () => {
      try{
        const res = await mutation.mutateAsync({
          date: date,
          heldModules: modules.map((module: any)=> ({moduleId: module.id})),
          sessions: [
            {
              category: tabValue === 0 ? "Theoric" : "Lab",
              attendancesWorkers: workers.map((worker: any) => ({
                workerId: worker.workerId,
                workerType: worker.workerType,
                totalHours: worker.totalHours,
                totalMinutes: worker.totalMinutes,
              })),
              attendances: students.map((student: any)=> ({
                studyId: student.studyId,
                totalAttendanceHours: student.totalHours,
                note: student.note
              }))
            }
          ]
        })
        if(res.status === 204){
          setAlertOpen(true);
          setAlertInside({
            text: 'Yadda saxlanıldı!',
            severity: 'success',
          });
        } else{
          throw Error
        }
      } catch(err){
        setAlertOpen(true);
        setAlertInside({
          text: 'Xəta baş verdi!',
          severity: 'error',
        });
      }
    }

    const handleCancel = async () => {
      try{
        const res = await cancelMutation.mutateAsync({})
        setAlertOpen(true);
        setAlertInside({
          text: 'Ləğv edildi!',
          severity: 'success',
        });
      } catch(err){
        setAlertOpen(true);
        setAlertInside({
          text: 'Xəta baş verdi!',
          severity: 'error',
        });
      }
    }

    console.log(tabValue === "Dərs" && modules?.length === 0, tabValue)

  return (
    <>
      {
        (workerLogin && workerLogin.length > 0) || classAdmin ? (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex'}}>
                <ClassSelection isAdmin={isAdmin} workerLogin={workerLogin} classAdmin={classAdmin} currentClass={currentClass} setCurrentClass={setCurrentClass} />
                {
                  isAdmin && currentClass ? (
                    <>
                    {
                      tabValue === 0 ? (
                        <Modules classId={currentClass?.id} id={currentClass?.programId} />
                      ) : null
                    }
                      <Calendar classId={currentClass?.id} />
                    
                    </>
                  ) : null
                }
              </div>
              {
                isAdmin ? (
                  <div>
                    <Button
                    variant="contained"
                    className="saveBtn"
                    onClick={handleCancel}
                    // disabled={modules.length === 0 || workers.length < 2 || students.length === 0}
                    sx={{
                      background: "#D54939",
                      color: "#FFF",
                      padding: "8px 24px",
                      borderRadius: "12px",
                      fontFamily: "Visby CF Bold, sans-serif",
                      textTransform: "none",
                      mr: 1,
                      mt: '21px',
                      // "&:hover": {
                      //   background: "#6e3d7d",
                      // },
                    }}
                  >
                   Dərsi ləğv et
                  </Button>
                  <Button
                    variant="contained"
                    className="saveBtn"
                    onClick={handleSave}
                    disabled={(tabValue === 0 && modules?.length === 0) || workers?.length < 2 || students?.length === 0}
                    sx={{
                      background: "#854693",
                      color: "#FFF",
                      padding: "8px 24px",
                      borderRadius: "12px",
                      fontFamily: "Visby CF Bold, sans-serif",
                      textTransform: "none",
                      mt: '21px',
                      "&:hover": {
                        background: "#6e3d7d",
                      },
                    }}
                  >
                    Yadda saxla
                  </Button>
                  
                  </div>
                ) : (
                  <LessonDuration/>
                )
              }
          </div>
        ) : (
          <div>Qrup yoxdur!</div>
        )
      }
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={() => setAlertOpen(false)}
        />
      )}
    </>
  )
}

