import * as React from "react";
import { Box,Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IClass, ISession } from "../../../../../../types";
import { display } from "@mui/system";

interface TableProps {
  roomName: string;
  sessions: ISession[];
  handleAddSessioninGroup: (session: ISession) => void;
}
function TableView(props: TableProps) {
  const { roomName, sessions,handleAddSessioninGroup } = props;
  const handleSessionClassesStyle = (cls: IClass): [number, number] => {
    const startDate = new Date(cls.startDate);
    const endDate = new Date(cls.endDate);
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const startDay = startDate.getDate();
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth(); // getMonth() is zero-based (January = 0)
    const endDay = endDate.getDate();
    const endMonth = endDate.getMonth();
    

    let leftPixel = 175;
    let secondStartDayPixel = 0;
    let elementWidth = 0;

    if (currentYear === startYear) {
      if (startMonth > currentMonth) {
        leftPixel += 100 * (startMonth - currentMonth);
        secondStartDayPixel = Math.floor((startDay - 1) / 3) * 8.7;
        if (endMonth > startMonth) {
          elementWidth = (endMonth - startMonth) * 87;
        } else {
          elementWidth = (11-startMonth + endMonth) * 87;
        }
      }
      else if (startMonth === currentMonth) {
        secondStartDayPixel = Math.floor((startDay - 1) / 3) * 8.7;

        if (endMonth > startMonth) {
          if(startDay>15){
            elementWidth =
            (endMonth - startMonth - (currentMonth - startMonth-1)) * 87;
          }else{
            elementWidth =
            (endMonth - startMonth - (currentMonth - startMonth)) * 87;
          }
      } else {
        if(startDay>15){
          elementWidth =
          (11-startMonth + endMonth - (currentMonth - startMonth-1)) * 87;
        }else{
          elementWidth =
          (11-startMonth + endMonth - (currentMonth - startMonth)) * 87;
        }
         
      }
      }
       else {
        if (endMonth > startMonth) {
            if(startDay>15){
              elementWidth =
              (endMonth - startMonth - (currentMonth - startMonth-1)) * 87;
            }else{
              elementWidth =
              (endMonth - startMonth - (currentMonth - startMonth)) * 87;
            }
        } else {
          if(startDay>15){
            elementWidth =
            (11-startMonth + endMonth - (currentMonth - startMonth-1)) * 87;
          }else{
            elementWidth =
            (11-startMonth + endMonth - (currentMonth - startMonth)) * 87;
          }
           
        }
      }
    } else if (startYear > currentYear) {
      if (endMonth > startMonth) {
        elementWidth = (endMonth - startMonth) * 87;
      } else {
        elementWidth = (startMonth - endMonth) * 87;
      }
      let offSetMonth = 11 - currentMonth;
      leftPixel += (startMonth + offSetMonth) * 87;
      secondStartDayPixel = Math.floor((startDay - 1) / 3) * 8.7;
    }
    const totalLeftPixel = leftPixel + secondStartDayPixel;

    const secondEndDayPixel = Math.floor((endDay - 1) / 3) * 10;
    const totalWidthPixel = elementWidth + secondEndDayPixel;

    return [totalLeftPixel, totalWidthPixel];
  };
  return (
    <>
      {sessions
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((session, index) => (
          <TableRow key={session.id} sx={{ position: "relative" ,cursor:"pointer"}} onClick={() =>handleAddSessioninGroup(session)
          }>
            {index % 5 == 0 ? (
              <TableCell
                rowSpan={5}
                sx={{
                  width: "120px",
                  fontFamily: "Visby CF Bold, sans-serif",
                  padding: "8px 0px 8px 2px",
                }}
                align="center"
              >
                {roomName}
              </TableCell>
            ) : (
              ""
            )}
            <TableCell
              sx={{
                width: "100px",
                padding: "8px 0px 8px 2px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
                borderLeft: "1px solid var(--Natural-natural-50, #E9E9E9)",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
              align="left"
            >
              {`${session.name}. Seans`}
            </TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            <TableCell
              sx={{
                width: "100px",
                borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
              }}
              align="right"
            ></TableCell>
            {session.classes
              .filter((c) => {
                const endDate = new Date(c.endDate);
                const endMonth = endDate.getMonth(); //4
                const currentMonth = new Date().getMonth(); //9
                const currentYear = new Date().getFullYear();
                const endYear = endDate.getFullYear();

                let result;

                if (endYear > currentYear) {
                  result = true;
                } else if (endYear == currentYear) {
                  if (endMonth >= currentMonth) {
                    result = true;
                  } else {
                    result = false;
                  }
                }

                return result;
              })
              .map((cls, index) => {
                const [leftPixel, widthPixel] = handleSessionClassesStyle(cls);
                return (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      left: `${leftPixel}px`,
                      width: `${widthPixel}px`,
                      height: "36px",
                      padding: "4px 8px",
                      background: "#CCE7F5",
                      borderRadius: "5px",
                      borderLeft: "2px solid #0086CD",
                    }}
                  >
                    <Box sx={{display:"flex", flexDirection:'column',alignItems:"flex-start",gap:"4px"}}>
                      <Typography sx={{fontFamily:'Visby CF Bold, sans-serif',fontSize:"10px",textTransform:"uppercase"}}>
                        {cls.name}
                      </Typography>
                      <Typography sx={{fontSize:"8px", fontFamily: "Visby CF Medium"}}>22 Fev - 3 Iyul</Typography>
                    </Box>
                  </Box>
                );
              })}
          </TableRow>
        ))}
    </>
  );
}

export default TableView;
