import {
  Select,
  TableRow,
  Button,
  TextField,
  Popover,
  Checkbox,
  TableCell,
  MenuItem,
  Typography,
} from '@mui/material';
import { ChatLinesIcon } from '../../../../../../../components/custom-components/chat-lines';
import { useState, useContext } from 'react';
import { AttendanceStudentsContext } from '../../../../../../../context/AttendanceStudentsContext';
import { AttendanceContext } from '../../../../../../../context/AttendanceContext';

export const sxSelect = {
  '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input':
    {
      padding: '0',
      fontFamily: 'Visby CF Medium',
      fontSize: '14px',
    },
  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
};

export const sxSelectInside = {
  '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input':
    {
      fontWeight: '400',
      width: '40px'
    },
  width: '100%',
};

const sxTableCell: React.CSSProperties = {
  fontSize: '14px',
  color: '#000000',
  padding: '12px 16px',
};
export const sxButtonIcon: React.CSSProperties = {
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  position: 'relative',
  minWidth: '20px',
};

export const sxPoint: React.CSSProperties = {
  width: '8px',
  height: '8px',
  background: '#D54939',
  border: '2px solid #FFFFFF',
  position: 'absolute',
  top: '-1px',
  right: '-2px',
  borderRadius: '50%',
};

export default function Row({
  index,
  id,
  fullName,
  attendance,
  note,
  totalHours,
  isChecked,
  isDisabled
}: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [innerNote, setInnerNote] = useState<string>(note);
  const {
    handleStudentChecked,
    handleStudentNoteAdd,
    handleStudentTotalHoursChange,
  } = useContext(AttendanceStudentsContext);
  const { totalHours: selectTotalHours } = useContext(AttendanceContext);

  const handleCommentOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCloseCommentOpen = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleNoteChange = (val: string) => {
    setInnerNote(val);
  };

  return (
    <>
      <TableRow>
        <TableCell sx={[sxTableCell]}>
          <Checkbox
            disabled={isDisabled}
            checked={isChecked}
            onClick={() => handleStudentChecked(id)}
          />
        </TableCell>
        <TableCell sx={[sxTableCell]}>{index}</TableCell>
        <TableCell sx={[sxTableCell]}>{fullName}</TableCell>
        <TableCell sx={[sxTableCell]}>
          {
            isDisabled ? (
              <span>İcazəli</span>
            ) : (
              <Select
                value={totalHours ?? 0}
                defaultValue={totalHours ?? 0}
                disabled={isDisabled}
                renderValue={()=>{
                  if(totalHours !== null){
                    return (<span>{totalHours}</span> )
                  } else{
                    return (<span style={{color: 'grey'}} >{selectTotalHours[selectTotalHours?.length -1]}</span> )
                  }
                }}
                onChange={(e) => handleStudentTotalHoursChange(id, e.target.value)}
                sx={[sxSelect, sxSelectInside]}
              >
                {selectTotalHours?.map((elem: any) => (
                  <MenuItem key={elem} value={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            )
          }
        </TableCell>
        <TableCell sx={[sxTableCell]}>
          <Button onClick={(e) => handleCommentOpen(e)} sx={sxButtonIcon}>
            <ChatLinesIcon student={{}} />
          </Button>
        </TableCell>
        <TableCell sx={[sxTableCell]} align="right">
          {Math.floor(attendance)}%
        </TableCell>
      </TableRow>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleCloseCommentOpen()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <TextField
          variant="outlined"
          defaultValue={''}
          multiline
          disabled={isDisabled}
          onChange={(e: any) => handleNoteChange(e.target.value)}
          value={innerNote}
          rows={3}
          inputProps={{
            maxLength: 300,
          }}
          placeholder="Qeyd..."
          sx={{ width: '100%' }}
          onBlur={(e) => {
            if (innerNote !== note) {
              handleStudentNoteAdd(id, e.target.value);
            }
          }}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      </Popover>
    </>
  );
}
