import React, { useState } from "react";
import styles from "./modal.module.css";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import Frame from "./frame";
import { TextField } from '@mui/material';
import TabContent from "./tab";
import Button from "../../../../../../../components/core-components/inputs/button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';



export interface ModalProps {
    status: any;
    start_date: any;
    end_date: any;
    type:any ;
    file: any ;
    teacher: any;
    group: any;
    onClose: () => void;  
 
}


export default function Modal(props: ModalProps) {

  const {onClose } = props;

  const [confirm,setConfirm] = useState<number>(0);
  const [reject, setReject] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState('');
  const handleCloseModal = () => {
    setConfirm(0);
    setReject(0);
    onClose();
  };

  const handleChangeValue = (event: any) => {
    setSelectedValue(event.target.value);
  };



  const actions = [
    {
      "id" : "1",
      "desc": "Könül Həmidova sorğunu təsdiq etdi.Könül Həmidova sorğunu təsdiq etdi.Könül Həmidova sorğunu təsdiq etdi.Könül Həmidova sorğunu təsdiq etdi.",
      "date": " 24 Avq 2023 | 16:33"
    },

    {
      "id" : "2",
      "desc": "Könül Həmidova sorğunu təsdiq etdi.",
      "date": " 24 Avq 2023 | 16:33"
    }
    ,
    {
      "id" : "3",
      "desc": "Könül Həmidova sorğunu təsdiq etdi.",
      "date": " 24 Avq 2023 | 16:33"
    }
  ]

  const comments = [
    {
      "id" : "1",
      "desc":"Kifayət qədər keçərli səbəb sayılmır.",
      "date": "24 Avq 2023 | 16:33",
      "name": "Oksana Sokolova"
    },
    {
      "id" : "2",
      "desc":"Kifayət qədər keçərli səbəb sayılmır.",
      "date": "24 Avq 2023 | 16:33",
      "name": "Oksana Sokolova"
    }
    ,
    {
      "id" : "1",
      "desc":"Kifayət qədər keçərli səbəb sayılmır.",
      "date": "24 Avq 2023 | 16:33",
      "name": "Oksana Sokolova"
    },
    {
      "id" : "2",
      "desc":"Kifayət qədər keçərli səbəb sayılmır.",
      "date": "24 Avq 2023 | 16:33",
      "name": "Oksana Sokolova"
    }
  ]
    const {
        status,
        start_date,
        end_date,
        type,
        file,
        teacher,
        group
    } = props;

    const [value, setValue] = useState(0);

  // Handle tab change
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
   };


  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "546px",
          padding: "60px 0px 10px 0px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        
       {(confirm != 0 || reject != 0)  ?(<svg 
      onClick={()=>{setConfirm(0); setReject(0)}}
      style={{
        width: "24px",
        height: "24px",
        position: "absolute",
        left: "24px",
        top: "24px",
        cursor:"pointer"
      }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M15 18L9 12L15 6" stroke="#999898" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>): null}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          style={{
            width: "24px",
            height: "24px",
            position: "absolute",
            right: "24px",
            top: "24px",
          }}
        >
          <path
            d="M18 6L6 18"
            stroke="#999898"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="#999898"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

      {(confirm === 0 && reject === 0)  ?  (<Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "44px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
              flexShrink: 0,
            }}
          >
            <Box
              sx={{
                width: "32px",
                height: "32px",
                flexShrink: 0,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                style={{}}
              >
                <circle cx="16.5" cy="16" r="16" fill="#E5FFE8" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  x="8"
                  y="8"
                >
                  <path
                    d="M13.8332 4L6.49984 11.3333L3.1665 8"
                    stroke="#4BA458"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </svg>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "12px", fontFamily: "Visby CF Regular" }}>
                Program rəhbəri
              </Typography>
            </Box>
            <Box sx={{ marginTop: "-10px" }}>
              <Typography sx={{ fontSize: "12px", fontFamily: "Visby CF Bold" }}>
                Fərid Rzazadə
              </Typography>
            </Box>
          </Box>

          <hr
            style={{
              color: "##4BA458",
              backgroundColor: "#4BA458",
              width: "90px",
              strokeWidth: "2px",
              borderColor: "#4BA458",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
              flexShrink: 0,
            }}
          >
            <Box
              sx={{
                width: "32px",
                height: "32px",
                flexShrink: 0,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <circle cx="16.5" cy="16" r="16" fill="#FBEDEB" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  x="8"
                  y="8"
                >
                  <path
                    d="M12 4L4 12"
                    stroke="#D54939"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="#D54939"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </svg>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "12px", fontFamily: "Visby CF Regular" }}>
                Akademik department
              </Typography>
            </Box>
            <Box sx={{ marginTop: "-10px" }}>
              <Typography sx={{ fontSize: "12px", fontFamily: "Visby CF Bold" }}>
                Ulviyya Imanova
              </Typography>
            </Box>
          </Box>
        </Box>): null}


        <Box sx={{display: "flex",
                alignItems:" center",
                gap: "12px",
                flex: "1 0 0",
                marginRight:"300px"}}>
        <div style={{width:"52px",height:"52px",flexShrink:"0",borderRadius:"52px",
            background: 'url(<path-to-image>) lightgray 50% / cover no-repeat'}} >
        </div>
        <Box>
        <Typography sx={{fontFamily: "Visby CF BOld",fontSize:"16px"}}>{teacher}</Typography>
        <Typography sx={{color: "var(--Natural-natural-300, #6B696A)"
                }}>{group} | Career plus</Typography></Box>
        </Box>

        {reject != 0 ? 
        (<Box sx={{height:"514px"}}>
          <Box sx={{display:"flex",
          padding:"12px 24px",
          flexDirection:"column",
          alignItems:"flex-start",
          gap:"4px",
          alignSelf:"stretch",
          borderRadius:"  16px",
          backgroundColor:"#F7F7F7",
          width:"482px",
          marginLeft:"-20px"
        }}>
          <Typography sx={{fontSize:"16px",fontFamily:"Visby CF Bold",color:"#4E4C4D"}}>İmtina səbəbi:</Typography>
          <FormControl>
     
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={selectedValue}
        onChange={handleChangeValue}
        
      >
        <FormControlLabel value="1" control={<Radio />} label="Davamiyyət azlığı" />
        <FormControlLabel value="2" control={<Radio />} label="Keçərli səbəb deyil" />
        <FormControlLabel value="3" control={<Radio />} label="İcazə haqqınız yoxdur" />
        <FormControlLabel value="other" control={<Radio />} label="Digər" />
      </RadioGroup>
    </FormControl>
        </Box>

       {selectedValue === 'other' ? (<TextField
      placeholder="Digər imtina səbəbini daxil edin..."
      variant="outlined"
      sx={{
        backgroundColor: "#F7F7F7",
        width: "482px",
        marginLeft: "-20px",
        marginTop:"8px",
        display: "flex",
        border:"0px",
        height: "113px",
        borderRadius:"16px",
padding: "16px 24px",
          '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',  // Kənarları tamamilə gizlədir
          },
          '&:hover fieldset': {
            border: 'none',  // Hover zamanı da kənarları gizlədir
          },
          '&.Mui-focused fieldset': {
            border: 'none',  // Fokus halında da kənarları gizlədir
          },
        }
      }}
    />): null}
        </Box>)
         : null}


      {(confirm === 0 && reject === 0)  ? ( <Frame 
        status={status}
         type={type} 
         start_date={start_date} 
         end_date={end_date} 
         file={file}></Frame>): null}

        {(confirm === 0 && reject === 0) ?
        (  <Box sx={{width:"482px",marginLeft:"20px"}} className = {`${styles.notebox}`}>
                <Typography 
                sx={{fontSize:"14px",fontFamily:"Visby CF Bold"}}>Qeyd:</Typography>
                <Typography sx={{fontSize:"14px", fontFamily:"Visby CF Regular",marginTop:"-22px"}}>Xəstəliklə bağlı 2 gün dərsdə iştirak edə bilməyəcəm. Bu səbədən sənəd təqdim edirəm...</Typography>

          </Box> ): null}
         
             {(status === "reject" )? (<Box sx={{display: "flex",
padding: "12px 24px",
flexDirection: "column",
justifyContent: "center",
alignItems: "flex-start",
gap: "2px",
alignSelf:" stretch",
borderRadius:"16px",
backgroundColor:"#F7F7F7",
width:"482px",
marginLeft:"20px"}}>

<Box sx={{display:"flex", gap:"8px", alignItems:"center"}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clip-path="url(#clip0_13842_1190)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM6.92082 6.08541C7.00133 5.92438 7.11032 5.78617 7.25977 5.68654C7.40453 5.59004 7.63124 5.5 8 5.5C8.27975 5.5 8.55291 5.58656 8.7375 5.725C8.90206 5.84842 9 6.01009 9 6.25C9 6.42662 8.95919 6.51381 8.92283 6.56835C8.87748 6.63639 8.79924 6.71162 8.64646 6.81349C8.57007 6.86441 8.48788 6.9139 8.38754 6.97411L8.38141 6.97779L8.38139 6.9778C8.28769 7.03402 8.17704 7.10041 8.06831 7.1729C7.84608 7.32107 7.58057 7.52712 7.37593 7.83412C7.14619 8.17878 7.23935 8.64442 7.58401 8.87417C7.92867 9.10391 8.39432 9.01075 8.62406 8.66609C8.66942 8.59803 8.74766 8.52279 8.90043 8.42093C8.97681 8.37001 9.059 8.32052 9.15933 8.26032L9.16554 8.25659L9.1656 8.25656C9.25926 8.20036 9.36986 8.134 9.47854 8.06154C9.70075 7.91339 9.96627 7.70737 10.1709 7.4004C10.3846 7.07994 10.5 6.69838 10.5 6.25C10.5 5.48991 10.1396 4.90158 9.6375 4.525C9.15542 4.16344 8.55358 4 8 4C7.36876 4 6.84547 4.15996 6.42772 4.43846C6.01467 4.71383 5.74866 5.07562 5.57918 5.41459C5.39393 5.78507 5.5441 6.23558 5.91459 6.42082C6.28507 6.60606 6.73558 6.45589 6.92082 6.08541Z" fill="#468CBC"/>
  </g>
  <defs>
    <clipPath id="clip0_13842_1190">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
<Typography sx={{fontSize:"16px", color:"#4E4C4D",fontFamily:"Visby CF Regular"}}>İmtina səbəbi:</Typography></Box>
<Typography sx={{fontSize:"16px", fontFamily:"Visby CF Bold",color:"#4F5259", marginLeft:"23px"}}>Az Davamiyyət</Typography>

</Box>) : (status === "apply") ?

(<Box sx={{display: "flex",
  padding: "12px 24px",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf:" stretch",
  borderRadius:"16px",
  backgroundColor:"#F7F7F7",
  width:"482px",
  marginLeft:"20px"}}>


<Box sx={{display:"flex", gap:"8px", alignItems:"center"}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clip-path="url(#clip0_13842_1190)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM6.92082 6.08541C7.00133 5.92438 7.11032 5.78617 7.25977 5.68654C7.40453 5.59004 7.63124 5.5 8 5.5C8.27975 5.5 8.55291 5.58656 8.7375 5.725C8.90206 5.84842 9 6.01009 9 6.25C9 6.42662 8.95919 6.51381 8.92283 6.56835C8.87748 6.63639 8.79924 6.71162 8.64646 6.81349C8.57007 6.86441 8.48788 6.9139 8.38754 6.97411L8.38141 6.97779L8.38139 6.9778C8.28769 7.03402 8.17704 7.10041 8.06831 7.1729C7.84608 7.32107 7.58057 7.52712 7.37593 7.83412C7.14619 8.17878 7.23935 8.64442 7.58401 8.87417C7.92867 9.10391 8.39432 9.01075 8.62406 8.66609C8.66942 8.59803 8.74766 8.52279 8.90043 8.42093C8.97681 8.37001 9.059 8.32052 9.15933 8.26032L9.16554 8.25659L9.1656 8.25656C9.25926 8.20036 9.36986 8.134 9.47854 8.06154C9.70075 7.91339 9.96627 7.70737 10.1709 7.4004C10.3846 7.07994 10.5 6.69838 10.5 6.25C10.5 5.48991 10.1396 4.90158 9.6375 4.525C9.15542 4.16344 8.55358 4 8 4C7.36876 4 6.84547 4.15996 6.42772 4.43846C6.01467 4.71383 5.74866 5.07562 5.57918 5.41459C5.39393 5.78507 5.5441 6.23558 5.91459 6.42082C6.28507 6.60606 6.73558 6.45589 6.92082 6.08541Z" fill="#468CBC"/>
  </g>
  <defs>
    <clipPath id="clip0_13842_1190">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
<Typography sx={{fontSize:"16px", color:"#4E4C4D",fontFamily:"Visby CF Regular"}}>Dərsin formatı:</Typography></Box>
<Box sx={{display: "flex",
flexDirection:"column",
justifyContent: "center",
alignItems:" flex-end",
gap: "2px"}}>

  <Typography sx={{fontSize:"12px", color:"#999898", fontFamily:"Visby CF Regular"}}>Əvəzedici müəllim:</Typography>
  <Typography sx={{fontSize:"14px", color:"#4E4C4D", fontFamily:"Visby CF Bold"}}>Tapdıq Əliyev</Typography>
  </Box>

</Box>) 


: null }   
        
        
        {(confirm === 0 && reject === 0) ?( <TabContent 
      value={value} 
      onChange={handleChange} 
      actions={actions} 
      comments={comments} 
         />): null}

{confirm !=0 ?(
  <Box sx={{height:"514px", width:"482px"}}>
  <Typography sx={{fontSize:"16px"}}><span style={{fontFamily:"Visby CF Bold"}}>Ulviyya İmamova</span> adlı tələbəyə sənəd təqdim etdiyi halda icazə verilə bilər.
     Onlayn iştirak etdiyi təqdirdə davamiyyətdə “Dərsdə iştirak etmiş” kimi əks olunacaq.
    <span style={{fontFamily:"Visby CF Bold"}}>  İcazəni təsdiq etmək istədiyinizdən əminsiniz?</span></Typography>
</Box>): null}
<Box sx={{ marginLeft: "20px", width: "490px" }} className={`${styles.addcomment}`}>
  <Box className={`${styles.image}`}></Box>

  <TextField
    variant="outlined"
    placeholder="Rəy yazın..."
    InputProps={{
      disableUnderline: true, 
      sx: {
        fontSize: "14px",
        fontFamily: "Visby CF Regular",
        color: "#999898",
        flex: "1 0 0",
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, 
      }
    }}
    sx={{
      width: "100%",
      '& .MuiInputBase-root': {
        paddingLeft: "0", 
      }
    }}
  />
  
  <div >
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
      <path d="M25.3309 12.0118C25.3402 11.2951 24.952 10.6364 24.3215 10.2907L9.62962 2.21852C8.97157 1.8466 8.19584 1.8923 7.57577 2.31572C6.94431 2.74627 6.62111 3.77121 6.79763 4.51148L8.16918 10.2577C8.30998 10.847 8.83734 11.2619 9.44405 11.2593L17.62 11.2339C18.0387 11.2255 18.3783 11.5651 18.3699 11.9838C18.3686 12.3953 18.0341 12.7299 17.6154 12.7383L9.43159 12.7573C8.82489 12.7584 8.29495 13.1752 8.15049 13.7653L6.73044 19.5344C6.55802 20.2162 6.7546 20.8968 7.23563 21.3778C7.29222 21.4344 7.35588 21.4981 7.41959 21.5475C8.04254 22.0281 8.85838 22.0895 9.55493 21.7183L24.2967 13.7252C24.9292 13.3897 25.3216 12.7285 25.3309 12.0118Z" fill="#468CBC" />
    </svg>
  </div>
</Box>

{status==="wait" ?(<Box sx={{display:"flex",gap:"16px",alignItems:"center",marginRight:"10px"}}>
  {confirm === 0 ?(<Button variant="text"
            type="submit"
            onClick={() => {
              setReject(reject + 1);
              if (reject !== 0) {
                  handleCloseModal();
              }
          }}
                sx={{backgroundColor:reject === 0 ? "#FBEDEB" : "#D54939",
                                        
                  padding: "10px 24px 10px 24px",
                  gap: "8px",
                  border:'none',
                  borderRadius: "12px",
                  opacity: "0px",
                  boxShadow:"none",
                  fontFamily: 'Visby CF Bold, sans-serif',
                  textTransform:"none",
                  width: reject === 0 ? "239px" : "482px" ,
                  ":hover": {
                    backgroundColor: reject === 0 ? "#FBEDEB" : "#D54939", // Hover rengini sabit tutmak için
                    opacity: 0.9 // Hafif bir opaklık ekleyerek hover efektini güçlendirebilirsiniz
                  },

                      
                  }}><Typography 
                  sx={{fontSize:"16px",fontFamily: 'Visby CF Bold, sans-serif',color:reject === 0 ?"black" : "white"}}>İmtina et</Typography>
                  </Button>): null}

   {reject === 0 ?(<Button variant="text"
            type="submit"
            onClick={()=> {setConfirm(confirm+1);
              if (confirm !== 0) {
                handleCloseModal();}
            }
            }
                sx={{backgroundColor:"#4BA458",
                                   
                  padding: "10px 24px 10px 24px",
                  gap: "8px",
                  border:'none',
                  borderRadius: "12px",
                  opacity: "0px",
                  boxShadow:"none",
                  fontFamily: 'Visby CF Bold, sans-serif',
                  textTransform:"none",
                  width: confirm === 0 ? "239px" : "482px" ,
                  ":hover": {
                    backgroundColor: "#4BA458", // Hover rengini sabit tutmak için
                    opacity: 0.9 // Hafif bir opaklık ekleyerek hover efektini güçlendirebilirsiniz
                  },
                      
 
 }}><Typography sx={{fontSize:"16px",fontFamily: 'Visby CF Bold, sans-serif',color:"white", ":hover": {textDecoration:"none"}}}>Təsdiq et</Typography>
 </Button>): null}
 
</Box>): null}

<Box sx={{alignSelf:"stretch",marginLeft:"23px",marginTop:"-10px"}}>
  <span style={{fontSize:"12px"}}>İcazə</span>
  <span style={{fontFamily:"Visby CF Bold",fontSize:"12px"}}> 28 Yan 2024</span>
  <span style={{fontSize:"12px"}}> tarxində əlavə edilmişdir.</span>
</Box>

      </Box>

    
    </>
  );
}
