import Header from '../../../../components/core-components/header';
import { APP_PREFIX_PATH } from '../../../../configs/Appconfig';
import { useForm, SubmitHandler} from "react-hook-form";
import { Grid, TextField, Dialog, Slide } from "@mui/material";
import DatePicker from '../../../../components/core-components/datePicker';
import Button from "../../../../components/core-components/inputs/button";
import CustomAutocomplete from "../../../../components/custom-components/custom-autocomplete";
import { sxField } from '../../MrpManagement/mrp/components/modal/style';
import { apiRoutes } from '../../../../apiRoutes';
import Progress from '../../../../components/custom-components/progress';
import DataGrid from "../../../../components/custom-components/data-grid";
import { GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { IGroupRequestModel } from '../types';
import { ApiInstance } from '../../../../utils/api/api';
import uuid from 'react-uuid';
import { TransitionProps } from "@mui/material/transitions";
import GroupDetails from './GroupDetails';


const columns: GridColDef[] = [
  { field: 'fullName', 
    headerName: 'Ad Soyad',
    flex: 1,
  },
  { field: 'email', 
    headerName: 'Email',
    flex: 2,
  },
  { field: 'programName', 
    headerName: 'Proqram',
    flex: 1,
  },
  { field: 'group', 
    headerName: 'Qrup',
  },
  { field: 'roomName', 
    headerName: 'Otaq',
    flex: 1,
  },
  { field: 'moduleName', 
    headerName: 'Modul',
    flex: 2,
    // renderCell: (params) => {
    //   return (
    //     <Button sx={{ fontSize: "14px" }}>
    //       {params.row?.moduleName}
    //     </Button>
    //   );
    // },
  },
  { field: 'startDate', 
    headerName: 'Başlama tarixi',
    flex: 1,
  },
  { field: 'endDate', 
    headerName: 'Bitmə tarixi',
    flex: 1,
  },
  { 
    field: 'roles', 
    headerName: 'Rol',
    flex: 1,
  },
]

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Index() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IGroupRequestModel>();

  const onSubmit: SubmitHandler<IGroupRequestModel> = async (data)=> {
    setIsLoading(true);
    try{
      const response = await ApiInstance.get(apiRoutes.workers.filteredDatas );
      let newFilteredData: any = response.data
        .filter((user: any) => user.roles?.includes('muellim'))
        .map((user: any) => ({
          ...user,
          sessions: user.sessions
            .map((session: any) => {
              const classStartDate = session.getFilteredDataClasses[0].startDate;
              const classEndDate = session.getFilteredDataClasses[session.getFilteredDataClasses.length - 1].endDate;

              const isWithinDateRange =
                (!data.startDate || new Date(classStartDate) >= new Date(data.startDate)) &&
                (!data.endDate || new Date(classEndDate) <= new Date(data.endDate));

              return {
                ...session,
                getFilteredDataClasses: session.getFilteredDataClasses
                  .filter(
                    (cls: any) =>
                      (!data.startDate || !data.endDate || isWithinDateRange) && 
                      (!data.group || cls.className.toLowerCase() === data.group?.toLowerCase()) && 
                      (!data.programName || cls.programName.toLowerCase() === data.programName.name.toLowerCase())
                  )
                  .sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()),
              };
            })
            .filter((session: any) => session.getFilteredDataClasses.length > 0),
        }))
        .filter((user: any) => {
          const fullUserName = `${user.name} ${user.surname}`.toLowerCase();
          return user.sessions.length > 0 && (!data.teacherName || fullUserName?.includes(data.teacherName.toLowerCase()));
        });

      if (!data.startDate && !data.endDate) {
        newFilteredData = newFilteredData.map((user: any) => ({
          ...user,
          sessions: user.sessions.map((session: any) => ({
            ...session,
            getFilteredDataClasses: session.getFilteredDataClasses.sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
          }))
        }));
      }

      const final = newFilteredData.map((user: any) =>
      user.sessions.map((session: any) =>
      session.getFilteredDataClasses
      .filter((cls: any) => cls.module && cls.module.subModules.length > 0)
      .map((cls: any)=> {
        return {
                id: uuid(),
                fullName: `${user.name} ${user.surname}`, 
                email: user.eMail, 
                programName: cls.programName, 
                group: cls.className,
                roomName: cls.roomName,
                moduleName: cls.module.name,
                startDate: cls.startDate,
                endDate: cls.endDate,
                roles: user.roles.join(', '),
                subModules: cls.module.subModules
              }
      })))[0]?.flat()

      setData(final.length > 0 ? final : []);
    }catch(err){
      console.error(err);
    }finally{
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setCurrent(null);
    setOpenModal(false);
  }

  const handleOpen = (elem: any) => {
    setCurrent(elem.row);
    setOpenModal(true)
  }

  return (
    <>
      <Header links={[{name: 'Home', url: APP_PREFIX_PATH}]} current='Tədris Utilizasiyası'/>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4} sx={{mb:3}}>
          <Grid item xs={12}>
              <TextField
                label="Ad Soyad"
                sx={{...sxField, width: '100%'}}
                error={errors.teacherName?.message !== undefined}
                {...register("teacherName")}
              />
          </Grid>
          <Grid item xs={12}>
            <CustomAutocomplete
              control={control}
              {...register("programName")}
              label="Proqramı seçin"
              url="programs"
              propName='name'
              selectedProp="programName"
              noOptionText='Proqram yoxdu'
              sx={{ "& .MuiOutlinedInput-root": {
                borderRadius: "0",
                padding: "5px"
              }, ...sxField}}
              errors={errors.programName?.message !== undefined}
            />
          </Grid>
          <Grid item xs={12}>
              <TextField
                label="Qrup"
                sx={{...sxField, width: '100%'}}
                error={errors.group?.message !== undefined}
                {...register("group")}
              />
          </Grid>
          <Grid item xs={6}>
            <DatePicker error={errors.startDate?.message !== undefined} control={control} {...register("startDate")} label="Tarixdən" name="startDate"/>
            <span style={{fontSize: '14px', color: 'red'}}>{errors.startDate?.message}</span>
          </Grid>
          <Grid item xs={6}>
            <DatePicker error={errors.endDate?.message !== undefined} control={control} {...register("endDate")} label="Tarixinədək" name="endDate"/>
            <span style={{fontSize: '14px', color: 'red'}}>{errors.endDate?.message}</span>
          </Grid>
        </Grid>
        <Button type="submit" >Axtar</Button>
      </form>
      {
        isLoading ? (
          <Progress/>
        ) :
        data.length > 0 ? (
          <>
            <div style={{marginTop: '24px'}}>
              <DataGrid
                data={data}
                columnsData={columns}
                rowsPerPageOptions={[10]}
                pageSizeData={pageSize}
                setPageSize={setPageSize}
                autoHeight
                onRowClick={(elem: any) => handleOpen(elem)}
              />
            </div>
            <Dialog
                open={openModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullScreen
                sx={{
                  "& .css-hz1bth-MuiDialog-container": {
                    backdropFilter: "blur(2px)",
                    position: "relative",
                    justifyContent: "end"
                  },
                  "& .MuiPaper-rounded.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation24": {
                    padding: "40px 30px",
                    maxWidth: "50%",
                    background: "var(--other-white, #FFF)",
                    position: "static",
                    scrollbarWidth: "thin", // Firefox için gizli scroll çubuğu
                    "::-webkit-scrollbar": {
                      width: "0.0em", // Diğer tarayıcılar için gizli scroll çubuğu
                    },
                    "::-webkit-scrollbar-thumb": {
                      backgroundColor: "#000000",
                    },
                  },
                }}
              >
              <GroupDetails group={current}/>
            </Dialog>
          </>
        ) :null
      }
    </>
  )
}
