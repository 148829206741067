import { useNavigate } from "react-router-dom";
import { Dialog, Slide, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as MagicStick } from "../../../../../../src/assets/icons/magic-stick.svg";
import { Button, Typography, Snackbar, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { sxBtn } from "../style";
import TableSetProgram from "./components/table-set-program";
import { useState, useEffect, useMemo } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Progress from "../../../../../components/custom-components/progress";
import { BaseService } from "../../../../../network/services/base/BaseService";
import React from "react";
import GroupInfo from "../components/group-info";
import { StudentHeaderDetail } from "../models/StudentDetail";
import { useParams } from "react-router-dom";
import { ApiInstance } from "../../../../../utils/api/api";
import { headerCells } from "./components/table-set-program/headerCells";
import {
  useGetClassAssignWorkers,
  useGetClassById,
  useGetClassModulesCalculation,
  useGetStudentsHeaderById,
} from "../../../../../api/classes";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const baseService = new BaseService("api");
function SetProgram() {
  const { id } = useParams<{ id: string }>(); // Extract classId from route
  const navigate = useNavigate();
  const [modules, setModules] = useState<any[]>([]);
  const [modulesCalculation, setModulesCalculation] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    text: "",
  });
  const [boxHeight, setBoxHeight] = useState<number>(180);
  const { data: classHeaderData, isLoading: isLoadingHeader } =
    useGetStudentsHeaderById(+id!);
  const {
    data: dataModules,
    refetch: modulesRefetch,
    isLoading,
  } = useGetClassAssignWorkers(+id!, false);
  const { data: dataModulesCalc, refetch: refetchModulesCalc } =
    useGetClassModulesCalculation(+id!, false);
  useEffect(() => {
    modulesRefetch();
    refetchModulesCalc();
  }, [id]);
  useEffect(() => {
    setModules(dataModules);
    setModulesCalculation(dataModulesCalc);
  }, [dataModules, dataModulesCalc]);

  const hasWorkersForTeachers = useMemo(() => {
    return modules?.filter(module => module.workerType === "Teacher")
                  .every(module => module.workers.length > 0);
  }, [modules]);
  
  const handleClose = () => {
    setOpenModal(false);
  };
  const handlePost = async () => {
    try {
      setBtnLoading(true);
      const modulesToUpdate: any[] = [];

      modules.forEach((module: any) => {
        let workersToPush: any = [];
        module.workers.forEach((worker: any) => {
          if (worker.id !== null && worker.id !== undefined) {
            workersToPush.push({
              workerId: worker.id,
            });
          }
        });
        let moduleToPush = {
          startDate: module.startDate,
          endDate: module.endDate,
          totalHours: module.totalHours,
          workerType: module.workerType,
          workers: workersToPush,
        };
        modulesToUpdate.push(moduleToPush);
      });
      const res = await ApiInstance.post(
        `api/Classes/${id}/assign-workers`,
        modulesToUpdate
      );
      if (res.status) {
        setSnackbar({ open: true, text: "Yadda saxlanılıb" });
        setTimeout(() => {}, 1500);
      } else {
        setSnackbar({ open: true, text: "Xəta baş verdi. Bir daha dənəyin." });
      }
    } catch (err) {
    } finally {
      setBtnLoading(false);
    }
  };
  const handleSave = () => {
    setModules([...modulesCalculation]);
    setOpenModal(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        {isLoading && isLoadingHeader ? (
          <Progress />
        ) : (
          <>
            <Box display="flex" justifyContent="space-between">
              <GroupInfo studentsHeaderDetail={classHeaderData} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                  padding: "8px 20px",
                  borderRadius: "12px",
                  backgroundColor: "#F3EDF4",
                  alignSelf: "flex-end", // Prevent it from stretching to the parent's height
                }}
              >
                <Typography
                  onClick={() => setOpenModal((oldValue) => !oldValue)}
                  sx={{
                    fontFamily: "Visby CF Bold, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  Modul üzrə bölgü
                </Typography>
                <MagicStick />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 5,
                height: "100vh",
                pb: 3,
              }}
            >
              <Box sx={{ flexGrow: 1, overflowY: "auto", height: boxHeight }}>
                <TableSetProgram
                  daysSession={classHeaderData?.session.details}
                  generationStartDate={classHeaderData?.startDate}
                  hasCheckBox={false}
                  headerCells={headerCells}
                  classId={id}
                  customSX={{ mb: 2 }}
                  modules={modules}
                  setModules={setModules}
                />
              </Box>
            </Box>
          </>
        )}
        {/* Fixed Button Container */}
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            left: 0,
            right: 50,
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            gap: 2,
            padding: "10px 20px",
          }}
        >
          <Button
            sx={[{ background: "#F3EDF4", color: "#0E0D0D" }, sxBtn]}
            onClick={() => navigate(-1)} // Navigate back to the previous page
          >
            Ləğv et
          </Button>
          {btnLoading ? (
            <LoadingButton sx={sxBtn} loading variant="outlined">
              Təsdiqlə
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={sxBtn}
              onClick={handlePost}
              disabled={!hasWorkersForTeachers}
            >
              Təsdiqlə
            </Button>
          )}
        </Box>
        <Snackbar
          sx={{ backgroundColor: "green" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          open={snackbar.open}
          onClose={() => setSnackbar({ open: false, text: "" })}
          message={snackbar.text}
        />
      </Box>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullScreen
        sx={{
          "& .css-hz1bth-MuiDialog-container": {
            backdropFilter: "blur(2px)",
            position: "relative",
          },
          "& .MuiPaper-rounded.MuiPaper-root.MuiPaper-elevation": {
            padding: "40px 100px",
            maxWidth: "none",
            marginTop: "250px",
            borderRadius: " 32px 32px 0px 0px",
            background: "var(--other-white, #FFF)",
            position: "static",
            scrollbarWidth: "thin", // Firefox için gizli scroll çubuğu
            "::-webkit-scrollbar": {
              width: "0.0em", // Diğer tarayıcılar için gizli scroll çubuğu
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#000000",
            },
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 40,
            top: 70,
            color: "#FFFFFF",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          component={"h1"}
          variant="h5"
          fontFamily={"Visby CF Bold, sans-serif"}
          sx={{ mb: 2 }}
        >
          Modullar üzrə bölgü
        </Typography>
        <Box sx={{ flexGrow: 1, overflowY: "auto", height: boxHeight }}>
          <TableSetProgram
            daysSession={classHeaderData?.session.details}
            generationStartDate={classHeaderData?.startDate}
            hasCheckBox={false}
            headerCells={[
              ...headerCells,
              {
                text: "Modul adı",
                key: "moduleName",
              },
            ]}
            classId={id}
            customSX={{ mb: 2 }}
            modules={modulesCalculation}
            setModules={setModulesCalculation}
          />
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 50,
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            gap: 2,
            padding: "10px 20px",
            backgroundColor: "#FFF",
          }}
        >
          <Button
            sx={[{ background: "#F3EDF4", color: "#0E0D0D" }, sxBtn]}
            onClick={() => navigate(-1)} // Navigate back to the previous page
          >
            Ləğv et
          </Button>
          {btnLoading ? (
            <LoadingButton
              sx={sxBtn}
              loading
              variant="outlined"
            >
              Təsdiqlə
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={sxBtn}
              onClick={handleSave}
            >
              Tətbiq et
            </Button>
          )}
        </Box>
      </Dialog>
    </>
  );
}
export default SetProgram;
