import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  FormControl,
  Typography,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import azLocale from "dayjs/locale/az";
import PageHeader from "../../../../../components/custom-components/page-header";
import Breadcrumbs from "../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../../components/custom-components/page-title";
import { API_URL, APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { sxFormControl } from "./style";
import { useEffect, useState } from "react";
import { sxDate } from "../../../MrpManagement/mrp/filter/style";
import Errors from "../../../../auth-views/login/components/Errors";
import {
  useGetProgramsByProjectId,
  useGetProjectsList,
} from "../../../../../api/projects";
import { useGetSessionsList } from "../../../../../api/sessions";
import {
  useGetRoomsList,
  useGetRoomsPlanningList,
} from "../../../../../api/rooms";
import {
  IClass,
  IRoomesPlaning,
  IRoomModel,
  ISessionModel,
} from "../../../../../types";
import TableView from "../components/table-view";
import Progress from "../../../../../components/custom-components/progress";
import HeaderRow from "../components/table-row";
import Footer from "../components/footer";
const showcaseDates = ["1 Həftə", "2 Həftə", "3 Həftə", "4 Həftə"];
function AddGroup() {
  const [startDate, setStartDate] = useState<any | null>("");
  const [startDateError, setStartDateError] = useState<string>("");
  const [endDate, setEndDate] = useState<any | null>("");
  const [endDateError, setEndDateError] = useState<string>("");
  const [selectedRooms, setSelectedRooms] = useState<IRoomModel[]>([]);
  const [selectedSessions, setSelectedSessions] = useState<ISessionModel[]>([]);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [programId, setProgramIdId] = useState<number | null>(null);
  const { data: projects } = useGetProjectsList();
  const { data: programs } = useGetProgramsByProjectId(projectId!);
  const { data: sessions } = useGetSessionsList();
  const { data: rooms } = useGetRoomsList();
  const [roomsPlaningsLocal, setRoomsPlaningsLocal] = useState<IRoomesPlaning[]>()
  const [newGenerateClass,setNewGenerateClass] = useState<IClass>()
 

  const { data: roomesPlanings, isLoading } = useGetRoomsPlanningList(
    selectedSessions.map((room) => room.id!),
    selectedRooms.map((room) => room.id!)
  );
  useEffect(() => {
    setRoomsPlaningsLocal(roomesPlanings)
  },[roomesPlanings])
 
  const validateInputs = () => {
    let isValid = true;

    if (!startDate) {
      setStartDateError("Başlanğıc tarixini seçin.");
      isValid = false;
    }

    if (!endDate) {
      setEndDateError("Bitiş tarixini seçin.");
      isValid = false;
    }
    return isValid;
  };
  let capitalizedMonthNames: any[] = [];

  if (azLocale.months && Array.isArray(azLocale.months)) {
    capitalizedMonthNames = azLocale.months.map((monthName) => {
      return monthName.charAt(0).toUpperCase() + monthName.slice(1);
    });
  }
  const customAzLocale = {
    ...azLocale,
    months: capitalizedMonthNames,
  };
  useEffect(() => {
    dayjs.locale(customAzLocale);
  }, []);
  const handleAddSessioninGroup = (session: ISessionModel) => {
    //  axios
    // .get(API_URL + `api/Classes/generate-name?programId=${programId}&sessionId=${session.id}&startDate=${startDate}`,
    //   {
    //     withCredentials: true,
    //   }
    // ).then((res) => {
    //   setNewGenerateClass(res.data)
    //   console.log(newGenerateClass,"newGenerateClass");
      
    // })
    setRoomsPlaningsLocal((prev) => {
      const newRoomsPlanings = prev?.map((room) => {
        if (room.sessions.some((s) => s.id === session.id)) {
          return {
            ...room,
            sessions: room.sessions.map((s) => {
              if (s.id === session.id) {
                // Add the new class to the session's classes array
                return {
                  ...s,
                  classes: [...s.classes,{id:newGenerateClass?.id!,name:newGenerateClass?.name!,startDate:startDate,endDate:endDate,studentCount:0,isNew:true}],
                };
              }
              return s;
            }),
          };
        }
        return room;
      });
      return newRoomsPlanings;
    });
  };
  return (
    <>
      <PageHeader>
        <Box>
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Akademiya" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Qruplar" />
          </Breadcrumbs>
          <PageTitle name="Qrup yarat" />
        </Box>
      </PageHeader>
      <Grid container spacing={4} padding="20px">
        <Grid item xs={4}>
          {" "}
          <FormControl fullWidth sx={sxFormControl}>
            <LocalizationProvider
              locale={customAzLocale}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label={"Başlama tarixi"}
                format="DD MMMM YYYY"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setStartDateError("");
                }}
                sx={sxDate}
              />
            </LocalizationProvider>
            {startDateError && <Errors message={startDateError} />}
          </FormControl>
        </Grid>{" "}
        <Grid item xs={4}>
          <Autocomplete
            sx={sxDate}
            fullWidth
            id="combo-box-demo"
            options={showcaseDates}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Vitrin tarixi"
                id="fullWidth"
              />
            )}
            onChange={(date) => {
              setEndDate(date);
              setEndDateError("");
            }}
          />
          {endDateError && <Errors message={endDateError} />}
        </Grid>
      </Grid>
      <Grid container spacing={4} padding="20px">
        <Grid item xs={4}>
          <Autocomplete
            sx={sxDate}
            fullWidth
            id="combo-box-demo"
            getOptionLabel={(option) => `${option.name}`}
            options={projects || []}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Lahiyə" id="fullWidth" />
            )}
            onChange={(e, value) => {
              setProjectId(value?.id!);
            }}
          />
          {endDateError && <Errors message={endDateError} />}
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            disabled={!projectId}
            sx={sxDate}
            fullWidth
            id="combo-box-demo"
            getOptionLabel={(option) => `${option.name}`}
            options={programs || []}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Tədris proqramı seçin"
                id="fullWidth"
              />
            )}
            onChange={(e, value) => {
              setProgramIdId(value?.id!);
            }}
          />
          {endDateError && <Errors message={endDateError} />}
        </Grid>
      </Grid>
      {startDate && endDate && projectId && programId && (
        <Box
          padding="20px 32px 32px 32px"
          display={"flex"}
          flexDirection="column"
          alignItems="flex-start"
          borderRadius="16px"
          gap="12px"
          sx={{ border: "1px solid rgba(0, 0, 0, 0.05)" }}
        >
          <Typography fontFamily="Visby CF Bold, sans-serif">
            Aktiv və planlanan qruplar
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Autocomplete
                sx={sxDate}
                fullWidth
                id="combo-box-demo1"
                disableCloseOnSelect
                getOptionLabel={(option) => `${option.name}`}
                options={sessions || []}
                renderOption={(props, option) => (
                  <MenuItem {...props}>
                    <Checkbox checked={selectedSessions.indexOf(option) > -1} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Seans"
                    id="fullWidth1"
                    placeholder={
                      selectedSessions.length > 0 ? "" : "Select Seans"
                    }
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedSessions
                        .map((room) => room.name)
                        .join(", "),
                    }}
                  />
                )}
                onChange={(e, value) => {
                  if (value === null) {
                    setSelectedSessions([]);
                  } else if (
                    selectedSessions.some(
                      (sessions) => sessions.id === value.id
                    )
                  ) {
                    setSelectedSessions(
                      selectedSessions.filter(
                        (sessions) => sessions.id !== value.id
                      )
                    );
                  } else {
                    setSelectedSessions([...selectedSessions, value]);
                  }
                }}
                clearOnEscape
              />
              {endDateError && <Errors message={endDateError} />}
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                disableCloseOnSelect
                sx={sxDate}
                fullWidth
                id="combo-box-demo"
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(props, option) => (
                  <MenuItem {...props}>
                    <Checkbox checked={selectedRooms.indexOf(option) > -1} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                )}
                options={rooms || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Otaq"
                    id="fullWidth"
                    placeholder={selectedRooms.length > 0 ? "" : "Select Rooms"}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedRooms
                        .map((room) => room.name)
                        .join(", "),
                    }}
                  />
                )}
                onChange={(e, value) => {
                  if (value === null) {
                    setSelectedRooms([]);
                  } else if (
                    selectedRooms.some((room) => room.id === value.id)
                  ) {
                    setSelectedRooms(
                      selectedRooms.filter((room) => room.id !== value.id)
                    );
                  } else {
                    setSelectedRooms([...selectedRooms, value]);
                  }
                }}
                clearOnEscape
              />
              {endDateError && <Errors message={endDateError} />}
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Checkbox />
            <ListItemText primary={"Yalnız boş otaqlar"} />
          </Box>
          {isLoading ? (
            <Progress />
          ) : (
            <TableContainer
              component={Paper}
              sx={{ overflow: "hidden", padding: 0 }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <HeaderRow />
                </TableHead>
                <TableBody>
                  {roomsPlaningsLocal &&
                    roomsPlaningsLocal.map(({ name, sessions }: IRoomesPlaning) => (
                      <TableView sessions={sessions} roomName={name} handleAddSessioninGroup={handleAddSessioninGroup} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
        <Footer handleClick={() => {}} />
    </>
  );
}

export default AddGroup;
