import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ActionButtonsProps {
  handleSubmit: () => void;
  isDisabled: boolean
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ handleSubmit, isDisabled }) => {

  const navigate =useNavigate();

  return (
    <Grid sx={{ marginLeft: '400px', gap: '16px' }} item xs={12}>
      <Button
        variant="contained"
        
        onClick={() => navigate('/admin/app/myrequests')}
        sx={{
          width: '105px',
          backgroundColor: '#F3EDF4' ,
          borderRadius: '12px',
          height: '48px',
          boxShadow: 'none',
          ':hover': {
            opacity:"0.9",
            backgroundColor: '#F3EDF4' ,
            boxShadow:"none"
          },
        }}
      >
        <Typography
          sx={{
            color: 'black',
            fontFamily: 'Visby CF Bold',
            fontSize: '16px',
            textTransform: 'none',
          }}
        >
          Ləğv et
        </Typography>
      </Button>

      <Button
      disabled={isDisabled}
        sx={{
          marginLeft: '16px',
          width: '105px',
          
          borderRadius: '12px',
          height: '48px',
          boxShadow: 'none',
          ':hover': {
            opacity:"0.9",
            
            boxShadow:"none"
          },
        }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        <Typography
          sx={{
            color: isDisabled ? 'black' : 'white',
            fontFamily: 'Visby CF Bold',
            textTransform: 'none',
          }}
        >
          Göndər
        </Typography>
      </Button>
    </Grid>
  );
};

export default ActionButtons;
