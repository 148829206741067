import { useState, forwardRef } from 'react';
import {Autocomplete, TextField,createFilterOptions} from '@mui/material';
import { BaseService } from '../../../network/services/base/BaseService';
import { Controller } from "react-hook-form";


const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: WorkerModel) => `${option.name} ${option.surname}`,
});

interface WorkerModel{
name:string 
surname: string
}

export interface ICustomAutocompleteModel{
    label: string;
    url?: string;
    customData?: any;
    propName: string;
    defaultValue?: any;
    sx?: any;
    onChange?: any;
    value?:any;
    selectedProp?: any;
    control?: any;
    errors?: boolean;
    disable?: boolean;
    noOptionText?: string;
}

const CustomAutocomplete = forwardRef(function CustomAutocomplete( {disable, control, label, url, value, customData, propName, defaultValue, onChange, sx, selectedProp, errors, noOptionText}: ICustomAutocompleteModel
  ,ref: React.Ref<unknown>) {
    const [data, setData] = useState(customData ? customData : []);
    const [loading, setLoading] = useState<boolean>(false);


    const fetchData = async () => {
        
        setLoading(true);
        try {
          const service = new BaseService(`api/`);
          const res = await service.getAll(
            `api/${url}`
          );
          if (res.statusCode === 200) {
            setData(res.data)
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false)
        }
    }
  return control !== undefined ? (
    <Controller
      render={({ field: { onChange, value } }) => (
        <Autocomplete
              disabled={disable}
              defaultValue={defaultValue}
              value={value}
              inputValue={value ? value[propName] : ''}
              loading={loading}
              noOptionsText={noOptionText ?? 'Empty'}
              id="combo-box-demo"
              clearIcon={false}
              options={data}
              
              placeholder="Seç"
              onChange={(e, v) => onChange((v as any))}
              onOpen={()=> url != null ? fetchData() : null}
              getOptionLabel={(option)=> {
                return option ? (!propName ? `${(option as any)?.name} ${(option as any)?.surname}` : (option as any)?.[propName]) : ''} }
              slotProps={{
                paper: {
                    sx: {padding: "0px!important",
                    marginTop: "0px!important",
                    borderRadius: "0px!important"}
                }
              }}
              sx={ sx ? sx : {
                // border: "1px solid blue",
                "& .MuiOutlinedInput-root": {
                    // border: "1px solid yellow",
                    borderRadius: "0",
                    padding: "0"
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee"
                }
            }}

            renderInput={(params) => (
                <TextField
                    {...params}
                    error={errors}
                    margin="none"
                    placeholder='Seç'
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            padding: "calc(0.5vw + 5px)",
                            fontSize: "calc(0.5vw + 5px)",
                            // border: "1px solid red"
                        },
                    }}
                />
            )}
              fullWidth
          />
      )}
      defaultValue={defaultValue}
      name={selectedProp}
      control={control}
    />
  ) : (
    <Autocomplete
              defaultValue={defaultValue}
              value={value}
              loading
              id="combo-box-demo"
              clearIcon={false}
              options={data}
              filterOptions={filterOptions}
              placeholder="Seç"
              onChange={(e, v) => onChange((v as any))}
              onOpen={()=> url != null ? fetchData() : null}
              getOptionLabel={(option)=> {
                return option.name !== undefined ? (propName === 'name' ? `${(option as any)?.name} ${(option as any)?.surname}` : (option as any)?.[propName]) : ''} }
              slotProps={{
                paper: {
                    sx: {padding: "0px!important",
                    marginTop: "0px!important",
                    borderRadius: "0px!important"}
                }
              }}
              sx={ sx ? sx : {
                // border: "1px solid blue",
                "& .MuiOutlinedInput-root": {
                    // border: "1px solid yellow",
                    borderRadius: "0",
                    padding: "0"
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee"
                }
            }}

            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="none"
                    placeholder='Seç'
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            padding: "calc(0.5vw + 5px)",
                            fontSize: "calc(0.5vw + 5px)",
                            // border: "1px solid red"
                        },
                    }}
                />
            )}
              fullWidth
          />
  )
})

export default CustomAutocomplete;