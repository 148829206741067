import React, { useState } from "react";
import { Box, InputAdornment,Typography,TextField} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import Button from "../../../../../../../../components/core-components/inputs/button";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAltOutlined';
import styles from './../header2/header2.module.css';
import { useNavigate } from "react-router-dom";
import StatusModal from "../../modal2";





interface Header2Props {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

function Header2({searchQuery,setSearchQuery}: Header2Props) {
  const navigate = useNavigate(); 
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [value,setValue] = useState<number>(0);

  return (
    <>
      <Box className={styles.inline}>
        <Box sx={{alignItems:"center", justifyContent:"space-between", gap:"0px", display:"flex", width:"1100px"}} >
          {/* Title */}
          <Grid >
            <h4 className={styles.headertext}>İcazələrim</h4>
          </Grid>

          {/* Search Input */}
          <Grid>
          <Box display="flex" alignItems="center" className={styles.search} sx={{marginLeft:"100px"}}>
            <TextField
             variant="standard"
              
              placeholder="Axtar"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={styles.searchbutton} />
                  </InputAdornment>
                ),
                disableUnderline: true
              }}
            />
          </Box>
        </Grid>
           {/* Add Button */}
           <Grid>
            <Button
            onClick={() => {setOpenModal(true); setValue(value+1)}}
            variant="text"
            type="submit"
                sx={{backgroundColor:"#F3EDF4",
                                   
                  padding: "10px 24px 10px 24px",
                  gap: "8px",
                  border:'none',
                  borderRadius: "12px",
                  opacity: "0px",
                   boxShadow:"none",
                  fontFamily: 'Visby CF Bold, sans-serif',
                      
                  }}
              
                  >
              <FilterAltIcon sx={{color:"#854693",height:"20px"}}/><Typography sx={{fontFamily: "Visby CF Bold",
              fontSize: "14px",
              textAlign: "left",
              color:" #0E0D0D",
              textTransform:"none",}}>Status</Typography>
            </Button>
          </Grid>

          {/* Add Button */}
          <Grid >
            <Button
            onClick={()=> navigate("/admin/app/myrequests/newrequest")}
            >
              <AddIcon sx={{height:"20px"}} />İcazə əlavə et
            </Button>
          </Grid>
        </Box>
        <StatusModal open={openModal}
                    value={value}
                    
                    
        ></StatusModal> 
      </Box>
    </>
  );
}

export default Header2;
