import { Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AttendanceContext } from "../../../../../../context/AttendanceContext";
import { Kpi } from "../../../../EducationSystemManagement/groups/components/kpi";

export default function LessonDuration() {

  const {totalHours, startTime, setEndTime, isActive, setIsActive, isWarning, setIsWarning} = useContext(AttendanceContext);
  const [elapsedSeconds, setElapsedSeconds] = useState(0); // Elapsed time in seconds
  const [isRunning, setIsRunning] = useState(true); // Stop when timer reaches endTime


  // Helper function to parse "hh:mm:ss" string into seconds
  const parseTimeToSeconds = (time: any) => {
    const [hours, minutes, seconds] = time?.split(":").map(Number);
    return hours * 3600 + minutes * 60 + (seconds || 0); // Account for seconds
  };

  // Helper function to format seconds into hh:mm:ss
  const formatTime = (seconds: any) => {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(Math.floor(seconds % 60)).padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
  
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0"); // Ensure 3 digits
  
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  useEffect(() => {
    if (startTime) {
      const startSeconds = parseTimeToSeconds(startTime);
      const endSeconds = startSeconds + totalHours[totalHours.length - 1] * 3600;
      const now = new Date();
      const currentSeconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
      
      const initialElapsed = currentSeconds - startSeconds;
      
      if (currentSeconds >= endSeconds) {
        setElapsedSeconds(endSeconds - startSeconds);
        setIsRunning(false);
        setIsActive(false);
        setEndTime(getCurrentTime());
        return;
      }
  
      setElapsedSeconds(initialElapsed > 0 ? initialElapsed : 0);
  
      const interval = setInterval(() => {
        setElapsedSeconds((prevElapsed) => {
          const remainingTime = endSeconds - (startSeconds + prevElapsed);
          
          // Show tooltip when 15 minutes remain
          if (remainingTime <= 900) {
            setIsWarning(true);
            // setTimeout(() => setIsWarning(false), 5000); // Auto-hide after 5 seconds
          }
  
          if (prevElapsed >= endSeconds - startSeconds) {
            clearInterval(interval);
            setIsRunning(false);
            return prevElapsed;
          }
          
          return prevElapsed + 1;
        });
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [startTime, totalHours]);
  



  return (
    <>
      <Kpi
        value={
          (startTime && isActive) ? (<div>{formatTime(elapsedSeconds)}</div>) : (<div>00:00:00</div>)
        }
        color={(startTime && isActive)
          ? isWarning ? '#D54939' : "#4BA458" 
          : '#6B696A'}
        tagTitle="Dərs müddəti"
        iconColor={(startTime && isActive) 
          ? isWarning ? '#F2C7C2' : "#C7E3CB" 
          : '#6B696A'}
      />
    </>
  )
}