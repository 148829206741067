import React from "react";
export const calculateDateRange = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    return Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert time difference from milliseconds to days
  };

export  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();

    const monthsInAzerbaijani = [
      'Yan', 'Fev', 'Mar', 'Apr', 'May', 'İyu',
      'İyu', 'Avq', 'Sen', 'Okt', 'Noy', 'Dek'
    ];

    const month = monthsInAzerbaijani[date.getMonth()];

    return `${day} ${month}`;
  };

  export const formattoDDMMYY = (dateString: string) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);

    return `${day}.${month}.${year}`;
  };