import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import Header from "./components/header/Header";
import Progress from "../../../../components/custom-components/progress";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DataGrid from "../../../../components/custom-components/data-grid";
import { Link } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { sxEditIcon } from "../../EducationSystemManagement/groups/style";
import { APP_PREFIX_PATH } from "../../../../configs/Appconfig";
import ConfirmModal from "../../../../components/custom-components/confirm-modal";
import Button from "../../../../components/core-components/inputs/button";
import { sxBoxCenter } from "./styles";
import { useGetHolidaysList,useDeleteHoliday } from "../../../../api/holidays";
import { HolidayModel } from "./models";

import { apiRoutes } from "../../../../apiRoutes";
import { useQueryClient } from 'react-query';

interface Holiday extends HolidayModel {
  startDateObj: Date;
}

function Holidays() {
  const queryClient = useQueryClient();
  const { data, isLoading } = useGetHolidaysList();
  const [id, setId] = useState<string>("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const deleteMutation = useDeleteHoliday();

 const handleDelete = async () => {
  try {
    const res = await deleteMutation.mutateAsync(id);
    console.log(res)
  
  if(res.status==200 || res.status==201)
  {
    queryClient.invalidateQueries(apiRoutes.holidays.getAll);
  }
    
  } finally {
    setDeleteConfirmationOpen(false);
  }
};


  const handleClose = () => {
    setDeleteConfirmationOpen(false);
  };

  // Sorting and transforming data directly within the component
  const sortedHolidays = data
    ? data
        .map((holiday: HolidayModel) => ({
          ...holiday,
          startDateObj: new Date(holiday.startDate),
        }))
        .sort((a: Holiday, b: Holiday) => b.startDateObj.getTime() - a.startDateObj.getTime())
    : [];

  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: "Başlıq",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Başlama Tarixi",
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("DD MMMM YYYY"),
    },
    {
      field: "endDate",
      headerName: "Bitmə Tarixi",
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("DD MMMM YYYY"),
    },
    {
      field: "operations",
      headerName: "Əməliyyatlar",
      flex: 0.4,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box sx={sxBoxCenter}>
            <Tooltip title="Düzəliş et">
              <Link to={`${APP_PREFIX_PATH}/holidays/${params.row.id}`}>
                <EditOutlinedIcon color={"secondary"} sx={sxEditIcon} />
              </Link>
            </Tooltip>
            <Tooltip title="Sil">
              <Box
                onClick={() => {
                  setDeleteConfirmationOpen(true);
                  setId(params.row.id);
                }}
              >
                <DeleteOutlineIcon color={"secondary"} sx={sxEditIcon} />
              </Box>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  if (isLoading) {
    return <Progress />;
  }

  return (
    <>
      <Header />
      <Box sx={{ width: "100%", height: "auto" }}>
        <Link to={`${APP_PREFIX_PATH}/holidays/new`}>
          <Button>Yeni</Button>
        </Link>
        <DataGrid
          data={sortedHolidays}
          columnsData={columns}
          pageSizeData={pageSize}
          setPageSize={setPageSize}
          disableColumnMenu={true}
        />
      </Box>
      {deleteConfirmationOpen && (
        <ConfirmModal
          open={deleteConfirmationOpen}
          handleClose={handleClose}
          handleAgree={() => handleDelete()}
          text={"Seçilmiş tarixi silmək istədiyinizə əminsiniz?"}
          isWarning={false}
          sendBtnText="Sil"
        />
      )}
    </>
  );
}

export default Holidays;