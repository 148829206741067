import AppLayout from "./layouts/app-layout";
import { useSelector } from "react-redux";
import { RootState } from "./store/reducers";
import { useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./Error";

const queryClient = new QueryClient();

function App() {
  const isLoggedIn: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const loading = useSelector((state: RootState) => state.auth.isLoading);
  const navigate = useNavigate();

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <QueryClientProvider client={queryClient}>
      <>
        {loading ? (
            <div className="loader"></div>
            ) : (
                <>
                  {isLoggedIn ? <AppLayout/> : navigate("/auth/login")}
                </>)
        }
      </>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
