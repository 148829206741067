import {
  useDelete,
    useFetch, usePost, useUpdate,
} from '../utils/api/reactQuery';
import { apiRoutes } from '../apiRoutes';
import { IClassModel, IClassCreateModel, IUpdateClassModel, IClassHeaderDetail, } from '../types';


export const useGetClassesList = () =>
  useFetch<IClassModel[]>(apiRoutes.classes.getAll);

export const useGetUnmarkedAttendancesList = (id: number) =>
  useFetch<any>(apiRoutes.classes.getUnmarkedAttendances(id));

export const useCreateClass = (newClass: IClassCreateModel ) => 
  usePost<IClassCreateModel, IClassCreateModel>(apiRoutes.classes.create, newClass);

export const useGetClassById = (id: number, isEnabled: boolean = true) => 
  useFetch<IClassModel>(apiRoutes.classes.getById(id), {}, [apiRoutes.classes.getById(id), undefined], isEnabled);

export const useGetSessionClassById = (id: number, category: string, isEnabled = true) => 
  useFetch<any>(apiRoutes.classes.sessions(id, category), [], [apiRoutes.classes.sessions(id, category),  undefined], isEnabled);

export const useGetSessionClassByIdAdmin = (id: number, date: string, category: string, isEnabled = true) => 
  useFetch<any>(apiRoutes.classes.sessionsAdmin(id, date, category), {}, [apiRoutes.classes.sessionsAdmin(id, date, category), undefined], isEnabled);

export const useUpdateClass = (id: number, updatedClass: IUpdateClassModel) => 
  useUpdate<IUpdateClassModel, IUpdateClassModel>(apiRoutes.classes.update(id), updatedClass);

export const useSaveAttendance = (id: number) => 
  usePost<any, any>(apiRoutes.classes.saveLesson(id));

export const useCancelSession = (id: number, date: string) => 
  usePost<any, any>(apiRoutes.classes.cancelLesson(id, date));

export const useDeleteClass = (id: number) => 
  useDelete(apiRoutes.classes.delete(id));

export const useGetClassStudents = (id: number) => useFetch<any>(apiRoutes.classes.students(id));
export const useGetStudentsHeaderById = (id: number) => 
  useFetch<IClassHeaderDetail>(apiRoutes.classes.getById(id));
export const useGetClassWorkers = (id: number) => useFetch<any>(apiRoutes.classes.workers(id));
export const useGetClassAssignWorkers=(id: number,isEnabled:boolean=true) => useFetch<any>(apiRoutes.classes.assignWorkers(id),{}, [apiRoutes.workers.getAll, undefined], isEnabled);
export const useGetClassModulesCalculation=(id: number,isEnabled:boolean) => useFetch<any>(apiRoutes.classes.modulesCalculation(id),{}, [apiRoutes.workers.getAll, undefined], isEnabled);
export const usePostTotalHoursByDate =(id:number,)=>usePost<any, any>(apiRoutes.classes.totalHours(id));;

