export const datagridSx = {     

    '& .MuiDataGrid':
    {
    border:'none'
    },
    
    '& .MuiDataGrid-root': {
      border: 'none', // Remove outer border of the DataGrid
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid var(--Natural-natural-100, #BABABA)',  // Remove cell borders
     // Adjust padding for a cleaner look
    fontFamily: 'Visby CF Bold',
    fontSize: '16px',
    background:"#FFFFFF",
    padding:'0px 0px 0px 50px',
    
  
    
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#F3EDF496',  // Header background color
   
    borderBottom: 'none',  // Remove header bottom border
    fontSize: '16px',
    fontFamily: 'Visby CF Bold',
      
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '16px',
      fontFamily: 'Visby CF Bold',
      border: 'none',
      padding:'0px 0px 0px 90px',
      
      
      
    },
    '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[data-field="enddate"] .MuiDataGrid-columnHeaderTitle': {
    padding: '0px 0px 0px 40px', // Specific padding for the "enddate" column
  },
  
    '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[data-field="status"] .MuiDataGrid-columnHeaderTitle': {
    padding: '0px 0px 0px 50px', // Specific padding for the "enddate" column
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[data-field="startdate"] .MuiDataGrid-columnHeaderTitle': {
    padding: '0px 0px 0px 90px', // Specific padding for the "enddate" column
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[data-field="title"] .MuiDataGrid-columnHeaderTitle': {
    padding: '0px 0px 0px 60px', // Specific padding for the "enddate" column
  },
    '& .MuiDataGrid-footerContainer': {
        border: 'none',
        right: '100px'
      // Remove footer borders if present
    },
    '& .MuiDataGrid-sortIcon': {
        border: 'none',
      display: 'none', // Hide sort icons if necessary
    },
    '& .MuiDataGrid-virtualScroller': {
        
      border: 'none', // Remove border around the virtual scroller area
      paddingBottom: '20px', 
    },
    '& .MuiDataGrid-row': {
       
      minHeight: '84px'
    },
    '& .MuiDataGrid-columnSeparator': {
    display: 'none',  // Hide the small vertical lines between columns
  },
  '& .MuiDataGrid-toolbarContainer': {
    padding: '20px',  // Add some padding to toolbar if you use it
  },
  ".MuiDataGrid-columnHeader:focus,.MuiDataGrid-cell:focus": {
        outline: "none"
    },
  
  };
  