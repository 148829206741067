import ModuleModal from '../../components/module-modal';
import ModuleModalImage from '../../assets/moduleModal.svg';
import { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  IconButton
} from '@mui/material';
import { useGetProgramModulesList } from '../../../../../../api/programs';
import ModuleRed from "../../../../../../assets/icons/module-red.svg";
import { AttendanceContext } from '../../../../../../context/AttendanceContext';
import { truncateText } from '../../../../../../utils/formatters';
import { useParams } from 'react-router-dom';

export default function Modules({id}: any) {

  const {setModules, modules: selectedModules} = useContext(AttendanceContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [addInputs, setAddInputs] = useState([{ index: 0 }]);
  const [options, setOptions] = useState<any>([]);
  const { data: modules, isLoading } = useGetProgramModulesList(id);

  useEffect(() => {
    const programs: any = [];
    (modules as any)?.modules?.forEach((elem: any) => {
      programs.push(elem);
      if (elem.subModules) {
        elem.subModules.forEach((subModl: any) => {
          programs.push(subModl);
        });
      }
    });
    setOptions(programs);
  }, [modules]);

  // useEffect(() => {
  //   if(modules){
  //     const programs: any = [];
  //     selectedModules?.forEach((elem: any, index: number)=> {
  //       programs.push((modules as any)?.modules?.find((x: any)=> x.id === elem.id))
  //     })
  //     setAddInputs(programs);
  //   }
  // }, [selectedModules, modules]);


  const handleSave = () => {
    setModules(addInputs);
    setOpenModal(false);
  }

  return (
    <ModuleModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      text={truncateText(selectedModules?.map((elem: any)=> elem.name)?.join(', '), 15)}
      cardModel={{
        image: ModuleRed,
        title: 'Modul',
        subtitle: '',
        color: '#FBEDEB',
      }}
      title=""
      width={490}
    >
      <div>
          <span
            style={{
              fontSize: '24px',
              fontWeight: '700',
              marginBottom: '18px',
              display: 'inline-block',
            }}
          >
            Modul seçin
          </span>
          {addInputs
            .sort((a, b) => (a as any).index - (b as any).index)
            .map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                gap="8px"
                sx={{ mb: '18px' }}
              >
                <Autocomplete
                  sx={{ width: '100%' }}
                  value={item}
                  id={`grouped-demo-${index}`}
                  options={
                    options ? options : [{ label: 'test', name: 'test' }]
                  }
                  getOptionLabel={(option) =>
                    (option as any).name
                      ? `${(option as any).version} ${(option as any).name}`
                      : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Modulu seçin və yazın"
                      sx={{ borderRadius: '12px' }}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <>
                        <li key={(option as any).id} {...props}>
                          {(option as any).version} {(option as any).name}
                        </li>
                      </>
                    );
                  }}
                  onChange={(event, item) => {
                    const inputs = addInputs.filter(
                      (elem) => (elem as any).index !== index
                    );
                    setAddInputs([...inputs, { ...item, index: index }]);
                  }}
                />
                <IconButton
                  onClick={() =>
                    setAddInputs(
                      addInputs.filter((item) => item.index !== index)
                    )
                  }
                  sx={{
                    display: addInputs.length > 1 ? 'block' : 'none',
                    padding: '0',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <img src="/icons/remove-recyecle.svg" />
                </IconButton>
              </Box>
            ))}
          <Typography
            onClick={() =>
              setAddInputs([...addInputs, { index: addInputs.length }])
            }
            style={{
              color: '#299AE8',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            component="span"
          >
            + Modul əlavə et
          </Typography>
          <div style={{display: 'flex'}}>
            <Button
                variant="contained"
                className="saveBtn"
                onClick={handleSave}
                sx={{
                  background: '#854693',
                  color: '#FFF',
                  padding: '8px 24px',
                  borderRadius: '12px',
                  fontFamily: 'Visby CF Bold, sans-serif',
                  textTransform: 'none',
                  mt: '21px',
                  '&:hover': {
                    background: '#6e3d7d',
                  },
                }}
              >
                Yadda saxla
            </Button>
          </div>
      </div>
    </ModuleModal>
  )
}
