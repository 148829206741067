import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
 import Star from "../images/Path.svg"
import Circle from "../images/Ellipse3.svg";
import Profile from "../images/Ellipse2.png";


function RightPanel() {
  return (
    <>
      {" "}
      <Box
        px={6}
        py={3}
        sx={{
          backgroundImage:
            "linear-gradient(210.83deg, #FEBE10 0%, #EF4C23 17.64%, #9A2F7A 51.53%, #4169B6 78.41%, #04AD52 100%)",
          height: "100%",
          flexWrap: "nowrap",
          margin: "0",
          display: "flex",
          position: "relative",
          flexDirection: "column",
          // width: "100%",

          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            position: "absolute",
            top: "0",
            left: "548px",
            right: "0",
            opacity: "50%",
          }}
        >
          <img src={Circle} alt="circle" />
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            position: "absolute",
            bottom: "0",
            right: "620px",
            left: "0",
            opacity: "50%",

            transform: "rotate(180deg)",
          }}
        >
          <img src={Circle} alt="circle" />
        </Box>
        <Box py={5}>
          <Typography
            variant="h4"
            sx={{ fontSize: "48px", color: "#fff", letterSpacing: "2px"}}
            color="text.secondary"
          >
            Biz sizlərin sayəsində{" "}
            <Typography
              sx={{
                fontSize: "48px",
                color: "#fff",
                fontWeight: "800",
                letterSpacing: "2px",
              }}
              component="span"
            >
              böyüdük!
            </Typography>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default RightPanel;
