import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filters: {},
    classesStatusQuery: 0
};

const groupFiltersSlice = createSlice({
    name: 'groupFilters',
    initialState,
    reducers: {
        change: (state, action) => {
            state.filters = action.payload;
        },
        changeStatusTabs:(state, action)=>{
            state.filters={...state.filters, status:action.payload}
        },
        setClassesStatusQuery: (state, action) => {
            state.classesStatusQuery = action.payload;
          },
    }
})

export default groupFiltersSlice.reducer;

export const { change,changeStatusTabs,setClassesStatusQuery } = groupFiltersSlice.actions