import {
  useDelete,
    useFetch, usePost, useUpdate,
} from '../utils/api/reactQuery';
import { apiRoutes } from '../apiRoutes';
import { IClassModel, IClassCreateModel, IUpdateClassModel, IClass } from '../types';


export const useGetClassesList = () =>
  useFetch<IClassModel[]>(apiRoutes.classes.getAll);

export const useCreateClass = (newClass: IClassCreateModel ) => 
  usePost<IClassCreateModel, IClassCreateModel>(apiRoutes.classes.create, newClass);

export const useGetClassById = (id: number) => 
  useFetch<IClassModel>(apiRoutes.classes.getById(id));

export const useGetSessionClassById = (id: number, workerType: string) => 
  useFetch<any>(apiRoutes.classes.sessions(id, workerType));

export const useUpdateClass = (id: number, updatedClass: IUpdateClassModel) => 
  useUpdate<IUpdateClassModel, IUpdateClassModel>(apiRoutes.classes.update(id), updatedClass);

export const useDeleteClass = (id: number) => 
  useDelete(apiRoutes.classes.delete(id));

export const useGetClassStudents = (id: number) => useFetch<any>(apiRoutes.classes.students(id));

export const useGetClassWorkers = (id: number) => useFetch<any>(apiRoutes.classes.workers(id));
// export const useGetGenerateClassName =( programId: number,sessionId:number,startDate:any) => useFetch<IClass>(apiRoutes.classes.generateName(programId,sessionId,startDate));
