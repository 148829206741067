import React from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Grid, Paper, Typography } from '@mui/material';
import StyledDropzone from './dropzone';
import Header from './header';
import { Box } from '@mui/system';
import ActionButtons from './buttons/buttons';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export const sxField = {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      borderRadius: "14px",
    }}

function NewRequest() {
  const [formData, setFormData] = React.useState({
    startDate: '',
    endDate: '',
    status: '',
    file: null
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    console.log(formData);
    // Form verilerini gönderme işlemi burada olacak
  };

  return (
    <>


      <Box sx={{marginLeft:"248px"}}>
        <Header />
      <Paper style={{ padding:" 0px 0px 0px 0px", boxShadow:"none",width:"626px" }}>
        <Box sx={{ display: 'flex',width:"626px" }}>
          <Typography sx={{fontFamily:"Visby CF Regular",fontSize:"14px"}}>Vilayat Muslumzada <span style={{fontFamily:"Visby CF Bold"}}>U504</span></Typography>
          <Typography sx={{marginLeft:"255px",fontFamily:"Visby CF Regular ",fontSize:"14px"}}>Qalıq məzuniyyət günü: <span style={{fontFamily:"Visby CF Bold"}}>15 gün
          </span></Typography>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={12} >
          <FormControl
  fullWidth
  sx={{
    width: "626px",
    height: "52px",
    gap: "0px",
    marginTop:"16px"
  }}
>
  <InputLabel id="status-label">İcazənin növü</InputLabel>
  <Select
    labelId="status-label"
    value={formData.status}
    label="İcazənin növü"
    onChange={handleChange}
    name="status"
    sx={{
      border: "none",
      "& fieldset": {
        border: "1px solid #999898", borderRadius: "12px", 
      },
    }}
  >
    <MenuItem value="İcazə">İcazə</MenuItem>
    <MenuItem value="Məzuniyyət">Məzuniyyət</MenuItem>
    <MenuItem value="Xəstəlik">Xəstəlik</MenuItem>
  </Select>
</FormControl>
          </Grid>

          <Box 
      display="flex" 
      justifyContent="space-between" 
      alignItems="center" 
      gap={"19px"}
      sx={{height:"Hug(52px)px",marginTop:"16px"}}
       >
      <TextField 
      label="Başlama tarixi" type="date" InputLabelProps={{ shrink: true }}  sx={{
        width:"196px"
        
        , "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid #999898",borderRadius: "12px" 
          },
        },
      }} />
      <TextField  label="Müddət"  InputLabelProps={{ shrink: true }}  sx={{
        width:"196px"
        ,
         "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid #999898", borderRadius: "12px", 
          },
        },
      }}
      onChange={handleChange} />
      <TextField label="Bitmə tarixi" type="date" InputLabelProps={{ shrink: true }}  sx={{
        width:"196px"
        ,
      fill:"#182px",
         "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid #999898", borderRadius: "12px" 
          },
        },
      }}/>
      
      
    </Box>
          <Box sx={{display:"flex", gap:"8px", padding:"2px", marginTop:"9px"}}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_14071_179145)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0001 0.750092C15.1081 0.750092 19.2501 4.89109 19.2501 10.0001C19.2501 15.1081 15.1081 19.2501 10.0001 19.2501C4.89112 19.2501 0.750122 15.1081 0.750122 10.0001C0.750122 4.89109 4.89112 0.750092 10.0001 0.750092Z" fill="#D54939"/>
    <path d="M9.99524 6.20419V10.6232" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.995 13.7961H10.005" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_14071_179145">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg><Typography sx={{fontFamily:"Visby CF Regular"}}>Diqqət! İcazə tarixiniz <span style={{fontFamily:"Visby CF Bold"}}> U302</span> qrupunun dərs günlərini əhatə edir.</Typography></Box>
            <TextField
              fullWidth
              label="Başlıq"
              name="title"
              value="" 
              onChange={handleChange}
              InputLabelProps={{
                shrink: false,
              }}
              sx={{border: "1px solid #999898", borderRadius: "12px",marginTop:"16px",
                fill:"#182px",
                   "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", 
                      
                    },
                  },
                }}
            />
            <TextField
              fullWidth
              label="Qeyd yazın.."
              name="note"
              value=""
              onChange={handleChange}
              InputLabelProps={{
                shrink: false,
              }}
              sx={{border: "1px solid #999898", borderRadius: "12px",marginTop:"16px",
                height:"108px",
                fill:"#182px",
                   "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", 
                    },
                  },
                }}
            />
          <Box sx={{marginTop:"16px"}}>
          <StyledDropzone />
          </Box>
          <FormControlLabel
    control={
      <Checkbox />
    }
    sx={{fontSize:"16px"}}
    label="Onlayn iştirak"
  />
          <ActionButtons handleSubmit={handleSubmit} />
        </Grid>
      </Paper></Box>
      
    </>
    
  );
}

export default NewRequest;
