import React from "react";
import { Box, Typography } from "@mui/material";
import GroupBox from "../components/group-box";
import { sxContainer } from "../components/group-box/style";
import AddGroupModal from "../components/add-group-modal";
import { IPermissionGroupsModel, IWorkerModel } from "../../../../../types";
interface GroupListProps {
  users: IWorkerModel[];
  groups: IPermissionGroupsModel[]
  refetch: () => void;
}
function GroupList({ users, groups, refetch }: GroupListProps) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "16px",
      }}
    >
      <Box sx={{ ...sxContainer, justifyContent: "center", height: "227px" }}>
        <Box
          onClick={handleClickOpen}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "89px",
              height: "89px",
              borderRadius: "50%",
              backgroundColor: "#E9E9E9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src="/icons/add-filled.svg" alt="add-filled Icon" />
          </Box>
          <Typography
            sx={{
              fontFamily: "Visby CF Bold, sans-serif",
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              Color: "#6B696A",
            }}
          >
            Qrup əlavə et
          </Typography>
        </Box>
      </Box>
      {groups &&
        groups.map((group:IPermissionGroupsModel, index) => (
          <React.Fragment key={group.id}>
            <GroupBox users={users} refetch={refetch} group={group} isLast={index == 1 || index % 3 == 1} />
          </React.Fragment>
        ))}
      <AddGroupModal
        refetch={refetch}
        users={users}
        open={open}
        setOpen={setOpen}
      />
    </Box>
  );
}

export default GroupList;
