export const getContractTypeLabel = (contractType: any) => {
    if (contractType === 0) return 'Bitib';
    if (contractType === 'PartTime') return 'Part-time';
    if (contractType === 'FullTime') return 'Full-time';
    return 'Bilinmir';
};

export const isDateOverlap = (start1: any, end1: any, start2: any, end2: any) => {
  return start1 <= end2 && end1 >= start2;
};

export const isDayBusy = (currentDate: any, mentor: any) => {
  return mentor.sessions.some((session: any) =>
    session.getFilteredDataClasses.some((cls: any) =>
      isDateOverlap(new Date(cls.startDate), new Date(cls.endDate), currentDate, currentDate)
    )
  );
};

export const generateCalendarDates = (start: any, end: any) => {
  const dates = [];
  const currentDate = new Date(start);
  const endDate = new Date(end);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate)); // Her günün tarihini listeye ekle
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};