import React from 'react';
import { Pagination } from '@mui/material';


interface CustomPaginationProps {
  count: number; 
  page: number; 
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void; 
  
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ count, page, onPageChange}) => {
  return (
    <Pagination
      count={count}
      page={page}
      onChange={onPageChange}
      variant="text"
      shape="circular"
      color="primary"
      sx={{ marginTop:-7,justifyContent: 'center', display: 'flex',paddingRight:10}}
      
    />
  );
};

export default CustomPagination;
