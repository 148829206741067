import { useSelector } from "react-redux";
import { IPermissionModel } from "../../permissions";

export function UserHasPermission(permission: IPermissionModel) : boolean{
    const user = useSelector((state: any) => state.auth.user);
    return user.permissions.find((x: any)=> x.normalizedName == permission.permissionName)?.permissions?.find((x:any)=> x == permission.permissionLevel) ? true : false;
}

export function UserHasAnyOfPermissions(permissions: IPermissionModel[]) : boolean{
    const user = useSelector((state: any) => state.auth.user);
    let res = false;
    permissions.forEach((permission: IPermissionModel)=> {
        if(user.permissions.find((x: any)=> x.normalizedName == permission.permissionName)?.permissions?.find((x:any)=> x == permission.permissionLevel)){
            res = true;
        }
    })
    return res;
}