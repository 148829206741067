import { Table, TableContainer, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import Header from './header';
import { headerCells } from "./headerCells";
import AddTableRow from './add-module-row';
import Row from './row';
import CustomDatePicker from '../date-picker';
import CustomAutocomplete from "../../../../../../../components/custom-components/custom-autocomplete";

interface ISetProgramTableModel{
  modules: any,
  setModules: any,
  isTableAdd?: boolean
  customSX?: any,
  hasDelete?: boolean
}


export default function Index({modules, setModules, isTableAdd = false, customSX, hasDelete = false} : ISetProgramTableModel) {

  const [openModule, setOpenModule] = useState<number | null>(null);
  const [isAddRowOpen, setIsAddRowOpen] = useState<boolean>(false);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const sortData = ()=> {
    let res;
    if(+modules[0].version > +modules[1].version){
      res = modules.sort((a: any, b: any) => {
        return +a.version > +b.version ? 1 : -1;
      });
    }else if(+modules[0].version < +modules[1].version){
      res = modules.sort((a: any, b: any) => {
        return +a.version < +b.version ? 1 : -1;
      });
    }
    setModules([...res])
  }

  const handleStartAndEndDateChange = (val:any, key:any, id: any) => {
    setModules(modules.map((elem:any)=> (elem.id === id || elem.isChecked) 
      ? {...elem, [key]: val, subModules: elem.subModules?.map((subModule: any)=> ({...subModule, [key]: val}) ) } 
      : {...elem, subModules: elem.subModules?.map((subModule: any)=> subModule.isChecked 
        ? {...subModule, [key]: val}  : {...subModule} )}))
  }

  const handleTeacherAndMentorChange = (val:any, key: any, moduleId: any) => {
    setModules(modules.map((elem:any)=> (elem.id === moduleId || elem.isChecked) ? 
      {...elem, workers: elem.workers.map((k:any)=> k.role === key 
          ? k={email: val?.email, role: k.role, name: val?.name, id: val?.id, surname: val?.surname, roleId: k.roleId } 
          : k ), subModules: elem.subModules?.map((subModule: any)=> ({...subModule, workers: subModule.workers.map((k:any)=> k.role === key 
            ? k={email: val?.email, role: k.role, name: val?.name, id: val?.id, surname: val?.surname, roleId: k.roleId } 
            : k )}) ) } 
      : {...elem, subModules: elem.subModules?.map((subModule: any)=> subModule.isChecked 
        ? {...subModule, workers: subModule.workers.map((k:any)=> k.role === key 
        ? k={email: val?.email, role: k.role, name: val?.name, id: val?.id, surname: val?.surname, roleId: k.roleId } 
        : k )} : {...subModule} )}))
  }

  const handleSubModuleCheck = (subModuleId: any, moduleId: any) => {
    setModules(modules.map((module: any) => {
      if (module.id === moduleId) {
        return {
          ...module,
          subModules: module.subModules.map((subModule: any) => {
            if (subModule.id === subModuleId) {
              return {
                ...subModule,
                isChecked: !subModule.isChecked
              };
            }
            return subModule;
          })
        };
      }
      return module;
    }));
  }

  const handleSubModuleStartAndEndDateChange = (val:any, key:any, moduleId: any, subModuleId: any) => {
    setModules(modules.map((module:any)=> (module.id === moduleId || module.isChecked) ? 
      {...module, [key]: module.isChecked ? val : module[key], subModules: (module as any).subModules.map((y: any)=> (y.id === subModuleId || y.isChecked)
        ? {...y, [key]: val } 
        : {...y}) } 
      : {...module,[key]: module.isChecked ? val : module[key], subModules: (module as any).subModules.map((y: any)=> (y.id === subModuleId || y.isChecked)
        ? {...y, [key]: val } 
        : {...y})}))
  }

  const handleSubModuleTeacherAndMentorChange = (val:any, key: any, moduleId: any, subModuleId: any) => {
    setModules(modules.map((module: any) => {
      if(module.isChecked){
        module.workers = module.workers.map((worker: any)=> {
          if (worker.role === key) {
            return {
              email: val?.email,
              role: worker.role,
              name: val?.name,
              id: val?.id,
              surname: val?.surname,
              roleId: worker.roleId
            };
          }
          return worker;
        } )
      }
      if (module.id === moduleId || module.isChecked) {
        return {
          ...module,
          subModules: module.subModules.map((subModule: any) => {
            if (subModule.id === subModuleId || subModule.isChecked) {
              return {
                ...subModule,
                workers: subModule.workers.map((worker: any) => {
                  if (worker.role === key) {
                    return {
                      email: val?.email,
                      role: worker.role,
                      name: val?.name,
                      id: val?.id,
                      surname: val?.surname,
                      roleId: worker.roleId
                    };
                  }
                  return worker;
                })
              };
            }
            return subModule;
          })
        };
      }
      return module;
    }));
  }

  const handleDelete = (id: any) => {
    setModules(modules.filter((elem: any)=> elem.id !== id))
  }

  return (
        <TableContainer sx={customSX}>
          <Table 
            sx={{
              width: "100%",
              border: "none",
            }}
            aria-label="simple table">
            <Header
              hasDelete={hasDelete}
              hasCheckBox={true} 
              areAllSelected={!modules.some((x:any)=> x.isChecked === false) && modules.length > 0} 
              indeterminate={modules.some((x:any)=> x.isChecked === false) && modules.some((x:any)=> x.isChecked === true)} 
              onCheckBoxChecked={()=> {
                setIsAllChecked(!isAllChecked)
                setModules(modules.map( (x :any)=> ({...x, isChecked: !isAllChecked, subModules: x.subModules?.map((elem: any) => ({...elem, isChecked: !isAllChecked}))})))
              }} 
              cells={headerCells}
              handleSort={sortData} />
            <TableBody>
              {
                isTableAdd ? (
                  isAddRowOpen ? (
                    <AddTableRow newModules={modules} setNewModules={setModules} setIsAddRowOpen={setIsAddRowOpen}/>
                  ) : (
                    <TableRow sx={{backgroundColor: '#f7f7f7', cursor: 'pointer'}}>
                      <TableCell colSpan={8} onClick={()=> setIsAddRowOpen(true)}>
                        <Typography sx={{color: '#BABABA'}}>
                          + Yeni modul əlavə et
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                  ) : null
              }
              {
                modules.map((module: any, index: any)=> 
                (
                  <Fragment key={index}>
                    <Row
                      
                      isDeletable={module.isNewModel || module.isExtraModule ? true : false}
                      onDelete={()=> handleDelete(module.id)}
                      hasCheckBox={true} 
                      indeterminate={module.subModules?.some((subModule: any)=> subModule.isChecked) && module.subModules?.some((subModule: any)=> !subModule.isChecked)}
                      isChecked={module.isChecked}
                      onCheckBoxChecked={()=> setModules(modules.map((elem:any)=> elem.id === module.id ? {...module, isChecked: !module.isChecked, subModules: module.subModules?.map((elem: any) => ({...elem, isChecked: !module.isChecked})) } : {...elem}))}
                      hasSubModules={module.subModules?.length > 0} 
                      onArrowClick={()=> {
                        if(openModule === module.id){
                          setOpenModule(null)
                        }else{
                          setOpenModule(module.id)
                        }
                      }}
                      isArrowClicked={openModule === module.id}
                      cells={
                        headerCells.map((key, index)=> {
                          if(key.key === 'subModules'){
                            return {
                              key: key.key,
                              text: module[key?.key]?.length,
                            }
                          } else if(key.key === 'startDate' || key.key === 'endDate'){
                            return {
                              key: key.key,
                              text: module[key?.key],
                              isCustomelement: true,
                              customElement: <CustomDatePicker 
                                date={module[key?.key]} 
                                isSubmodule={false}
                                id={module.id}
                                handleDateChange={(e)=> handleStartAndEndDateChange(e, key.key, module.id)}
                                type='date'/>
                            }
                          }
                          else if(key.key === 'name'){
                            return {
                              key: key.key,
                              text: module[key?.key],
                              isFirst: true,
                              version: module.version
                            }
                          } else if(key.key === 'mentor' || key.key === 'muellim'){
                            return {
                              key: key.key,
                              isCustomelement: true,
                              customElement: <CustomAutocomplete 
                                              value={module.workers?.find((x: any)=> x.role === key.key)} 
                                              label='' url={`Workers?role=${key.key}` }
                                              propName='name' 
                                              onChange={(elem:any)=> handleTeacherAndMentorChange(elem, key.key, module.id) }/>
                            }
                          }
                          return{
                            key: key.key,
                            text: module[key?.key],
                          }
                        }
                        )
                      }/>
                      {
                        openModule === module.id ? (
                          module.subModules?.map((elem : any, k : any) => (
                            <Row 
                            key={k}
                            hasCheckBox={true}
                            isChecked={elem.isChecked}
                            onCheckBoxChecked={()=> handleSubModuleCheck(elem.id, module.id)}
                            cells={
                              headerCells.map((key, index)=> {
                                if(key.key === 'name'){
                                  return {
                                    key: key.key,
                                    isCustomelement: true,
                                    customElement: <span><span style={{fontWeight: 'bold', fontFamily: '"Roboto","Helvetica","Arial",sans-serif'}}>{elem.version}</span> {elem[key?.key]}</span>,
                                    isFirst: true,
                                    version: elem.version
                                  }
                                }else if(key.key === 'startDate' || key.key === 'endDate'){
                                  return {
                                    key: key.key,
                                    isCustomelement: true,
                                    customElement: <CustomDatePicker 
                                      date={elem[key?.key]} 
                                      isSubmodule={true} 
                                      id={module.id}
                                      handleDateChange={(e)=> handleSubModuleStartAndEndDateChange(e, key.key, module.id, elem.id)}
                                      type='date'/>
                                  }
                                }else if (key.key === 'subModules'){
                                  return {
                                    key: key.key,
                                    text: '-',
                                  }
                                } else if(key.key === 'mentor' || key.key === 'muellim'){
                                  return {
                                    key: key.key,
                                    isCustomelement: true,
                                    customElement: <CustomAutocomplete 
                                            value={elem.workers?.find((x: any)=> x.role === key.key)} 
                                            label='' url={`Workers?role=${key.key}` }
                                            propName='name' 
                                            onChange={(e: any)=> handleSubModuleTeacherAndMentorChange(e, key.key, module.id, elem.id)}/>
                                  }
                                }
                                return{
                                  key: key.key,
                                  text: elem[key?.key],
                                }
                              }
                              )
                            }/>
                          ))
                        ) : null
                      }
                  </Fragment>
                  
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
    //   )
    // }
  )
}
