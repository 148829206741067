import { Box } from '@mui/system';
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Autocomplete
} from '@mui/material';
import { useState } from 'react';
import {
  useGetActionReasons,
  usePostAction,
} from '../../../../../../../../../api/requests';
import Footer from '../footer';
import Button from '../../../../../../../../../components/core-components/inputs/button';
import { useQueryClient } from 'react-query';
import { apiRoutes } from '../../../../../../../../../apiRoutes';
import { useGetAllWorkers } from '../../../../../../../../../api/workers';

export default function Index({ createdDate, requestId, setStage }: any) {
  const postActionMutation = usePostAction(requestId);
  const queryClient = useQueryClient();
  const [selectedRejectValue, setSelectedRejectValue] = useState<string>('');
  const [selectedTeacher, setSelectedTeacher] = useState<any>(null);
  const [textValue, setTextValue] = useState<string>('');
  const {data: teachers, refetch} = useGetAllWorkers(false);

  const {
    data: reasons,
    error,
    refetch: actionsRefetch,
  } = useGetActionReasons('approve');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedRejectValue(value);
  };

  const handleApprove = async () => {
    try {
      const res = await postActionMutation.mutateAsync({
        reasonId: selectedRejectValue,
        reasonNote: textValue,
        substituteTeacherId: selectedTeacher?.id || 0,
        status: 'Approve',
      });
      queryClient.invalidateQueries(apiRoutes.leaverequests.myRequests);
      queryClient.invalidateQueries(apiRoutes.leaverequests.getAllRequests);
      queryClient.invalidateQueries(
        apiRoutes.leaverequests.getRequest(requestId)
      );
      setStage(0);
    } catch (err) {}
  };

  const isDisabled = (): boolean => {
    if (selectedRejectValue) {
      if (
        (reasons?.find((x: any) => x.id == selectedRejectValue)?.reasonAction ===
          'Note' || reasons?.find((x: any) => x.id == selectedRejectValue)?.reasonAction ===
          'TargetPermissionGroup') &&
        !textValue
      )  {
        return true;
      } else if(
        reasons?.find((x: any) => x.id == selectedRejectValue)?.reasonAction ===
          'SubstituteTeacher' &&
        !selectedTeacher
      ){
        return true;
      }
      return false;
    }
    return true;
  };


  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          padding: '12px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '4px',
          alignSelf: 'stretch',
          borderRadius: '16px',
          backgroundColor: '#F7F7F7',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Visby CF Bold',
            color: '#4E4C4D',
          }}
        >
          Dərsin statusu:
        </Typography>
        <RadioGroup value={selectedRejectValue} onChange={handleRadioChange}>
          {Array.isArray(reasons)
            ? reasons?.map((reason: { id: number; name: string }) => (
                <FormControlLabel
                  key={reason.id}
                  value={reason.id.toString()}
                  control={<Radio />}
                  label={reason.name}
                />
              ))
            : null}
        </RadioGroup>
      </Box>
      
      {(reasons?.find((x: any) => x.id == selectedRejectValue)?.reasonAction ===
      'Note' || reasons?.find((x: any) => x.id == selectedRejectValue)?.reasonAction ===
      'TargetPermissionGroup') ? (
        <TextField
          placeholder="Digər imtina səbəbini daxil edin..."
          variant="outlined"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value as any)}
          multiline
          maxRows={2}
          sx={{
            backgroundColor: '#F7F7F7',
            width: '100%',
            marginTop: '8px',
            display: 'flex',
            border: '0px',
            height: '113px',
            borderRadius: '16px',
            padding: '16px 24px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          }}
        />
      ) : null}
      {reasons?.find((x: any) => x.id == selectedRejectValue)?.reasonAction ===
      'SubstituteTeacher' ? (
        <Box
          sx={{
            backgroundColor: '#F7F7F7',
            width: '100%',
            marginTop: '8px',
            border: '0px',
            borderRadius: '16px',
            padding: '16px 24px',

            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Visby CF Bold',
              color: '#4E4C4D',
            }}
          >
            Əvəzedici müəllimi seçin
          </Typography>
          <Autocomplete
            loading
            disablePortal
            id="combo-box-demo"
            options={teachers || []}
            onChange={(e, v) => setSelectedTeacher((v as any))}
            onOpen={()=> refetch()}
            getOptionLabel={(option)=> `${(option as any)?.name} ${(option as any)?.surname}`}
            renderOption={(props, option) => {
              return (
                <li {...props} key={(option as any).id}>
                  {(option as any)?.name} {(option as any)?.surname}
                </li>
              );
            }}
            fullWidth
            sx={{backgroundColor: 'white', borderRadius: '16px', marginTop: '16px'}}
            renderInput={(params) => <TextField {...params} placeholder="Axtar..."
            sx={{
              fontSize: '14px',
              backgroundColor: 'white',
              borderRadius: '16px'
            }} />}
          />
        </Box>
      ) : null}
      <Footer createdDate={createdDate}>
        <Button
          variant="text"
          type="submit"
          onClick={() => handleApprove()}
          disabled={isDisabled()}
          sx={{
            backgroundColor: '#4BA458',
            padding: '10px 24px 10px 24px',
            gap: '8px',
            border: 'none',
            borderRadius: '12px',
            opacity: '0px',
            boxShadow: 'none',
            fontFamily: 'Visby CF Bold, sans-serif',
            textTransform: 'none',
            width: '100%',
            ':disabled': {
              backgroundColor: '#E9E9E9',
              opacity: 0.9,
            },
            ':hover': {
              backgroundColor: '#4BA458',
              opacity: 0.9,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Visby CF Bold, sans-serif',
              color: 'white',
              ':disabled': {
                color: '#6B696A',
              },
            }}
            
          >
            Təsdiq et
          </Typography>
        </Button>
      </Footer>
    </Box>
  );
}
