import React from 'react';

export default function GroupDetails({group}: any) {
  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 style={{fontWeight: 'bold', marginRight: '10px', fontFamily: 'Visby CF Bold'}}>Ad Soyad:</h4>
        <p>{group?.fullName}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 style={{fontWeight: 'bold', marginRight: '10px', fontFamily: 'Visby CF Bold'}}>Email:</h4>
        <p>{group?.email}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 style={{fontWeight: 'bold', marginRight: '10px', fontFamily: 'Visby CF Bold'}}>Proqram:</h4>
        <p>{group?.programName}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 style={{fontWeight: 'bold', marginRight: '10px', fontFamily: 'Visby CF Bold'}}>Qrup:</h4>
        <p>{group?.group}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 style={{fontWeight: 'bold', marginRight: '10px', fontFamily: 'Visby CF Bold'}}>Otaq:</h4>
        <p>{group?.roomName}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 style={{fontWeight: 'bold', marginRight: '10px', fontFamily: 'Visby CF Bold'}}>Başlama tarixi:</h4>
        <p>{group?.startDate}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 style={{fontWeight: 'bold', marginRight: '10px', fontFamily: 'Visby CF Bold'}}>Bitmə tarixi:</h4>
        <p>{group?.endDate}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 style={{fontWeight: 'bold', marginRight: '10px', fontFamily: 'Visby CF Bold'}}>Modul:</h4>
        <p>{group?.moduleName}</p>
      </div>
      <ul>
        {
          group?.subModules?.map((elem: any)=> (
            <li>
              {`${elem.name} - ${elem.hours}`}
            </li>
          ))
        }
      </ul>
    </div>
  )
}
