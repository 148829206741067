import { Box } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import styles from "./../card/card.module.css";

export interface Frame {
    status: any;
    type: any;
    start_date: any;
    end_date: any;
    file: any;
}

export default function Frame(props: Frame) {
    const {
        status, type, start_date, end_date, file
    } = props;

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const year = date.getFullYear();

        const monthsInAzerbaijani = [
            'Yan', 'Fev', 'Mar', 'Apr', 'May', 'İyu',
            'İyu', 'Avq', 'Sen', 'Okt', 'Noy', 'Dek'
        ];

        const month = monthsInAzerbaijani[date.getMonth()];

        return `${day} ${month} ${year}`;
    };

    const calculateDateRange = (start: string, end: string) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        return Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert time difference from milliseconds to days
    };

    return (
        <>
            <Box sx={{ marginRight: file === true ? "110px" : "140px" }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch"
                }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M3.3335 13.3333V12.6667C3.3335 10.0893 5.42284 8 8.00016 8V8C10.5775 8 12.6668 10.0893 12.6668 12.6667V13.3333"
                                stroke="#999898"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.00016 8.00008C9.47292 8.00008 10.6668 6.80617 10.6668 5.33341C10.6668 3.86065 9.47292 2.66675 8.00016 2.66675C6.5274 2.66675 5.3335 3.86065 5.3335 5.33341C5.3335 6.80617 6.5274 8.00008 8.00016 8.00008Z"
                                stroke="#999898"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <Typography sx={{ marginLeft: "10px", color: "var(--Natural-natural-200, #999898)" }}>
                            Status:
                        </Typography>
                        <Box sx={{ marginLeft: "70px" }} className={`${styles.status} ${
                            status === 'apply'
                                ? styles.statusapply
                                : status === 'wait'
                                    ? styles.statuswait
                                    : status === 'reject'
                                        ? styles.statusreject
                                        : styles.statusunknown
                        }`}>
                            {status === 'apply'
                                ? 'Təsdiqlənib'
                                : status === 'wait'
                                    ? 'Gözləmədə'
                                    : status === 'reject'
                                        ? 'Qəbul edilməyib'
                                        : 'Naməlum status'}
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" 
                                  stroke="#999898" 
                                  strokeWidth="1.5" 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" />
                            <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" 
                                  stroke="#999898" 
                                  strokeWidth="1.5" 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" />
                            <path d="M8.6665 4H10.6665C11.0201 4 11.3593 4.14048 11.6093 4.39052C11.8594 4.64057 11.9998 4.97971 11.9998 5.33333V10" 
                                  stroke="#999898" 
                                  strokeWidth="1.5" 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" />
                            <path d="M4 6V14" stroke="#999898" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <Typography sx={{ marginLeft: "10px", color: "var(--Natural-natural-200, #999898)" }}>
                            İcazə növü:
                        </Typography>
                        <Box sx={{
                            height: "Hug(24px)px",
                            padding: "4px 8px",
                            width: "auto",
                            marginLeft: "35px",
                            background: "#EAF6FF",
                            borderRadius: "8px",
                        }}>
                            <Typography sx={{
                                fontSize: '12px',
                                color: '#2DA9FF',
                                fontFamily: 'Visby CF Bold'
                            }}>
                                {type.toLowerCase()}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.75 0C5.16421 0 5.5 0.335786 5.5 0.75V2H10.5V0.75C10.5 0.335786 10.8358 0 11.25 0C11.6642 0 12 0.335786 12 0.75V2H13.25C14.2165 2 15 2.7835 15 3.75V14.25C15 15.2165 14.2165 16 13.25 16H2.75C1.7835 16 1 15.2165 1 14.25V3.75C1 2.7835 1.7835 2 2.75 2H4V0.75C4 0.335786 4.33579 0 4.75 0ZM13.25 3.5C13.3881 3.5 13.5 3.61193 13.5 3.75V6H2.5V3.75C2.5 3.61193 2.61193 3.5 2.75 3.5H13.25ZM2.5 7.5V14.25C2.5 14.3881 2.61193 14.5 2.75 14.5H13.25C13.3881 14.5 13.5 14.3881 13.5 14.25V7.5H2.5Z" fill="#999898" />
                        </svg>
                        <Typography sx={{ marginLeft: "10px", color: "var(--Natural-natural-200, #999898)" }}>
                            İcazə tarixi:
                        </Typography>
                        <Box sx={{ marginLeft: "40px" }}>
                            <Typography sx={{ fontSize: "14px", fontFamily: "Visby CF Bold" }}>
                                {`${formatDate(start_date)} - ${formatDate(end_date)} ${calculateDateRange(start_date, end_date)} gün`}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M8.632 4.87189L4.94667 8.55656C4.62063 8.88268 4.4375 9.32497 4.43756 9.78613C4.43762 10.2473 4.62088 10.6895 4.947 11.0156C5.27313 11.3416 5.71542 11.5247 6.17657 11.5247C6.63772 11.5246 7.07996 11.3414 7.406 11.0152L11.394 7.02722C11.6776 6.74543 11.9026 6.41035 12.0562 6.04125C12.2097 5.67215 12.2888 5.27633 12.2888 4.87656C12.2888 4.47679 12.2097 4.08096 12.0562 3.71187C11.9026 3.34277 11.6776 3.00768 11.394 2.72589C10.8205 2.16963 10.0507 1.86216 9.25176 1.87027C8.45285 1.87839 7.68942 2.20143 7.12734 2.76922L3.10467 6.71456C2.31744 7.53478 1.88307 8.63089 1.89476 9.76771C1.90646 10.9045 2.36328 11.9915 3.16722 12.7953C3.97116 13.5992 5.05816 14.0559 6.19498 14.0675C7.3318 14.079 8.42787 13.6445 9.248 12.8572L14.166 7.98256" 
                                  stroke="#999898" 
                                  strokeWidth="1.5" 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" />
                        </svg>
                        <Typography sx={{ color: "var(--Natural-natural-200, #999898)" }}>
                            Sənəd:
                        </Typography>
                        {file === true ? (
                            <Box sx={{ marginLeft: "75px", display:"flex",alignItems:"center", gap:"12px" }}>
                                <Box style={{
                                    width: "178px",
                                    height: "60px",
                                    borderRadius: "4px",
                                    border: "1px solid var(--Natural-natural-25, #F3F3F3)",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    justifyItems: "center",
                                    display:"flex",
                                    gap:"8px"
                                }}> <Box><img 
                                src="/modal/image/image.png" // Resminizin yolu
                                                  /></Box>
                                    <Box><Typography sx={{color:"#4E4C4D", fontSize:"12px"}}>for_academy.pdf</Typography>
                                        <Typography>
                                            <span color="#6B696A" style={{fontSize:"10px", color:"#6B696A"}}>2MB</span>
                                            <span style={{fontSize:"10px", color:"#6B696A"}}> - </span>
                                            <span style={{fontFamily:"Visby CF Bold", fontSize:"10px", color:"#854693", textDecoration:"underline"}}>Yüklə</span>
                                        </Typography>
                                    </Box>
                                    
                                </Box>
                                
                                <Box sx={{height:"56px",width:"56px",justifyContent:"center", alignItems:"center", padding:"12px", gap:"8px",display:"flex", borderRadius:"4px"
                                    , border:" 1px solid var(--Primary-primary-50, #F3EDF4)", backgroundColor:"#F8F6F9"
                                }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 5V19" stroke="#854693" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5 12H19" stroke="#854693" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg></Box>
                            </Box>
                        ) : (
                            <Box sx={{height:"56px",width:"56px",justifyContent:"center", alignItems:"center", padding:"12px", gap:"8px",display:"flex", borderRadius:"4px"
                                , border:" 1px solid var(--Primary-primary-50, #F3EDF4)", backgroundColor:"#F8F6F9", marginLeft:"75px"
                            }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 5V19" stroke="#854693" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 12H19" stroke="#854693" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg></Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
}
