import React, { useState } from 'react'
import Header from '../../../../components/core-components/header';
import { APP_PREFIX_PATH } from '../../../../configs/Appconfig';
import { yupResolver } from "@hookform/resolvers/yup"; 
import { useForm, SubmitHandler} from "react-hook-form";
import { IEducationRequestModel } from '../types';
import { educationRequestSchema } from '../validations';
import { Grid } from "@mui/material";
import DatePicker from '../../../../components/core-components/datePicker';
import Button from "../../../../components/core-components/inputs/button";
import CustomAutocomplete from "../../../../components/custom-components/custom-autocomplete";
import { sxField } from '../../MrpManagement/mrp/components/modal/style';
import { apiRoutes } from '../../../../apiRoutes';
import { ApiInstance } from '../../../../utils/api/api';
import Progress from '../../../../components/custom-components/progress';
import DataGrid from "../../../../components/custom-components/data-grid";
import { GridColDef } from '@mui/x-data-grid';
import { getContractTypeLabel } from '../utils';

const columns: GridColDef[] = [
  { field: 'name', 
    headerName: 'Ad Soyad',
    flex: 1,
    valueGetter: ({ row }) => row.name + " " + row.surname
  },
  {
    field: 'eMail',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'program',
    headerName: 'Proqram',
    flex: 1,
    valueGetter: ({ row }) => row.relevantClasses ? row.relevantClasses[0]?.programName : ''
  },
  {
    field: 'contractType',
    headerName: 'Contract type',
    flex: 1,
    valueGetter: ({ row }) => getContractTypeLabel(row.contractType)
  },
  {
    field: 'roles',
    headerName: 'Vəzifə',
    flex: 1,
    valueGetter: ({ row }) => row.roles ? row.roles.join(', ') : '-'
  },
];

export default function Index() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [pageSize, setPageSize] = useState<number>(10);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IEducationRequestModel>({
    resolver: yupResolver(educationRequestSchema),
  });

  const onSubmit: SubmitHandler<IEducationRequestModel> = async (data)=> {
    setIsLoading(true);
    try{
      const response = await ApiInstance.get(apiRoutes.workers.filteredDatas );
      const filteredTeachers = response.data.filter((teacher: any) =>
        teacher.sessions.some((session: any) =>
          session.getFilteredDataClasses.some((cls: any) => cls.programName === data.programName.name)
        )
      );

      const updatedTeachers = filteredTeachers.map((teacher: any) => {
        const relevantSessions = teacher.sessions.filter((session: any) =>
          session.getFilteredDataClasses.some((cls: any) => cls.programName === data.programName.name)
        );

        const allClasses = relevantSessions?.flatMap((session: any) => session.getFilteredDataClasses);

        return { ...teacher, relevantClasses: allClasses };
      });

      setData(updatedTeachers);
    }catch(err){
      console.error(err);
    }finally{
      setIsLoading(false)
    }
  }

  return (
    <>
      <Header links={[{name: 'Home', url: APP_PREFIX_PATH}]} current='Tədris Utilizasiyası'/>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4} sx={{mb:3}}>
          <Grid item xs={12}>
            <CustomAutocomplete
              control={control}
              {...register("programName")}
              label="Proqramı seçin"
              url="programs"
              propName='name'
              selectedProp="programName"
              noOptionText='Proqram yoxdu'
              sx={{ "& .MuiOutlinedInput-root": {
                borderRadius: "0",
                padding: "5px"
              }, ...sxField}}
              errors={errors.programName?.message !== undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker error={errors.startDate?.message !== undefined} control={control} {...register("startDate")} label="Tarixdən" name="startDate"/>
            <span style={{fontSize: '14px', color: 'red'}}>{errors.startDate?.message}</span>
          </Grid>
          <Grid item xs={6}>
            <DatePicker error={errors.endDate?.message !== undefined} control={control} {...register("endDate")} label="Tarixinədək" name="endDate"/>
            <span style={{fontSize: '14px', color: 'red'}}>{errors.endDate?.message}</span>
          </Grid>
        </Grid>
        <Button type="submit" >Axtar</Button>
      </form>
      {
        isLoading ? (
          <Progress/>
        ) :
        data.length > 0 ? (
          <div style={{marginTop: '24px'}}>
            <DataGrid
              data={data}
              columnsData={columns}
              rowsPerPageOptions={[10]}
              pageSizeData={pageSize}
              setPageSize={setPageSize}
              autoHeight
            />
          </div>
        ) :null
      }
    </>
  )
}
