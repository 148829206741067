import { useState } from "react";
import { Menu, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GridFilterOperator, useGridApiContext } from "@mui/x-data-grid";

export function GroupNameSearch({columnField}: any) {
    const [anchorEl, setAnchorEl] = useState(null);
    const apiRef = useGridApiContext();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const applyCustomFilter = (filterValue: any) => {
        apiRef.current.setFilterModel({
          items: [
            {
              columnField,
              operatorValue: 'contains',
              value: filterValue,
            },
          ],
        });
        apiRef.current.setState({...apiRef.current.state, filters: {columnField, value: filterValue}})
    };

  return (
    <div style={{width: '100%', backgroundColor: 'white'}}>
      <div style={{textTransform: 'capitalize', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        {columnField}
        <IconButton onClick={handleClick}>
            <SearchIcon />
        </IconButton>
      </div>
      <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            <TextField
                onChange={(e: any)=> applyCustomFilter(e.target.value)}
                value={(apiRef.current.state as any).filters?.className ? (apiRef.current.state as any).filters.value : null}
                margin="none"
                placeholder='...'
                inputProps={{
                    style: {
                        padding: "calc(0.5vw + 5px)",
                        fontSize: "calc(0.5vw + 5px)",
                        // border: "1px solid red"
                    },
                }}
            />
      </Menu>
    </div>
  )
}
