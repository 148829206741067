import React from "react";
import { TableRow, TableCell } from "@mui/material";

// Array of month names
const monthNames = ["Yan.", "Fev.", "Mar.", "Apr.", "May.", "İyn.", "İyl.", "Avq.", "Sen.", "Okt.", "Noy.", "Dek."];

const HeaderRow: React.FC = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // 0-based index for months

  // Adjust the monthNames array to start with the current month
  const adjustedMonthNames = [
    ...monthNames.slice(currentMonth),  // Months from the current month to December
    ...monthNames.slice(0, currentMonth) // Months from January to the month before the current month
  ];

  return (
    <TableRow>
      {/* Year cell spanning two columns */}
      <TableCell
        colSpan={2}
        sx={{
          width: "220px",
          borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
        }}
        align="center"
      >
        {currentYear}
      </TableCell>

      {/* Generate month cells dynamically based on the adjusted months */}
      {adjustedMonthNames.map((month, index) => (
        <TableCell
          key={index}
          sx={{
            width: "100px",
            borderRight: "1px solid var(--Natural-natural-50, #E9E9E9)",
          }}
          align="center"
        >
          {month}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default HeaderRow;
