import Button from "./components/core-components/inputs/button";
import { useNavigate } from "react-router-dom";
import Illustration from './assets/icons/illustration.png'

const wrapperStyle = {
  width: '100%',
  height: '70vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}



export default function ErrorPage() {
  const navigate = useNavigate();
  return (
    <div style={wrapperStyle}>
      <div style={{textAlign: 'center'}}>
        <img style={{width: 'Hug (381.51px)px', height: 'Hug (200px)px', objectFit: 'cover',gap:'10px',opacity: '0px'}} src={Illustration} alt="" />
        <h2 style={{fontSize: '24px', textAlign: 'center', fontFamily: 'VisbyBold', marginBottom: '5px'}}>Xəta baş verdi!</h2>
        <p style={{fontSize: '14px'}}> Gözlənilməz bir xəta baş verdi. Ətraflı məlumat üçün dəstək komandası ilə əlaqə saxlayın!</p>
        {/* <Button color="secondary" onClick={()=> navigate('/')} style={{marginRight: '10px'}}>Ana səhifə</Button> */}
        <Button onClick={()=> navigate(0)}>Yenidən yoxla</Button>

      </div>
    </div>
  )
}