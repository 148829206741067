import { Fragment, Key, useState } from 'react';
import { Grid, Box,Typography,Button,Drawer } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Card from './components/card/card';
import Header2 from './components/header/header2';
import { datagridSx } from './components/datagrid/datagrid';
import styles from './components/card/card.module.css';
import CustomPagination from './components/pagination/CustomPagination';
import PageTitle from '../../../../../components/custom-components/page-title';
import Breadcrumbs from '../../../../../components/custom-components/breadcrumbs';
import BreadcrumbsLink from '../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink';
import BreadcrumbsTypography from '../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography';
import PageHeader from '../../../../../components/custom-components/page-header';
import { APP_PREFIX_PATH } from '../../../../../configs/Appconfig';
import Modal from './components/modal/Modal';
import Dialog from "@mui/material/Dialog";
import Popup from './components/grouppopup';



export interface Request {
  id: number | null;
  status: string | null;
  startdate: string | null;
  enddate: string | null;
  title : string | null;
  description : string | null;
  file : boolean | null;
  group: string | null;
  teacher: string | null;
  type: string | null;
  percentage : number | null;

}

export default function Myrequests() {
  const [pageSize, setPageSize] = useState<number>(3);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = useState(1); 
  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<any>(null); // Seçilen satırın verisi
  const [openPopUp,setOpenPopUp] = useState<boolean>(false);
  const [request, setRequest] = useState<Request>();


  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRequest(null);
    
  };

  // Satıra tıklama olayı
  const handleRowClick = (params: any) => {
    if (params.field === ('status') || params.field === ('symbol')) {
    setRequest(params.row); 
    setOpenModal(true); // Open modal if the status column is clicked
  }
  else if (params.field === ('teacher') ) 
  {
    setOpenPopUp(true);
  }
  };




  const handleOpenModal = (open: boolean,request: any) => {
    setRequest(request); 
    setOpenModal(open); // Modal'ı aç
  };
  
  const requests = [
    {
      "id": "1",
      "title": "Tətil",
      "status": "apply",
      "startdate": "2024-03-01",
      "enddate": "2024-03-10",
      "daterange": 9,
      "teacher": "Farid Bayramov",
      "group": "u101",
      "description": "Tətil",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "2",
      "title": "Təlim",
      "status": "wait",
      "startdate": "2024-04-15",
      "enddate": "2024-04-22",
      "daterange": 7,
      "teacher": "Farid Bayramov",
      "group": "u102",
      "description": "Təlim",
      "file": false,
      "type": "normal",
      "percentage": 0
      
    },
    {
      "id": "3",
      "title": "Xəstəlik",
      "status": "reject",
      "startdate": "2024-05-05",
      "enddate": "2024-05-12",
      "daterange": 7,
      "teacher": "Farid Bayramov",
      "group": "u103",
      "description": "Xəstəliklə bağlı",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "4",
      "title": "Konfrans",
      "status": "apply",
      "startdate": "2024-06-20",
      "enddate": "2024-06-23",
      "daterange": 3,
      "teacher": "Farid Bayramov",
      "group": "u101",
      "description": "Konfrans",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "5",
      "title": "Yaradıcılıq",
      "status": "wait",
      "startdate": "2024-07-10",
      "enddate": "2024-07-15",
      "daterange": 5,
      "teacher": "Farid Bayramov",
      "group": "u102",
      "description": "Yeni layihə",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "6",
      "title": "Müayinə",
      "status": "apply",
      "startdate": "2024-08-01",
      "enddate": "2024-08-07",
      "daterange": 6,
      "teacher": "Farid Bayramov",
      "group": "u103",
      "description": "Sağlıq müayinəsi",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "7",
      "title": "Gəzinti",
      "status": "apply",
      "startdate": "2024-09-05",
      "enddate": "2024-09-10",
      "daterange": 5,
      "teacher": "Farid Bayramov",
      "group": "u101",
      "description": "Gəzinti",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "8",
      "title": "Xəstəlik sorğusu",
      "status": "wait",
      "startdate": "2024-10-12",
      "enddate": "2024-10-19",
      "daterange": 7,
      "teacher": "Farid Bayramov",
      "group": "u102",
      "description": "Sistem təlimi",
      "file": true,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "9",
      "title": "Səyahət",
      "status": "reject",
      "startdate": "2024-11-01",
      "enddate": "2024-11-07",
      "daterange": 6,
      "teacher": "Farid Bayramov",
      "group": "u103",
      "description": "Şəxsi səyahət",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "10",
      "title": "Tədqiqat",
      "status": "apply",
      "startdate": "2024-09-10",
      "enddate": "2024-09-11",
      "daterange": 4,
      "teacher": "Farid Bayramov",
      "group": "u101",
      "description": "Elmi tədqiqat",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "11",
      "title": "Ailə Görüşü",
      "status": "wait",
      "startdate": "2023-12-15",
      "enddate": "2023-12-20",
      "daterange": 5,
      "teacher": "Farid Bayramov",
      "group": "u102",
      "description": "Görüş",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "12",
      "title": "Təhsil",
      "status": "wait",
      "startdate": "2023-10-10",
      "enddate": "2023-11-15",
      "daterange": 5,
      "teacher": "Farid Bayramov",
      "group": "u103",
      "description": "məzuniyyət",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "13",
      "title": "Təlim / seminar",
      "status": "apply",
      "startdate": "2023-09-05",
      "enddate": "2025-10-12",
      "daterange": 7,
      "teacher": "Farid Bayramov",
      "group": "u103",
      "description": "Xaricdə seminar",
      "file": false,
      "type": "normal",
      "percentage": 0
    },
    {
      "id": "14",
      "title": "Career Plus",
      "status": "apply",
      "startdate": "2024-09-05",
      "enddate": "2025-12-12",
      "daterange": 7,
      "teacher": "Farid Bayramov",
      "group": "u103",
      "description": "Xaricdə seminar",
      "file": false,
      "type": "career",
      "percentage": 95
    },
    {
      "id": "15",
      "title": "Technest",
      "status": "apply",
      "startdate": "2024-09-05",
      "enddate": "2024-10-12",
      "daterange": 7,
      "teacher": "Farid Bayramov",
      "group": "u103",
      "description": "Xaricdə seminara",
      "file": false,
      "type": "career",
      "percentage": 70
    }
  ];

  const today = new Date();

  // tarix filteri
  const filteredSorgularbyDate = requests.filter((request) => {
    const start = new Date(request.startdate);
    const end = new Date(request.enddate);

    // cari icazelerde gosterileceylerin case i (formal)
    return (request.status === 'apply' || request.status === 'wait' ||
       (request.status === 'reject' && Math.ceil((start.getTime() - today.getTime())/ (1000 * 3600 * 24)) <3 )) 
    && today!<end;

  });



  const shouldShowPageTitle = filteredSorgularbyDate.length > 0;
  console.log(shouldShowPageTitle)

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
  
    const monthsInAzerbaijani = [
      'Yan', 'Fev', 'Mar', 'Apr', 'May', 'İyu',
      'İyu', 'Avq', 'Sen', 'Okt', 'Noy', 'Dek'
    ];
  
    const month = monthsInAzerbaijani[date.getMonth()];
  
    return `${day} ${month}`;
  };

  //tarix formati deyismek
  const formattoDDMMYY= (dateString: string) => {
    const date = new Date(dateString); 
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2); 
    
    return `${day}.${month}.${year}`; 
  };
  
  // gun araligin hesablamag
  const calculateDateRange = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    return Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert time difference from milliseconds to days
  };

  // Filtering based on search
  const filteredSorgular = requests.filter((request) =>
    request.description.toLowerCase().includes(searchQuery.toLowerCase())

   
    
  );

  
  const rowHeight = 84;
  const rowCount = 3.7; 
  const headerHeight = 44;
  const footerHeight = 56; 
  const boxHeight = rowHeight * rowCount + headerHeight + footerHeight;
  const totalRows = filteredSorgular.length;
  const totalPages = Math.ceil(totalRows / pageSize);
 
  const columns = [
    
  
    { 
      field: 'teacher',
      headerName:"Müəllim",
      flex:1.2,
      height: "44px",
      

      renderCell: (params:any) => (
        <Box sx={{display:"flex"}}>
          <svg style={{marginLeft:"10px",marginTop:"40px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_14071_179145)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0001 0.750092C15.1081 0.750092 19.2501 4.89109 19.2501 10.0001C19.2501 15.1081 15.1081 19.2501 10.0001 19.2501C4.89112 19.2501 0.750122 15.1081 0.750122 10.0001C0.750122 4.89109 4.89112 0.750092 10.0001 0.750092Z" fill="#D54939"/>
    <path d="M9.99524 6.20419V10.6232" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.995 13.7961H10.005" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_14071_179145">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
        <Box sx={{padding: '26px 50px 20px 50px',marginLeft:"-30px"}}>
    
          <Typography sx={{ fontSize: '16px', color: 'black', fontFamily: 'Visby CF Bold' ,width:"20px"}}>
            Xudayar Ağayarov
          </Typography>
            <Box sx={{ height: "Hug(24px)px",padding: "4px 8px 4px 0px",width:"auto",
                    
                    }}>
                      
                      <Typography sx={{ fontSize: '16px', color: 'black', fontFamily: 'Visby CF Regular'}}>
                        Q401
                      </Typography>
            </Box>

        </Box></Box>
      ),
      sortable: false
    },
    { 
      field: 'title',
      headerName: 'Sorğunun növü',
      flex:1.1,
      height: "44px",
      

      renderCell: (params:any) => (
        <Box sx={{padding: '26px 50px 20px 20px'}}>
        
          <Typography sx={{ fontSize: '16px', color: 'black', fontFamily: 'Visby CF Bold' ,width:"20px"}}>
            {params.row.description}
          </Typography>
            <Box sx={{ height: "Hug(24px)px",padding: "4px 8px 4px 8px",width:"auto",
                    background:"#EAF6FF",
                    borderRadius:"8px",
                    }}>
                      
                      <Typography sx={{ fontSize: '12px', color: '#2DA9FF', fontFamily: 'Visby CF Bold'}}>
                        {params.row.title.toLowerCase()}
                      </Typography>
            </Box>

        </Box>
      ),
      sortable: false
    },

    { 
      field: 'startdate',
      headerName: 'Başlama tarixi',
      flex:1.1,
      
      
      renderCell: (params:any) => (
        <Box sx={{padding: '26px 50px 20px 52px'}}>
          <Typography
           sx={{ fontSize: '14px', color: 'black', fontFamily: 'Visby CF Bold' }}>
            {formattoDDMMYY(params.row.startdate)}
          </Typography>
      </Box>
      ),
      sortable: false
    },

    { 
      field: 'enddate',
      headerName: 'Bitmə tarixi',
      flex:1,
      
      renderCell: (params:any) => (
        <Box sx={{padding: '26px 0px 20px 0px'}}>
        <Typography 
        sx={{ fontSize: '14px', color: 'black', fontFamily: 'Visby CF Bold' }}>
          {formattoDDMMYY(params.row.enddate)}
        </Typography>
    </Box>
    ),
      sortable: false
    },

    { 
      field: 'status',
      headerName: 'Status',
      flex:1,
      renderCell: (params: any) => {
        const rowStatus = params.row.status; 
    
        return (
          <Box sx={{padding: '26px 0px 20px 0px'}}>
          <div className={`${styles.status} ${
            rowStatus === 'apply' 
              ? styles.statusapply 
              : rowStatus === 'wait' 
                ? styles.statuswait 
                : rowStatus === 'reject' 
                  ? styles.statusreject 
                  : styles.statusunknown
          }`}>
            {rowStatus === 'apply' 
              ? 'Təsdiqlənib' 
              : rowStatus === 'wait' 
                ? 'Gözləmədə' 
                : rowStatus === 'reject' 
                  ? 'Qəbul edilməyib' 
                  : 'Naməlum status'}
                  
          </div>

          
          </Box>
        );
      },
      sortable: false
    },
    {
      field: 'symbol',
      flex: 0.4,
      headerName:"",
      renderCell: (params:any) => (
        <Box sx={{marginLeft:"-20px"}} >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M8.5 5L15.5 12L8.5 19" stroke="#BABABA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      </Box>
      ),
      sortable: false
    }
   
  ];

  


  return (
    <>
      <PageHeader>
        <Box>
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="İcazələr" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Mənim icazələrim" />
          </Breadcrumbs>
          <Box>
          {shouldShowPageTitle ? <PageTitle name="Cari icazələr" />:null}

          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}></Box>
      </PageHeader>
      
      
      <Grid container spacing={2}  >
        {filteredSorgularbyDate.map((elem: { status: string | null;
         description: string |null; 
         enddate: string; 
         startdate: string;
         title: string | null; id: string | null;
         file: boolean | null;
         type: string;
         percentage: number;
        
          },
         key: Key | null | undefined) => (
          <Fragment key={key}>
            <Grid item xs={12} md={3} lg={3} >
              <Card
                status={elem.status}
                enddate={formatDate(elem.enddate)}
                startdate={formatDate(elem.startdate)}
                title={elem.title}
                daterange={calculateDateRange(elem.startdate, elem.enddate)}
                id={elem.id}
                description={elem.description}
                file={elem.file}
                openModal={() => handleOpenModal(true, elem)}
                type={elem.type}   
                percentage={elem.percentage}
                
                              />
            </Grid>
          </Fragment>
        ))}
      </Grid>
      
      <Header2 searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>

        <Box sx={{height: boxHeight,border: '0px'}}>
      <DataGrid
        rows={filteredSorgular.slice((page - 1) * pageSize, page * pageSize)}
        columns={columns}
        pageSize={pageSize}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        disableSelectionOnClick
        onCellClick={handleRowClick}
        sx={{...datagridSx,
           border:'none',width:"115.5%",right:'100px',cursor:"pointer",
          }}
        disableColumnFilter
        disableColumnMenu
        components={{
          
          Pagination: () => (
            <CustomPagination
              
              count={totalPages}
              page={page}
              onPageChange={handlePageChange}
              
            />
          ),
        }}
        
      />
    </Box>
    <Drawer
        anchor="right"
        open={openModal}
        onClose={handleCloseModal}
      >
        <Modal 
          status={request?.status}
          start_date={request?.startdate}
          end_date={request?.enddate}
          type={request?.title}
          file={request?.file}
          teacher={request?.teacher}
          group={request?.group} 
          onClose={handleCloseModal}       />
       
        
      </Drawer>

      <Dialog open={openPopUp}
        onClose={()=> setOpenPopUp(false)}
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            borderRadius:"24px",
            
            
          },
        }}
        >
        <Popup
        
        />

      </Dialog>
      
    </>
  );
}
