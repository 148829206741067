import React, { useEffect, useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import StyledDropzone from './dropzone';
import Header from './header';
import { Box } from '@mui/system';
import ActionButtons from './buttons/buttons';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { sxDate } from '../../../MrpManagement/mrp/filter/style';
import 'dayjs/locale/az';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import azLocale from 'dayjs/locale/az';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import Errors from '../../../../auth-views/login/components/Errors';
import { useGetTypesList, usePostRequest } from '../../../../../api/requests';
import { useQueryClient } from 'react-query';
import { apiRoutes } from '../../../../../apiRoutes';
import { useNavigate } from 'react-router-dom';
import { useGetWorkerLoginClass } from '../../../../../api/workers';
import { IWorkerLoginClassModel } from '../../../../../types';
import { useSelector } from 'react-redux';
import { UserHasPermission } from '../../../../../utils/hooks/userPermissions';
import { canAddWorkerDate, canAttendOnline } from '../../../../../permissions';
import { useGetUserLoginClasses } from '../../../../../api/users';

export const sxField = {
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '14px',
  },
};

function NewRequest() {
  const [formData, setFormData] = React.useState({
    startDate: '',
    workDate: '',
    endDate: '',
    status: '',
    files: [] as File[],
    isOnline: false,
    duration: '',
    title: '',
    note: '',
  });
  const [startDatee, setStartDatee] = useState<any | null>('');
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDatee, setEndDatee] = useState<any | null>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [workDatee, setWorkDatee] = useState<any | null>('');
  const [workDateError, setWorkDateError] = useState<string>('');
  const [dateRange, setDateRange] = useState<string>('Müddət');
  const { data: myClasses, refetch: refetchClasses } = useGetWorkerLoginClass(false);
  const user = useSelector((state: any) => state.auth.user);
  const userRole = useSelector((state: any) => state.auth.user.roles[0]);

  const hasCanAttendOnlinePermission = UserHasPermission(canAttendOnline);

  const { data: classes, refetch } = useGetUserLoginClasses(
    startDatee ? startDatee?.format(
      "YYYY-MM-DD") : '',
    endDatee ? endDatee?.format(
      "YYYY-MM-DD") : '',
    false
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const postRequestMutation = usePostRequest();
  const handleFilesChange = (files: File[]) => {
    setFormData((prev) => ({ ...prev, files: files }));
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      return;
    }

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('LeaveRequestTypeId', formData.status.toString()); // İzin türü ID'si
    formDataToSubmit.append(
      'StartDate',
      dayjs(startDatee).format('YYYY-MM-DD')
    ); // Başlangıç tarihi
    formDataToSubmit.append('EndDate', dayjs(endDatee).format('YYYY-MM-DD')); // Bitiş tarihi
    formDataToSubmit.append('Title', formData.title); // Başlık
    formDataToSubmit.append('Note', formData.note); // Not
    formDataToSubmit.append('IsOnline', formData.isOnline.toString()); // Onlayn katılım (boolean)
    if (workDatee) {
      formDataToSubmit.append(
        'JobStartDate',
        dayjs(workDatee).format('YYYY-MM-DD')
      ); // İşe başlama tarihi
    }
    formData.files.forEach((files) => {
      formDataToSubmit.append('Files', files);
    });

    try {
      await postRequestMutation.mutateAsync(formDataToSubmit, {
        onSuccess: () => {
          queryClient.invalidateQueries(apiRoutes.leaverequests.getAll);
          navigate('/admin/app/myrequests');
          console.log(formDataToSubmit, 'Request submitted successfully');
        },
      });
    } catch (error) {
      console.error('Request submission failed:', error);
    }
  };

  const { data, isLoading } = useGetTypesList();

  const calculateDateRange = (start: any, end: any, work: any) => {
    if (!start || !end) return 'Müddət';

    const today = dayjs();
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const workDate = dayjs(work);

    if (startDate.diff(today, 'day') < 2) {
      return 'Başlama tarixi bugündən ən az 3 gün sonra olmalıdır.';
    }


    if (endDate.isBefore(startDate)) {
      return 'Bitiş tarixi, başlama tarixindən sonra olmalıdır.';
    }

    if (!workDate.isAfter(endDate) && userRole !== 'Tələbə') {
      return 'İşə başlama tarixi, bitiş tarixindən sonra olmalıdır.';
    }

    const differenceInDays = endDate.diff(startDate, 'day');
    return `${differenceInDays + 1} gün`;
  };

  useEffect(() => {
    const range = calculateDateRange(startDatee, endDatee, workDatee);
    setDateRange(range);
  }, [startDatee, endDatee, workDatee]);

  useEffect(() => {
    if (userRole !== 'Tələbə' && startDatee && endDatee) {
      refetch();
    }
  }, [startDatee, endDatee]);

  useEffect(() => {
    if (userRole !== 'Tələbə') {
      refetchClasses();
    }
  }, []);

  const isFormValid = () => {
    const isDateRangeValid =
      calculateDateRange(startDatee, endDatee, workDatee) !==
        'Başlama tarixi bugündən ən az 3 gün sonra olmalıdır.' &&
      calculateDateRange(startDatee, endDatee, workDatee) !==
        'Bitiş tarixi, başlama tarixindən sonra olmalıdır.' 
        &&
      calculateDateRange(startDatee, endDatee, workDatee) !==
        'İşə başlama tarixi, bitiş tarixindən sonra olmalıdır.'

    if(userRole !== 'Tələbə' && !workDatee){
      return false;
    }

    return (
      formData.title.trim() &&
      formData.note.trim() &&
      formData.status &&
      startDatee &&
      endDatee &&
      isDateRangeValid
    );
  };

  dayjs.extend(localizedFormat);

  let capitalizedMonthNames: any[] = [];

  if (azLocale.months && Array.isArray(azLocale.months)) {
    capitalizedMonthNames = azLocale.months.map((monthName) => {
      return monthName.charAt(0).toUpperCase() + monthName.slice(1);
    });
  }

  const customAzLocale = {
    ...azLocale,
    months: capitalizedMonthNames,
  };

  function DateIcon() {
    return <CalendarTodayOutlined sx={{ color: '#468CBC' }} />;
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  React.useEffect(() => {
    dayjs.locale(customAzLocale);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Header />
        <Paper
          style={{
            padding: ' 0px 0px 0px 0px',
            boxShadow: 'none',
            width: '626px',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                width: '626px',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{ width: '46px', height: '46px', borderRadius: '200%' }}
                >
                  <img
                    src={`https://avatar.iran.liara.run/username?username=${user.name} ${user.surname}`} // Görsel URL'sini buraya ekleyin
                    alt="profile"
                    style={{
                      borderRadius: '200%',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <Box sx={{ marginLeft: '12px' }}>
                  <Typography
                    sx={{ fontFamily: 'Visby CF Bold', fontSize: '16px' }}
                  >
                    {user.name} {user.surname}
                  </Typography>
                  <Typography
                    sx={{
                      color: ' var(--Natural-natural-300, #6B696A)',
                      fontSize: '14px',
                    }}
                  >
                    {myClasses ? (
                      <>
                        {myClasses?.length > 0 ? (
                          <>
                            {(myClasses as any)?.map(
                              (
                                classItem: IWorkerLoginClassModel,
                                index: number
                              ) => (
                                <span
                                  key={index}
                                  style={{ fontFamily: 'Visby CF Bold' }}
                                >
                                  {classItem.name}
                                  {index < myClasses.length - 1 && (
                                    <span style={{ color: '#999898' }}>
                                      {' '}
                                      |{' '}
                                    </span>
                                  )}
                                </span>
                              )
                            )}
                          </>
                        ) : (
                          <span style={{ fontFamily: 'Visby CF Bold' }}>
                            Məlumat yoxdur
                          </span>
                        )}
                      </>
                    ) : null}
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Visby CF Regular ',
                  fontSize: '14px',
                  marginTop: '22px',
                }}
              >
                İcazə haqqı:{' '}
                <span style={{ fontFamily: 'Visby CF Bold' }}>
                  {user.totalLeaveDayLimit} gün
                </span>
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                sx={{
                  width: '626px',
                  height: '52px',
                  gap: '0px',
                  marginTop: '16px',
                }}
              >
                <InputLabel required id="status-label">
                  İcazənin növü
                </InputLabel>
                <Select
                  labelId="status-label"
                  value={formData.status}
                  label="İcazənin növü"
                  onChange={handleChange}
                  name="status"
                  sx={{
                    border: 'none',
                    '& fieldset': {
                      border: '1px solid var(--Natural-natural-200, #999898)',
                      borderRadius: '12px',
                    },
                  }}
                >
                  {data && data.length > 0 ? (
                    data.map((item: { id: number; name: string }) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Data not available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Box
              display="flex"
              width={'100%'}
              justifyContent="space-between"
              alignItems="center"
              gap={'16px'}
              sx={{ height: 'Hug(52px)px', marginTop: '18px' }}
            >
              <LocalizationProvider
                locale={customAzLocale}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={'Başlama tarixi *'}
                  format="DD MMM YYYY"
                  value={startDatee}
                  onChange={(date) => {
                    setStartDatee(date);
                    setStartDateError('');
                  }}
                  sx={{ ...sxDate, width: '100%' }}
                  slots={{
                    openPickerIcon: DateIcon,
                  }}
                />
              </LocalizationProvider>

              <LocalizationProvider
                locale={customAzLocale}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={'Bitmə tarixi *'}
                  format="DD MMM YYYY"
                  value={endDatee}
                  onChange={(date) => {
                    setEndDatee(date);
                    setEndDateError('');
                  }}
                  sx={{ ...sxDate, width: '100%' }}
                  slots={{
                    openPickerIcon: DateIcon,
                  }}
                />
                {!UserHasPermission(canAddWorkerDate) && (
                  <Box
                    sx={{
                      display: 'flex',
                      padding: ' 8px 10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '6px',
                      background: '#FDF3EB',
                      height: '40px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#E7873C',
                        fontFamily: 'Visby CF Bold',
                      }}
                    >
                      {dateRange}
                    </span>
                  </Box>
                )}

                {UserHasPermission(canAddWorkerDate) && (
                  <DatePicker
                    label={'İşə başlama tarixi *'}
                    format="DD MMMM YYYY"
                    value={workDatee}
                    onChange={(date) => {
                      setWorkDatee(date);
                      setWorkDateError('');
                    }}
                    sx={{ ...sxDate, width: '100%' }}
                    slots={{
                      openPickerIcon: DateIcon,
                    }}
                  />
                )}
              </LocalizationProvider>

              {endDateError && <Errors message={endDateError} />}
            </Box>
            {userRole !== 'Tələbə' && classes &&  classes?.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: '55px',
                  padding: '2px',
                  marginTop: '9px',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 9.9993C0 4.4803 4.48 -0.000701904 10 -0.000701904C15.53 -0.000701904 20 4.4803 20 9.9993C20 15.5203 15.53 19.9993 10 19.9993C4.48 19.9993
      0 15.5203 0 9.9993ZM9.12 6.2093C9.12 5.7303 9.52 5.3293 10 5.3293C10.48 5.3293 10.87 5.7303 10.87 6.2093V10.6293C10.87
        11.1103 10.48 11.4993 10 11.4993C9.52 11.4993 9.12 11.1103 9.12 10.6293V6.2093ZM10.01 14.6803C9.52 14.6803 9.13 14.2803
        9.13 13.8003C9.13 13.3203 9.52 12.9303 10 12.9303C10.49 12.9303 10.88 13.3203 10.88 13.8003C10.88 14.2803 10.49 14.6803 
        10.01 14.6803Z"
                      fill="#E7873C"
                    />
                  </svg>
                  {
                    classes?.length > 0 ? (
                      <Typography
                        sx={{
                          fontFamily: 'Visby CF Regular',
                          color:
                            'var(--Wireframe-color-styles-Base-blue-900, #4F5259)',
                        }}
                      >
                        Diqqət! İcazə tarixiniz{' '}
                        <span style={{ fontFamily: 'Visby CF Bold' }}>
                          {' '}
                          {classes?.length > 0 ? (
                            (classes as any)?.map(
                              (
                                classItem: IWorkerLoginClassModel,
                                index: number
                              ) => (
                                <span
                                  key={index}
                                  style={{ fontFamily: 'Visby CF Bold' }}
                                >
                                  {classItem.name}
                                  {index < classes.length - 1 && (
                                    <span style={{ color: '#999898' }}> | </span>
                                  )}
                                </span>
                              )
                            )
                          ) : null}
                        </span>{' '}
                        qrupunun dərs günlərini əhatə edir.
                      </Typography>
                    ) : null
                  }
                </Box>

                {userRole !== 'Tələbə' && (
                  <Box
                    sx={{
                      display: 'flex',
                      padding: ' 8px 10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '6px',
                      background: '#FDF3EB',
                      minWidth: '65px'
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#E7873C',
                        fontFamily: 'Visby CF Bold',
                      }}
                    >
                      {dateRange}
                    </span>
                  </Box>
                )}
              </Box>
            ) : null}
            <TextField
              fullWidth
              label="Başlıq"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              sx={{
                marginTop: '16px',
                fill: '#182px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #999898',
                    borderRadius: '12px',
                    '&.Mui-focused fieldset': {
                      borderColor: '#468CBC',
                    },
                  },
                },
              }}
            />
            <TextField
              fullWidth
              label="Qeyd yazın.."
              name="note"
              value={formData.note}
              onChange={handleChange}
              required
              multiline
              maxRows={3}
              sx={{
                marginTop: '16px',
                height: '108px',
                fill: '#182px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #999898',
                    borderRadius: '12px',
                    height: '108px',
                  },
                },
              }}
            />
            <Box sx={{ marginTop: '16px' }}>
              <StyledDropzone onFilesChange={handleFilesChange} />
            </Box>
            {hasCanAttendOnlinePermission ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isOnline}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        isOnline: e.target.checked,
                      }))
                    }
                  />
                }
                sx={{ fontSize: '16px' }}
                label="Onlayn iştirak"
              />
            ) : null}
            <ActionButtons
              isDisabled={!isFormValid()}
              handleSubmit={handleSubmit}
            />
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

export default NewRequest;
