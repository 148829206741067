import {
  Table,
  TableContainer,
  TableBody,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { Fragment } from "react";
import Header from "./header";
import Row from "./row";
import CustomDatePicker from "../date-picker";
import CustomMultipleAutocomplete from "../../../../../../../components/custom-components/custom-multuple-autocomplete";
import { usePostTotalHoursByDate } from "../../../../../../../api/classes";
import dayjs from "dayjs";
import Progress from "../../../../../../../components/custom-components/progress";
import { ReactComponent as InfoIcon } from "../../../../../../../assets/icons/info.svg";

interface ISetProgramTableModel {
  generationStartDate: any;
  modules: any;
  setModules: any;
  customSX?: any;
  classId?: any;
  headerCells: any;
  hasCheckBox: boolean;
  daysSession: any;
}
export default function Index({
  modules,
  classId,
  setModules,
  customSX,
  headerCells,
  hasCheckBox,
  generationStartDate,
  daysSession,
}: ISetProgramTableModel) {
  const mutation = usePostTotalHoursByDate(classId); 
  const handleStartAndEndDateChange = async (
    newDate: any,
    key: any,
    startDate?: any,
    endDate?: any,
    workerType?: any
  ) => {
    try {
      // 1. Modülleri güncelle
      const updatedModules = modules.map((elem: any) => {
        if (
          elem.startDate === startDate &&
          elem.endDate === endDate &&
          elem.workerType === workerType
        ) {
          return { ...elem, [key]: newDate, totalHours: 0 };
        }
        return elem;
      });
      if (key === "endDate") {
        // Kendinden sonra gelen modülleri kontrol et
        const conflictingModules = modules.filter(
          (elem: any) =>
            elem.workerType === workerType &&
            dayjs(elem.startDate).isAfter(dayjs(startDate)) && // Kendinden sonra gelen modülleri kontrol et
            dayjs(newDate).isAfter(dayjs(elem.endDate)) // Yeni endDate, bu modüllerin endDate'inden büyük mü?
        );

        if (conflictingModules.length > 0) {
          // Eğer büyük bir `endDate` varsa, o modülleri kaldır ve mevcut modülün `endDate` değerini güncelle.
          const adjustedModules = updatedModules
            .filter(
              (elem: any) =>
                !conflictingModules.some(
                  (conflict: any) =>
                    conflict.startDate === elem.startDate &&
                    conflict.endDate === elem.endDate
                )
            )
            .map((elem: any) => {
              if (
                elem.startDate === startDate &&
                elem.workerType === workerType
              ) {
                return {
                  ...elem,
                  endDate: dayjs(newDate).format("YYYY-MM-DD"),
                };
              }
              return elem;
            });

          // Güncellenmiş modülleri API'ye gönder
          await updateModules(adjustedModules);
          return; // Yeni modül eklenmeden işlem tamamlandı.
        } else {
          const nextModule = modules.find(
            (elem: any) =>
              elem.workerType === workerType &&
              elem.startDate ===
                dayjs(endDate).add(1, "day").format("YYYY-MM-DD")
          );
          if (nextModule) {
            const adjustedModules = updatedModules.map((elem: any) => {
              if (
                elem.startDate === nextModule.startDate &&
                elem.workerType === workerType
              ) {
                return {
                  ...elem,
                  startDate: dayjs(newDate).add(1, "day").format("YYYY-MM-DD"),
                };
              }
              return elem;
            });

            await updateModules(adjustedModules);
            return; // Yeni modül eklenmeden işlem tamamlandı.
          } else if (dayjs(newDate).isAfter(dayjs(endDate))) {
            return await updateModules(updatedModules);
          }
        }
        await updateModules(updatedModules);
      }
      // 2. `startDate` değişiminde önceki modülü bul ve `endDate`'ini güncelle.
      if (key === "startDate") {
        const previousModule = modules.find(
          (elem: any) =>
            elem.workerType === workerType &&
            elem.endDate ===
              dayjs(startDate).subtract(1, "day").format("YYYY-MM-DD")
        );

        if (previousModule) {
          const adjustedModules = updatedModules.map((elem: any) => {
            if (
              elem.endDate === previousModule.endDate &&
              elem.workerType === workerType
            ) {
              return {
                ...elem,
                endDate: dayjs(newDate).subtract(1, "day").format("YYYY-MM-DD"),
              };
            }
            return elem;
          });

          await updateModules(adjustedModules);
          return; // Yeni modül eklenmeden işlem tamamlandı.
        }
      }
      // 4. Yeni modül oluştur ve ekle
      const newStartDate = dayjs(newDate).add(1, "day").format("YYYY-MM-DD");
      const newModule = {
        startDate: newStartDate,
        endDate: endDate,
        workerType,
        totalHours: 0,
        workers: [],
      };
      const finalModules = [newModule, ...updatedModules];
      await updateModules(finalModules);
    } catch (error) {
      console.error("Mutation error:", error);
    }
  };

  const updateModules = async (modules: any[]) => {
    // API'ye mutasyon için modülleri gönder.
    const datesArray = modules.map((module: any) => ({
      startDate: module.startDate,
      endDate: module.endDate,
    }));

    const response = await mutation.mutateAsync(datesArray);

    // Modülleri state'e kaydet
    setModules(() =>
      response.data?.map((module: any) => {
        const existingModule = modules.find(
          (elem: any) =>
            elem.startDate === module.startDate &&
            elem.endDate === module.endDate
        );
        return {
          ...existingModule,
          totalHours: module.totalHours,
        };
      })
    );
  };

  const handleTeacherAndMentorChange = (
    selectedWorkers: any[], // Dropdown'da seçilen kullanıcılar
    startDate?: any,
    endDate?: any,
    workerType?: any
  ) => {
    setModules((prevModules: any) =>
      prevModules.map((module: any) => {
        if (
          module.startDate === startDate &&
          module.endDate === endDate &&
          module.workerType === workerType
        ) {
          return {
            ...module,
            workers: selectedWorkers, // Yalnızca yeni seçilen kullanıcıları ayarla
          };
        }
        return module;
      })
    );
  };
  return (
    <TableContainer sx={customSX}>
      <Table
        sx={{
          width: "100%",
          border: "none",
        }}
        aria-label="simple table"
      >
        <Header cells={headerCells} />
        <TableBody sx={{ textAlign: "center" }}>
          {mutation.isLoading ? (
            <Progress />
          ) : (
            <>
              {modules &&
                modules.map((module: any, index: any) => (
                  <Fragment key={index}>
                    <Row
                      hasCheckBox={hasCheckBox}
                      cells={headerCells.map((key: any, index: number) => {
                        if (key.key === "workerType") {
                          return {
                            key: key.key,
                            isCustomelement: true,
                            customElement: (
                              <Box
                                display="flex"
                                alignItems="center"
                                gap={5}
                                position={"relative"}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontFamily: "Visby CF Bold, sans-serif",
                                  }}
                                >
                                  {module[key?.key]}
                                </Typography>
                                {module.assignType === "LeaveRequest" && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <Tooltip
                                      title="Əvəzedici müəllim olaraq təyin edilmişdir."
                                      arrow
                                      placement="top"
                                      sx={{
                                        "& .MuiTooltip-tooltip": {
                                          background: "#FBEDEB",
                                          color: "#C24234",
                                          fontSize: "12px",
                                          fontFamily:
                                            "Visby CF Bold, sans-serif",
                                          borderRadius: "4px",
                                          padding: "4px 8px",
                                        },
                                      }}
                                    >
                                      <InfoIcon style={{ cursor: "pointer" }} />
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            ),
                          };
                        } else if (key.key === "moduleName") {
                          return {
                            key: key.key,
                            isCustomelement: true,
                            customElement: (
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Visby CF Bold, sans-serif",
                                }}
                              >
                                {`${module.versionNo} . ${module[key?.key]}`}
                              </Typography>
                            ),
                          };
                        } else if (
                          key.key === "startDate" ||
                          key.key === "endDate"
                        ) {
                          return {
                            key: key.key,
                            text: module[key?.key],
                            isCustomelement: true,
                            customElement: (
                              <CustomDatePicker
                                daysSession={daysSession}
                                minSelectDate={
                                  key.key === "startDate"
                                    ? generationStartDate
                                    : module.startDate
                                }
                                isOpen={
                                  key.key === "startDate"
                                    ? module.startDate === generationStartDate
                                    : false
                                }
                                date={module[key?.key]}
                                handleDateChange={(e) =>
                                  handleStartAndEndDateChange(
                                    e,
                                    key.key,
                                    module.startDate,
                                    module.endDate,
                                    module.workerType
                                  )
                                }
                                type="date"
                              />
                            ),
                          };
                        } else if (key.key === "workers") {
                          return {
                            key: key.key,
                            isCustomelement: true,
                            customElement: (
                              <CustomMultipleAutocomplete
                                workerType={module.workerType || []}
                                value={module.workers} // Pass the array of selected workers
                                label={""}
                                url={`Workers?workerType=${module.workerType}`}
                                propName="name"
                                onChange={(selectedWorkers: any[]) =>
                                  handleTeacherAndMentorChange(
                                    selectedWorkers,
                                    module.startDate,
                                    module.endDate,
                                    module.workerType
                                  )
                                }
                              />
                            ),
                          };
                        }
                        return {
                          key: key.key,
                          text: module[key?.key],
                        };
                      })}
                    />
                  </Fragment>
                ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
