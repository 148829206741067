import {
    useDelete,
      useFetch, usePost, useUpdate,
} from '../utils/api/reactQuery';
import { apiRoutes } from '../apiRoutes';
import { IWorkerLoginClassModel } from '../types';
import { IWorkerModel } from "../types";

export const useGetWorkersList = () =>
    useFetch<IWorkerModel[]>(apiRoutes.workers.getAll, undefined, [apiRoutes.workers.base, undefined]);

export const useAddWorker = () => 
    usePost<any,any>(apiRoutes.workers.addWorker,undefined);

export const useDeleteWorker = () => 
    useDelete(apiRoutes.workers.delete);

export const useGetWorkerLoginClass = () =>
  useFetch<IWorkerLoginClassModel[]>(apiRoutes.workers.loginClasses, undefined, [apiRoutes.workers.base, undefined]);