import {
  useDelete,
    useFetch, usePost, useUpdate,
} from '../utils/api/reactQuery';
import { apiRoutes } from '../apiRoutes';
import { IWorkerLoginClassModel } from '../types';

export const useGetAllExamModules = () =>
useFetch<any[]>(apiRoutes.workers.allExamModules);

export const useGetAllWorkers = (isEnabled = true) =>
useFetch<any[]>(apiRoutes.workers.getAll, {}, [apiRoutes.workers.getAll, undefined], isEnabled);

export const useGetWorkersByRole = (role: string, isEnabled = true) =>
useFetch<any[]>(apiRoutes.workers.getByRole(role), {}, [apiRoutes.workers.getByRole(role), undefined], isEnabled);

export const useCreateExamSheet = (
updater: (oldData: any, newData: any) => any) => 
usePost<any, any>(apiRoutes.workers.createExamSheet, undefined, updater);

export const useCreateSurveySheet = (
updater: (oldData: any, newData: any) => any) => 
usePost<any, any>(apiRoutes.workers.createSurveySheet, undefined, updater);

export const useAddWorker = () => 
  usePost<any,any>(apiRoutes.workers.addWorker,undefined);

export const useGetWorkerLoginClass = (isEnabled=true) =>
useFetch<IWorkerLoginClassModel[]>(apiRoutes.workers.loginClasses, undefined, [apiRoutes.workers.base, undefined], isEnabled);