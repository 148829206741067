import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams for route parameters
import moment from "moment";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { Box, Typography, Tab, Tabs, Link } from "@mui/material";
import { sxBtn } from "../style";
import { ModelSkeleton } from "../components/model-skeleton";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { sxTab } from "../components/tab-header/style";
import {
  StudentTableDetail,
  StudentHeaderDetail,
} from "../models/StudentDetail";
import DataGrid from "../../../../../components/custom-components/data-grid";
import { Kpi } from "../components/kpi";
import { API_URL, APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
import Attendance from "./attendance";
import "./style.css";
import AttendanceGraph from "./attendanceGraph";
import { tabColumns } from "./tableColumns";
import { BaseService } from "../../../../../network/services/base/BaseService";
import GroupInfo from "../components/group-info";

function GroupDetail() {
  const { id } = useParams<{ id: string }>(); // Extract classId from route
  const navigate = useNavigate();
  const [studentsProgres, setStudentProgres] = useState(0);
  const [isGraph, setIsGraph] = useState<boolean>(false);
  const [studentsHeaderDetail, setStudentsHeaderDetail] = useState<
    StudentHeaderDetail | undefined
  >(undefined);
  const [studentsTableDetail, setStudentsTableDetail] = useState<
    StudentTableDetail[]
  >([]);
  const [isLoading, setisLoading] = useState(true); // Manage loading state
  const getStudentsHeaderDetail = async (id: string) => {
    try {
      const getClassesDetails = new BaseService("/api/Classes");
      const res = await getClassesDetails.getById(`${id}`);
      setStudentsHeaderDetail(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getStudentsTableDetail = async (id: string) => {
    try {
      const getStudentsByClassId = new BaseService(
        `/api/Classes/${id}/students-details`
      );
      const res = await getStudentsByClassId.getAll();
      setStudentsTableDetail(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      setisLoading(true);
      Promise.all([getStudentsTableDetail(id), getStudentsHeaderDetail(id)])
        .then(() => setisLoading(false))
        .catch((err) => {
          console.error(err);
          setisLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100vh",
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <ModelSkeleton />
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              flex-start
              marginBottom="10px"
            >
              <GroupInfo studentsHeaderDetail={studentsHeaderDetail} />
              <Box display="flex" gap="16px">
                <Kpi
                  value={`${studentsHeaderDetail?.attendanceRate || 0}%`}
                  color="#4BA458"
                  tagTitle="Davamiyyət göstəricisi"
                  iconColor="#C7E3CB"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              height="32px"
              justifyContent="space-between"
              alignItems="center"
              alignSelf="stretch"
            >
              <Tabs value={studentsProgres} sx={{ height: "40px" }}>
                <Tab
                  sx={sxTab}
                  label="Tələbələr"
                  onClick={() => setStudentProgres(0)}
                />
                <Tab
                  sx={sxTab}
                  label="Davamiyyət göstəricisi"
                  onClick={() => setStudentProgres(1)}
                />
              </Tabs>
              <Box display="flex" alignItems="flex-start" gap="8px">
                <FileDownloadOutlinedIcon
                  sx={{ width: "24px", height: "24px", color: "#854693" }}
                />
                <Link
                  target="_blank"
                  href={`${API_URL}api/classes/${id}/export/excel`}
                  sx={{
                    padding: "0",
                    color: "#000",
                    fontFamily: "Visby CF Bold, sans-serif",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    textDecorationLine: "underline",
                    textTransform: "initial",
                    marginRight: 3,
                  }}
                >
                  Excel kimi yüklə
                </Link>
              </Box>
            </Box>
            {studentsProgres === 0 ? (
              <Box
                className="hideScrollBar"
                width="100%"
                sx={{ overflowY: "scroll" }}
              >
                <DataGrid
                  data={
                    studentsTableDetail.map((row, index) => ({
                      ...row,
                      rowNumber: index + 1,
                    })) ?? []
                  }
                  columnsData={tabColumns}
                  pageSizeData={10}
                  setPageSize={() => {}}
                  disableColumnMenu={true}
                />
              </Box>
            ) : studentsProgres === 1 ? (
              !isGraph ? (
                <Attendance classId={id!} />
              ) : (
                <AttendanceGraph classId={id!} />
              )
            ) : null}
          </>
        )}
        {/* Fixed Button Container */}
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            left: 0,
            right: 50,
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            gap: 2,
            padding: "10px 20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={sxBtn}
            onClick={() =>  navigate(`${APP_PREFIX_PATH}/set-program/${id}`)}
          >
           Proqramı tənzimlə
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default GroupDetail;
