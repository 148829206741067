import { IPermissionModel } from "../../permissions";
import { UserHasAnyOfPermissions, UserHasPermission } from "../hooks/userPermissions";

export function MenuItemRoleProvider({
  children,
  permission,
}: {
  children: JSX.Element;
  permission: IPermissionModel[];
}) {

  const res = UserHasAnyOfPermissions(permission);

  if(res){
    return children;
  }else{
    return <></>;
  }
}
