import React, { useEffect, useState } from 'react'
import { Table, TableContainer } from '@mui/material'
import { Module } from '../../models/Module';
import { SubModule } from '../../models/SubModule';
import { PostData } from '../../models/PostData';
import { Worker as NetworkWorker } from '../../../../../../../../network/models/worker/Worker';
import TableHeader from '../table-header';
import TableBody from '../table-body';
import { Worker } from '../../models/Worker';
import { ROLES } from '../../../../../../../../constants/auth/roles';

interface TableListModel {
    data: Module[],
    instructors: string[],
    mentors: string[],
    workers: NetworkWorker[],
    checked: string[],
    copiedData: Module[],
    setCopiedData: React.Dispatch<React.SetStateAction<Module[]>>,
    setChecked: React.Dispatch<React.SetStateAction<string[]>>,
    instructor: string,
    mentor: string,
    postData: PostData[],
    setPostData: React.Dispatch<React.SetStateAction<PostData[]>>
}

function TableList(props: TableListModel) {

    const {
        data,
        instructors,
        mentors,
        workers,
        checked,
        setChecked,
        copiedData,
        setCopiedData,
        instructor,
        mentor,
        setPostData,
    } = props

    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const [checkedDataLength, setCheckedDataLength] = useState<number>(-1);



    useEffect(() => {
        setCopiedData([...data])
    }, [data])

    const getWorker = (worker: NetworkWorker, role: string) => {
        
        return {
            ...worker,
            role: worker.roles.filter(q => q.name === role)[0]?.name,
            roleId: worker.roles.filter(q => q.name === role)[0]?.id?.toString()!,
            id: worker.id?.toString()!
        }
    }

    const checkWorkers = (item: Module | SubModule, itemWorkers: Worker[]) => {
        const selectedMentor = workers.find(({ name, surname }) => `${name} ${surname}` === mentor);
        const selectedInstructor = workers.find(({ name, surname }) => `${name} ${surname}` === instructor);
        if (item.workers.length === 0) {
            if (selectedInstructor) {
                itemWorkers.push(getWorker(selectedInstructor, ROLES.MUELLIM));
            }
            else if (selectedMentor) {
                itemWorkers.push(getWorker(selectedMentor, ROLES.MENTOR));
            }
        }
        else if (item.workers.length === 1) {
            if (item.workers.filter(worker => worker.role === ROLES.MUELLIM).length === 1 && selectedMentor) {
                itemWorkers.push(getWorker(selectedMentor, ROLES.MENTOR));
            }
            else if (item.workers.filter(worker => worker.role === ROLES.MUELLIM).length === 1 && selectedInstructor) {
                itemWorkers = [getWorker(selectedInstructor, ROLES.MUELLIM)]
            }
            else if (item.workers.filter(worker => worker.role === ROLES.MENTOR).length === 1 && selectedInstructor) {
                itemWorkers.push(getWorker(selectedInstructor, ROLES.MUELLIM));
            }
            else if (item.workers.filter(worker => worker.role === ROLES.MENTOR).length === 1 && selectedMentor) {
                itemWorkers = [getWorker(selectedMentor, ROLES.MENTOR)]
            }
        }
        else {
            itemWorkers = item.workers.map((worker) => {
                if (worker.role === ROLES.MUELLIM && selectedInstructor) {
                    return getWorker(selectedInstructor, ROLES.MUELLIM)
                } else if (worker.role === ROLES.MENTOR && selectedMentor) {
                    return getWorker(selectedMentor, ROLES.MENTOR)
                }
                else {
                    return worker;
                }
            })
        }
        return itemWorkers
    }

    useEffect(() => {
        if (checked.length > 0) {
            let updatedData = copiedData
            checked.forEach(id => {
       
                
                updatedData = updatedData.map((item: Module) => {
              
                    let itemWorkers = [...item.workers];
                    let itemSubmodules = [...item.subModules];
                    if (item.id === id) {
                        itemWorkers = checkWorkers(item, itemWorkers)
                    } else {
                        if (itemSubmodules.length !== 0) {
                            itemSubmodules = itemSubmodules.map((subModule: SubModule) => {
                                let subModuleWorkers = [...subModule.workers];
                                if (subModule.id === id) {
                                    subModuleWorkers = checkWorkers(subModule, subModuleWorkers)
                                }
                                return {
                                    ...subModule,
                                    workers: subModuleWorkers
                                }
                            })
                        }
                    }
                    return {
                        ...item,
                        workers: itemWorkers,
                        subModules: itemSubmodules
                    }
                }
                )
            })
            setCopiedData(updatedData)
        }
    }, [instructor, mentor])

    useEffect(() => {
        let postedData = copiedData?.flatMap((q) => {
            let moduleWorkers = q.workers?.map(worker => ({
                moduleId: q.id,
                workerId: worker.id,
                roleId: worker.roleId
            }));

            let subModuleWorkers = q.subModules?.flatMap((subModule: SubModule) => {
                return subModule.workers?.map(worker => ({
                    moduleId: subModule.id,
                    workerId: worker.id,
                    roleId: worker.roleId

                }));
            });

            return [...moduleWorkers, ...subModuleWorkers];
        });
        setPostData(postedData);
    }, [copiedData])



    useEffect(() => {
        let dataLength = data.length;
        data.filter(item => {
            dataLength += item.subModules.length
        })
        setCheckedDataLength(dataLength);
        if (checked.length > 0 && checked.length < dataLength) {
            setIndeterminate(true)
        } else {
            setIndeterminate(false)
        }
    }, [checked, data.length])

    return (
        <>
            {copiedData.length > 0 &&
                (<TableContainer>
                    <Table
                        sx={{
                            width: "100%",
                            border: "none",
                        }}
                        aria-label="simple table"
                    >

                        <TableHeader
                            indeterminate={indeterminate}
                            checked={checked}
                            checkedDataLength={checkedDataLength}
                            setChecked={setChecked}
                            copiedData={copiedData}
                            setCopiedData={setCopiedData}

                        />

                        <TableBody
                            copiedData={copiedData}
                            checked={checked}
                            setChecked={setChecked}
                            workers={workers}
                            setCopiedData={setCopiedData}
                            instructors={instructors}
                            mentors={mentors}
                        />


                    </Table>
                </TableContainer>
                )
            }
        </>
    )
}

export default TableList