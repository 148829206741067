export const sxFormControl = {
    display: "flex",
  
    "& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
      right: "20px",
    },
    "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
      {
        right: "20px",
      },
  };