import React, { useState } from "react";
import Header from "./components/Header";
import { Class } from "./models/StudentModels";
import Progress from "../../../../components/custom-components/progress";
import { GridColDef } from "@mui/x-data-grid";
import { localeText } from "../../../../configs/Locales";
import { StudentAttendance } from "../continuity-result-students/models/StudentAttendance";
import { ModalData } from "../continuity-result-students/models/ModalData";
import { Dialog, Slide, TextField } from "@mui/material";
import Modal from "../continuity-result-students/components/modal";
import { TransitionProps } from "@mui/material/transitions";
import DataGrid from "../../../../components/custom-components/data-grid";
import { useGetStudents} from "../../../../api/students";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StudentsList() {
  const [selectedStudentDetail, setSelectedStudentDetail] = useState([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { data: students = [] } = useGetStudents();
  const [classId, setclassId] = useState<string>("");
  const [studentId,setStudentId] =useState<string>("");
  const [modalData, setModalData] = useState<ModalData[]>([]);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [studentAttendance, setStudentAttendance] = useState<StudentAttendance>(
    {
      id: "",
      name: "",
      surname: "",
      phone: "",
      eMail: "",
      attendancePercent: 0,
      attendances: [],
    }
  );  

  
  if (loading) {
    return <Progress />;
  }
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Ad Soyad",
      flex: 1,
      renderCell: (params) => `${params.value} ${params.row.surname}`,
    },

    {
      field: "eMail",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Telefon",
      flex: 1,
    },

    {
      field: "classes",
      headerName: "Qrup",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const handleClick = (classItem: Class) => {
         
          setclassId(classItem.id);
          setStudentId(params.row.id);
          setOpenModal(true);        
          
        };
       
        const { classes } = params.row;
        return (
          <>
            {classes.map((classItem: Class) => {
              return (
                <React.Fragment key={classItem.id}>
                  <p
                    onClick={() => handleClick(classItem)
                      
                    }
                    style={{
                      margin: "0 5px",
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "blue",
                    }}
                  >
                    {classItem.name}
                  </p>
                </React.Fragment>
              );
            })}
          </>
        );
      },
    },
  ];

  return (
    <>
        <Header />
        <DataGrid
          data={students.filter((row) =>
            `${row.name} ${row.surname}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )}
          columnsData={columns}
          pageSizeData={pageSize}
          setPageSize={setPageSize}
          disableColumnMenu={false}
          localeText={localeText}
          components={{
            Toolbar: () => (
              <TextField
                autoFocus
                variant="standard"
                placeholder="Tələbə axtar.."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value.trimStart())}
              />
            ),
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
            pagination: {
              labelRowsPerPage: "Səhifədəki sətir sayı:",
            },
          }}
        />
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenModal(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            maxWidth: "none",
            width: "75%",
          },
        }}
      >
        {
          (studentId && classId) ? (
            <Modal
              selectedStudentDetail={selectedStudentDetail}
              studentAttendance={studentAttendance}
              modalData={modalData}
              modalLoading={modalLoading}
              studentId={studentId}
              classId={classId}        />
          ) : null
        }
      </Dialog>
    </>
  );
}

export default StudentsList;
