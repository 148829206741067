import { Box, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CustomSelectModal } from "../../../continuity/components/CustomSelectModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface IClassSelectionModel{
  workerLogin: any,
  currentClass: any,
  setCurrentClass: any,
  isAdmin: boolean,
  classAdmin: any
}

export default function ClassSelection({workerLogin, classAdmin, currentClass, setCurrentClass, isAdmin} : IClassSelectionModel) {
  const [isOpen, setIsOpen] = useState(false);
  const {id, date} = useParams();
  const handleChange = (value: any) => {
    if(!isAdmin){
      setCurrentClass(workerLogin?.find((x: any)=> x.name === value));
    }
    setIsOpen(false);
  }
  useEffect(()=> {
      if(workerLogin) {
        setCurrentClass(workerLogin[0])
      }
  }, [workerLogin])

  useEffect(()=> {
    if(classAdmin) {
      setCurrentClass({...classAdmin, id: id, programId: classAdmin?.program?.id})
    }
}, [isAdmin])
  
  return (
    <Box display="flex" alignItems="center" gap="56px" sx={{marginRight: '40px'}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start" position='relative'sx={{cursor:"pointer"}}>
          <Box display="flex" alignItems="center" gap="8px">
            <Typography
              variant="h1"
              sx={{
                fontSize: "24px",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
              lineHeight="32px"
            >
              {(currentClass as any)?.name ?? ""}
            </Typography>
            {
              !isAdmin ? (
                <>
                  <ArrowDropDownIcon
                      onClick={()=> setIsOpen(!isOpen)}
                    sx={{ width: "24px", height: "24px", color: "#6B696A" }}
                  />
                  <CustomSelectModal data={workerLogin ? workerLogin : []} handleChange={handleChange} isOpen={isOpen}  />
                </>
              ) : null
            }
          </Box>
          <Box
            display="flex"
            color="#6B696A"
            alignItems="center"
            fontSize="12px"
            fontFamily="Visby CF, sans-serif"
            letterSpacing="0.4px"
          >
            Keçirilib:
            <Typography
              sx={{
                fontFamily: "Visby CF Bold, sans-serif",
                color: "#0E0D0D",
                fontSize: "12px",
              }}
            >
              {`${(currentClass?.attendanceHours ?? currentClass?.currentHours) ?? '-'}/${currentClass?.totalHours} Saat`}
            </Typography>
          </Box>
        </Box>
    </Box>
          
  )
}
