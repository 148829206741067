import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Typography from '@mui/material/Typography';
import styles from './header.module.css';
import { Box } from '@mui/system';
import Button from '../../../../../../components/core-components/inputs/button';
import { useNavigate } from 'react-router-dom';

export default function Header() {

    const navigate = useNavigate();

    return (
        <>
            <Box
                sx={{
                    height: "56px",
                    top: "76px",
                    gap: "10px",
                    display: 'flex',
                    alignItems: 'center', 
                }}
            >
                <Box className={`${styles.header}`} sx={{ display: 'flex', alignItems: 'center' }}> 
                   <Button sx={{background:"none",width:"0px",boxShadow:"none","&:hover": {
                                 background: "none",
                                    boxShadow: "none",    
        }}}
        onClick={() => navigate('/admin/app/myrequests')}
        ><ArrowBackRoundedIcon
        
                        sx={{
                            color: "#468CBC",
                            width: "24px",
                            height: "24px",
                            marginRight: "20px", 
                        }}
                    />
                    
                    </Button> 
                    <Typography
                        sx={{
                            fontFamily: "Visby CF Bold",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "24px",
                            letterSpacing: "0.4px",
                            textAlign: "left",
                            marginLeft:"-15px"
                        }}
                    >
                        İcazə sorğusu əlavə et
                    </Typography>
                </Box>
            </Box>
        </>
    );
}
