import * as yup from "yup";


export const educationRequestSchema = yup.object().shape({
    programName: yup.object().required("Boş ola bilməz"),
    startDate: yup.string().required("Boş ola bilməz"),
    endDate: yup.object().required("Boş ola bilməz"),
});

export const teacherMentorRequestSchema = yup.object().shape({
    teacherName: yup.string().required("Boş ola bilməz"),
    startDate: yup.string().required("Boş ola bilməz"),
    endDate: yup.object().required("Boş ola bilməz"),
});