import React, { useState } from "react";
import { Box, InputAdornment, Typography, TextField } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import Button from "../../../../../../../../components/core-components/inputs/button";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAltOutlined';
import styles from './../header2/header2.module.css';
import { useNavigate } from "react-router-dom";
import StatusModal from "../../modal2";


interface Header2Props {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedStatus: string | null;
  onStatusChange: (status: string | null) => void;
}

function Header2({ searchQuery, setSearchQuery, onStatusChange }: Header2Props) {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleStatusSelect = (status: string) => {
    onStatusChange(status);
    setOpenModal(false);
    setSelectedStatus(status);
  };


  return (
    <>
      <Box className={styles.inline}>
        <Box sx={{ alignItems: "center", justifyContent: "space-between", gap: "0px", display: "flex" }} >
          {/* Title */}
          <Grid >
            <h4 className={styles.headertext}>İcazələrim</h4>
          </Grid>

          {/* Search Input */}
          <Box sx={{ gap: "10px", display: "flex" }}>
            <Grid >
              <Box display="flex" alignItems="center" className={styles.search} >
                <TextField
                  variant="standard"

                  placeholder="Axtar"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={styles.searchbutton} />
                      </InputAdornment>
                    ),
                    disableUnderline: true
                  }}
                />
              </Box>
            </Grid>
            {/* status Button */}
            <Grid >
              <Button
                onClick={() => { setOpenModal(true); setValue(value + 1) }}
                variant="text"
                type="submit"
                sx={{
                  backgroundColor: "#F3EDF4",

                  padding: "10px 24px 10px 24px",
                  gap: "8px",
                  border: 'none',
                  borderRadius: "12px",
                  opacity: "0px",
                  boxShadow: "none",
                  fontFamily: 'Visby CF Bold, sans-serif',
                  marginLeft: "0px"

                }}

              >
                <FilterAltIcon sx={{ color: "#854693", height: "20px" }} /><Typography sx={{
                  fontFamily: "Visby CF Bold",
                  fontSize: "14px",
                  textAlign: "left",
                  color: " #0E0D0D",
                  textTransform: "none",
                }}>Status</Typography>
              </Button>
            </Grid>

            {/* Add Button */}
            <Grid sx={{ marginRight: "0px" }}>
              <Button
                onClick={() => navigate("/admin/app/myrequests/newrequest")}

              >
                <AddIcon sx={{ height: "20px" }} />İcazə əlavə et
              </Button>
            </Grid>
          </Box>
        </Box>
        <StatusModal open={openModal}
          onClose={handleCloseModal}
          value={value}
          onSelectStatus={handleStatusSelect}


        ></StatusModal>
      </Box>
    </>
  );
}

export default Header2;
