import {
    Box,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useGetUnmarkedAttendancesList } from "../../../../../../api/classes";


export default function Calendar({classId}: any) {

    const params = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const {data: unMarkedAttendance} = useGetUnmarkedAttendancesList(classId);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
        params.date ? dayjs(params.date) : null
    );
    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);

    const handleDateChange = (newDate: Dayjs | null) => {
        setSelectedDate(newDate);
        setOpenDatePicker(false);
        navigate(
          `/admin/app/attendance/${classId}/${newDate?.format(
            "YYYY-MM-DD")}?category=${searchParams.get('category') ?? 0}`
        );
        window.location.reload()
    };

    const handleCalendarClick = () => {
        setOpenDatePicker(true);
    };

  return (
    <div style={{ position: "relative" }}> 
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={selectedDate}
        onChange={handleDateChange}
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        sx={{ visibility: "hidden" }}
        showDaysOutsideCurrentMonth={false}
        slotProps={{
          rightArrowIcon: { color: "primary" },
          previousIconButton: { color: "primary" },
        }}
        slots={{
          day: (elem) => {
            return (
              <PickersDay
                day={elem.day}
                onDaySelect={(e) => {
                  handleDateChange(e);
                }}
                sx={
                  elem.selected
                    ? {
                        backgroundColor: "#854693",
                        color: "white",
                        display: "inline",
                      }
                    : unMarkedAttendance.some(
                        (x: any) =>
                          dayjs(x.date).toString() === elem.day.toString()
                      )
                    ? { backgroundColor: "#fbedeb", display: "inline" }
                    : { display: "inline" }
                }
                outsideCurrentMonth={elem.outsideCurrentMonth}
                showDaysOutsideCurrentMonth={false}
                isFirstVisibleCell={(elem.day as any).$D === 1}
                isLastVisibleCell={(elem.day as any).$D === 31}
              >
                {(elem.day as any).$D}
                {unMarkedAttendance.some(
                  (x: any) => dayjs(x.date).toString() === elem.day.toString()
                ) ? (
                  <div
                    style={{
                      backgroundColor: "#d75345",
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      margin: "auto",
                    }}
                  ></div>
                ) : null}
              </PickersDay>
            );
          },
        }}
      />
    </LocalizationProvider>
    <Filter
      onClick={handleCalendarClick}
      // disabled={categoryHours?.length > 0 ? false : true}
      sx={{
        position: "absolute",
        left: "0",
        top: "0",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "12px 16px 12px 16px",
      }}
    >
      <Typography
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="42px"
        width="42px"
        sx={{
          background: "#F3EDF4",
          borderRadius: "50%",
          transition: "0.5s ease all",
        }}
      >
        <CalendarTodayOutlinedIcon
          sx={{
            width: "16px",
            height: "16px",
            color: "#854693",
            transition: "0.5s ease all",
          }}
        />
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="2px"
        width="75px"
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Visby CF Bold, sans-serif",
          }}
        >
          {formatDate(selectedDate)} {/* Gün ve Ay */}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "12px",
            textTransform: "none",
            fontWeight: "500",
            color: "#6B696A",
            lineHeight: "16px",
          }}
        >
          {formatYear(selectedDate)} {/* Yıl */}
        </Typography>
      </Box>
    </Filter>
  </div>
  )
}

const formatDate = (date: Dayjs | null): string => {
    return date ? date.format("DD MMM") : "23 Aug"; // Gün ve ay, örn: '24 Aug'
};

const formatYear = (date: Dayjs | null): string => {
    return date ? date.format("YYYY") : "2024"; // Yalnızca yıl
};

const Filter = (props: any) => {
    const { children, sx,borderColor,disabled,onClick,open,modalContainerRef} = props;
      const handleClick = () => {
          if (!disabled && onClick) {
            onClick();
          }
        };
      return (
          <Box 
              ref={modalContainerRef}
              sx={{
                  position:"relative",
                  padding: "16px",
                  border: open?'none':`1px solid ${borderColor?borderColor:"#D4D4D4"}`,
                  boxShadow: open?"0px 23px 39px rgba(0, 0, 0, 0.08)":"none",
                  borderRadius:open?"16px 16px 0px 0px":"16px",
                  transition:"0.5s ease all",
                  whiteSpace: 'nowrap',
                  "&:hover": {
                      boxShadow: "0px 23px 39px rgba(0, 0, 0, 0.08)",
                      border:'none',
                      ".schoolOutlinedIconBox": {
                          background: "#468CBC",
                          transition:"0.5s ease all",
                        },
                      ".schoolOutlinedIcon":{
                        color:'#fff',
                        transition:"0.5s ease all",
                      },
                  },
                  ...(disabled && {
                      opacity: 0.5, // Örnek bir devre dışı bırakma efekti, isteğinize göre ayarlayabilirsiniz.
                      pointerEvents: 'none',
                  }),
                  ...sx
              }}
              onClick={handleClick} 
          >
           
              {children}
          </Box>
      )
  }