// import AuthPage from "../../403";
import { useEffect, useState } from "react";
import { IPermissionModel } from "../../permissions";
import { UserHasAnyOfPermissions } from "../hooks/userPermissions";

export function RoleProvider({
  children,
  permission,
}: {
  children: JSX.Element;
  permission: IPermissionModel[];
}) {

  const res = UserHasAnyOfPermissions(permission)

  // if(res){
    return children;
  // }else{
  //   // window.location.href = '/403';
  //   // return <></>;
  // }
}
