import React, { useContext, useState } from 'react';
import {
  MenuItem,
  Typography,
  Select,
  Table,
  TableBody as MaterialTableBody,
  TableContainer,
  TableRow,
  TextField,
  TableCell,
  Slide,
  FormControl,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Header from './Header';
import TableCellBody from '../../../../../../../components/custom-components/table-cell-body';
import { useGetAllWorkers, useGetWorkersByRole } from '../../../../../../../api/workers';
import { AttendanceContext } from '../../../../../../../context/AttendanceContext';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

enum StatusEnum {
  Full = 'Tam iştirak',
  Late = 'Erkən çıxma',
  Early = 'Gecikmə',
  Absent = 'İştirak etməyib',
}

enum StatusColors {
  Full = '#4BA458',
  Late = '#E7873C',
  Early = '#D54939',
  Absent = '#999898',
}

const sxAutocomplete = {
  '& .MuiAutocomplete-inputRoot': {
    padding: '0',
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
};

const sxtimeSelect = {
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      WebkitAppearance: "none",
      margin: 0,
    },
  '& input[type="number"]': {
    MozAppearance: "textfield",
  },
  padding: 0,
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

function TeacherTableList({maxHours}: any) {

  const {workers, setWorkers } = useContext(AttendanceContext);

  const {data: teachers, refetch: teachersRefetch, isLoading: teachersIsLoading} = useGetWorkersByRole('Teacher', false);
  const {data: mentors, refetch: mentorsRefetch, isLoading: mentorsIsLoading} = useGetWorkersByRole('Mentor', false);

  const [teacherHours, setTeacherHours] = useState<any>(workers?.find((x: any)=> x.workerType === "Teacher")?.totalHours ? dayjs().hour(workers?.find((x: any)=> x.workerType === "Teacher")?.totalHours).minute(workers?.find((x: any)=> x.workerType === "Teacher")?.totalMinutes) : null );
  const [mentorHours, setMentorHours] = useState<any>(workers?.find((x: any)=> x.workerType === "Mentor")?.totalHours ? dayjs().hour(workers?.find((x: any)=> x.workerType === "Mentor")?.totalHours).minute(workers?.find((x: any)=> x.workerType === "Mentor")?.totalMinutes) : null );

  const handleTeacherMentorChange = (newValue: any, type: string) => {
    setWorkers([...workers.filter((x: any)=> x.workerType !== type ), {
      workerId: newValue.id,
      name: newValue.name,
      surname: newValue.surname,
      totalMinutes: 0,
      totalHours: 0,
      workerType: type
    }])
  }

  const handleTeacherHoursChange = (newTime: any) => {
    if (!newTime) return;

    let selectedHours = newTime.hour();
    let selectedMinutes = newTime.minute();

    // Ensure time does not exceed maxHours
    if (selectedHours >= maxHours) {
      selectedHours = maxHours;
      selectedMinutes = 0; // Reset minutes to 00
    }

    // Format time correctly
    const formattedTime = dayjs().hour(selectedHours).minute(selectedMinutes);
    setWorkers(workers.map((x: any)=> {
      if(x.workerType === "Teacher" ){
        return {...x, totalHours: selectedHours, totalMinutes: selectedMinutes}
      }
      return x;
    }))
    setTeacherHours(formattedTime);
  };

  const handleMentorHoursChange = (newTime: any) => {
    if (!newTime) return;

    let selectedHours = newTime.hour();
    let selectedMinutes = newTime.minute();

    // Ensure time does not exceed maxHours
    if (selectedHours >= maxHours) {
      selectedHours = maxHours;
      selectedMinutes = 0; // Reset minutes to 00
    }

    // Format time correctly
    const formattedTime = dayjs().hour(selectedHours).minute(selectedMinutes);
    setWorkers(workers.map((x: any)=> {
      if(x.workerType === "Mentor" ){
        return {...x, totalHours: selectedHours, totalMinutes: selectedMinutes}
      }
      return x;
    }))
    setMentorHours(formattedTime);
  };

  console.log(workers?.find((x: any)=> x.workerType === "Teacher"))

  return (
    <TableContainer sx={{ marginBottom: '16px' }}>
      <Table
        sx={{
          width: '100%',
          border: 'none',
        }}
        aria-label="simple table"
      >
        <Header />
        <MaterialTableBody
          sx={{ fontSize: '14px', fontFamily: 'Visby CF Bold, sans-serif' }}
        >
          <TableRow>
            <TableCellBody width="5%">1</TableCellBody>
            <TableCellBody width="65%">
              <FormControl fullWidth>
                <Autocomplete
                  style={{width: '50%', paddingLeft: '5'}}
                  defaultValue={workers?.find((x: any)=> x.workerType === "Teacher")}
                  value={workers?.find((x: any)=> x.workerType === "Teacher")}
                  onChange={(event, newValue) => handleTeacherMentorChange(newValue, "Teacher")}
                  onOpen={()=> teachersRefetch()}
                  loading={teachersIsLoading}
                  options={teachers ? teachers : []}
                  getOptionLabel={(worker: any) =>
                    `${worker.name} ${worker.surname}`
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        '& .MuiInputBase-input': {
                          padding: '0px!important',
                        },
                      }}
                      {...params}
                      placeholder={'Axtar...'}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Typography
                            sx={{
                              color: '#000',
                              fontSize: '14px',
                              marginLeft: '8px',
                              paddingRight: '8px'
                            }}
                          >
                            Müəllim:
                          </Typography>
                        ),
                      }}
                    />
                  )}
                  sx={sxAutocomplete}
                />
              </FormControl>
            </TableCellBody>
            <TableCell>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  disabled={!workers?.find((x: any)=> x.workerType === "Teacher")}
                  value={teacherHours}
                  onChange={handleTeacherHoursChange}
                  ampm={false}
                  minutesStep={1}
                  slotProps={{
                    textField: {
                      variant: "standard", // Removes default outlined variant
                      InputProps: {
                        disableUnderline: true, // Removes underline
                        sx: {
                          border: "none", // Ensures no border
                          outline: "none", // No focus outline
                          "& .MuiOutlinedInput-notchedOutline": { border: "none" }, // For outlined variant (just in case)
                        },
                      },
                    },
                  }}
                />
              {/* <TextField
                // disabled={disabled}
                placeholder="00:00"
                sx={[
                  sxtimeSelect,
                  {
                    "& .MuiInputBase-input": {
                      padding: 0,
                      textAlign: "start",
                      height:"47px",
                      // color:isGreatherThanCategoryHour(roleName,totalHours!)?"red":""
                    },
                  },
                ]}
                type="text"
                variant="outlined"
                value={teacherHours}
                onChange={(e: any)=> handleHoursChange(e, "Teacher")}
              /> */}
              </LocalizationProvider>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCellBody width="5%">2</TableCellBody>
            <TableCellBody width="65%">
              <FormControl fullWidth>
                <Autocomplete
                  style={{width: '50%', paddingLeft: '5'}}
                  defaultValue={workers?.find((x: any)=> x.workerType === "Mentor")}
                  value={workers?.find((x: any)=> x.workerType === "Mentor")}
                  onChange={(event, newValue) => handleTeacherMentorChange(newValue, "Mentor")}
                  onOpen={()=> mentorsRefetch()}
                  loading={mentorsIsLoading}
                  options={mentors ? mentors : []}
                  getOptionLabel={(worker: any) =>
                    `${worker.name} ${worker.surname}`
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        '& .MuiInputBase-input': {
                          padding: '0px!important',
                        },
                      }}
                      {...params}
                      placeholder={'Axtar...'}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Typography
                            sx={{
                              color: '#000',
                              fontSize: '14px',
                              marginLeft: '8px',
                              paddingRight: '8px'
                            }}
                          >
                            Mentor:
                          </Typography>
                        ),
                      }}
                    />
                  )}
                  sx={sxAutocomplete}
                />
              </FormControl>
            </TableCellBody>
            <TableCell>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={mentorHours}
                  disabled={!workers?.find((x: any)=> x.workerType === "Mentor")}
                  onChange={handleMentorHoursChange}
                  ampm={false}
                  minutesStep={1}
                  slotProps={{
                    textField: {
                      variant: "standard", // Removes default outlined variant
                      InputProps: {
                        disableUnderline: true, // Removes underline
                        sx: {
                          border: "none", // Ensures no border
                          outline: "none", // No focus outline
                          "& .MuiOutlinedInput-notchedOutline": { border: "none" }, // For outlined variant (just in case)
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </TableCell>
          </TableRow>
        </MaterialTableBody>
      </Table>
    </TableContainer>
  );
}
export default TeacherTableList;
