import { useEffect, useRef, useState } from "react"
import Card, {ICardModel} from "./components/card";
import { Typography } from "@mui/material";

interface IModuleModal{
  cardModel: ICardModel,
  title: string,
  children: any,
  width: number,
  isSelect?: boolean,
  openModal?: boolean,
  setOpenModal?: any,
  text?: string
}

export default function Index({cardModel, title, children, width, isSelect=false, openModal, setOpenModal, text} : IModuleModal) {

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !(dropdownRef.current as any).contains(event.target) &&
      !document.querySelector(".MuiAutocomplete-popper")?.contains(event.target) &&
      !document.querySelector(".MuiDialog-root")?.contains(event.target)) {
        setOpenModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{marginRight: '16px', width: '230px', position: 'relative'}}>
      <Card {...cardModel} onClick={()=> setOpenModal(!openModal)} subtitle={text ?? ''}/>
      {
        openModal ? (
          <div ref={dropdownRef}>
            {
              isSelect ? (
                <>
                  <div style={{width: `${width}px`, position: 'absolute', overflow: 'hidden', top: '60px', zIndex: '100', backgroundColor: '#FFF', borderRadius: '0px 0px 24px 24px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    {children}
                  </div>
                </>
              ) : (
                <div style={{padding: '32px', width: `${width}px`, position: 'absolute', top: '55px', zIndex: '100', backgroundColor: '#FFF', borderRadius: '0px 24px 24px 24px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                  <span onClick={()=> setOpenModal(false)} style={{position: 'absolute', top: '12px', right: '12px', cursor: 'pointer'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect width="24" height="24" rx="12" fill="#F3EDF4"/>
                      <path d="M15.5936 7.5L12 11.0936L8.40643 7.5L7.5 8.40643L11.0936 12L7.5 15.5936L8.40643 16.5L12 12.9064L15.5936 16.5L16.5 15.5936L12.9064 12L16.5 8.40643L15.5936 7.5Z" fill="#854693"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.40643 7.02856L12 10.6221L15.5936 7.02856L16.9714 8.4064L13.3778 12L16.9714 15.5935L15.5936 16.9714L12 13.3778L8.40643 16.9714L7.02859 15.5935L10.6222 12L7.02859 8.4064L8.40643 7.02856ZM7.9714 8.4064L11.565 12L7.9714 15.5935L8.40643 16.0286L12 12.435L15.5936 16.0286L16.0286 15.5935L12.435 12L16.0286 8.4064L15.5936 7.97137L12 11.5649L8.40643 7.97137L7.9714 8.4064Z" fill="#854693"/>
                    </svg>
                  </span>
                  <Typography sx={{fontSize: '24px', fontWeight: '700', mb: '18px'}}>{title}</Typography>
                  {children}
                </div>
              )
            }
          </div>
          
        ) : null
      }
    </div>
  )
}
