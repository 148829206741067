import {
  Box,
  Tab,
  Tabs,
  Button
} from "@mui/material";
import { sxTab } from "./style";
import { StatusQuery } from "../../models/StatusQuery";
import { ClassesCount } from "../../models/ClassesCount";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


interface TabPanelProps {
  classesStatusQuery: number;
  classesCountByStatus:ClassesCount[]
  handleChangeStatusQuery: (event: React.SyntheticEvent, newValue: number) => void;
}
interface FormattedData {
  [key: string]: number;
}
export const TabHeader = (props:TabPanelProps) => {
  const { classesStatusQuery, handleChangeStatusQuery,classesCountByStatus} = props;
  const countData:FormattedData = {};
  classesCountByStatus.forEach(item => {
    countData[item.status.toLowerCase()] = item.count;
});
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 0px",
      }}
    >
      <Box>
        <Tabs value={classesStatusQuery} onChange={handleChangeStatusQuery} sx={{ minHeight: "48px" }}>
          <Tab sx={sxTab} label={`Aktiv qruplar(${countData.active})`} />
          <Tab sx={sxTab} label={`Açılacaq qruplar(${countData.new})`} />
          <Tab sx={sxTab} label={`Bitmiş qruplar(${countData.close})`}/>
        </Tabs>
      </Box>
      <Box>
          <Button variant="text" color="inherit" sx={{textTransform: 'none', display: 'flex', alignItems: 'end'}}>
            <FileDownloadOutlinedIcon sx={{color: '#468CBC', mr: 1}}/> Excel kimi yüklə
          </Button>
        </Box>
    </Box>
  );
};
