import React, { useEffect, useState } from "react";
import Header from "./components/header";
import { Box} from "@mui/material";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { BaseService } from "../../../../network/services/base/BaseService";
import Progress from "../../../../components/custom-components/progress";
import { Module } from "../../shared/continuity/models/Module";
import { Student } from "../../shared/continuity/models/Student";
import { Class } from "../../shared/continuity/models/Class";
import { CategoryHours } from "../../shared/continuity/models/CategoryHours";
import Alert from "../../../../components/custom-components/alert";
import { AlertColor } from "@mui/material/Alert";
import { lessonFormats } from "./data";
import { LessonFormat } from "../../shared/continuity/models/LessonFormat";
import { Teachers } from "../../shared/continuity/models/Teachers";
import StudentTableList from "../../shared/continuity/components/table/table-list/student-table-list";
import { ROLES } from "../../../../constants/auth/roles";
import { AuthState } from "../../../../components/layout-components/header";
import { useSelector } from "react-redux";
import { fetchWorkers } from "../../../../store/reducers/workerReducer";
import TeacherTableList from "../../shared/continuity/components/table/table-list/teacher-table-list";
import { RootState } from "../../../../store/reducers";
import { HeldModule } from "../../shared/continuity/models/HeldModule";
import InfoBanner from "./components/info-banner";
import { useBanner } from "../../../../contexts/BannerContext";

function Continuity() {
  const user = useSelector((state: AuthState) => state.auth.user);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const workers = useSelector((state: RootState) => state.worker.workers);
  const [value, setValue] = useState<number>(0);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const [role, setRole] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [classes, setClasses] = useState<Class[]>([]);
  const [teachers, setTeachers] = useState<Teachers[]>([]);
  const [heldModules, setHeldModules] = useState<HeldModule[]>([{}]);
  const [loading, setLoading] = useState<boolean>(false);
  const [format, setFormat] = useState<string>("");
  const [teacherStatus, setTeacherStatus] = useState<boolean>(false);
  const [selectedClassLoading, setSelectedClassLoading] =
    useState<boolean>(false);
  const [categoryHours, setCategoryHours] = useState<CategoryHours[]>([]);
  const [selectedClass, setSelectedClass] = useState<Class>({
    id: "",
    name: "",
    programId: 0,
    totalHours: 0,
    attendanceHours: 0,
  });
  const [teacherChecks, setTeacherChecks] = useState<any>({});
  const [isLessonsModalOpen, setIsLessonsModalOpen] = useState<boolean>(false);
  const [hasPratice, sethasPratice] = useState<boolean>(false);

  const [hasTheoric, sethasTheoric] = useState<boolean>(false);
  const [hasLab, setHasLab] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  const [index, setIndex] = useState<number>(-1);
  const [lessonFormat, setLessonFormat] = useState<LessonFormat>({
    name: "",
    value: -1,
  });
  
  const userId = user?.id;
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const today = `${year}-${month}-${day}`;

  const modulesByClassesIdService = new BaseService(
    `/api/classes/${selectedClass?.id}/modules?date=${today}`
  );
  const studentsService = new BaseService(
    `/api/classes/${selectedClass?.id}/students?date=${today}`
  );
  const classesByWorkerIdService = new BaseService(
    `/api/workers/login/classes`
  );
  const categoryHoursService = new BaseService(
    `/api/classes/${selectedClass?.id}/sessions-category?date=${today}`
  );
  const teachersByClassId = new BaseService(
    `/api/classes/${selectedClass?.id}/workers?date=${today}`
  );
  const modulesService = new BaseService(
    `/api/Programs/${selectedClass?.programId}/modules`
  );
  const heldModulesService = new BaseService(
    `/api/Classes/${selectedClass?.id}/held-modules`
  );
   
  const { bannerVisible} = useBanner();
  const getTeacherByClassId = async () => {
    const res = await teachersByClassId.getAll();
    setTeachers(res.data);
    let initialTeacherChecksObj: any = {};
    res.data?.forEach((item: any) => {
      initialTeacherChecksObj[item.roleName] = item.isAttendance;
    });
    setTeacherChecks(initialTeacherChecksObj);
    setTeacherStatus(true);
  };

  useEffect(() => {
    if (teacherStatus) {
      const userRole = teachers.find(
        (q: Teachers) => q.workerId === userId
      )?.roleName;
      if (userRole === ROLES.MENTOR) {
        setRole(userRole);
        if (hasPratice && hasTheoric) setFormat("Dərs və Praktika");
        else if (hasPratice) setFormat("Praktika");
        else if (hasLab) setFormat("Lab");
        else setFormat("");
      } else if (userRole === ROLES.MUELLIM) {
        setRole(userRole);
        if (hasTheoric) setFormat("Dərs");
        else setFormat("");
      }
    }
  }, [teacherStatus, categoryHours]);

  const getModules = async () => {
    try {
      const res = await modulesService.getAll();
      setModules(res.data);
    } catch (err) {
      return console.log(err);
    }
  };
  const getHeldModules = async () => {
    try {
      const res = await heldModulesService.getAll();
      setHeldModules(
        res.data?.map((item: any) => {
          return {
            moduleId: item.id,
            totalHours: item.totalHours,
            moduleName: `${item.version} - ${item.name}`,
          };
        })
      );
    } catch (err) {
      setHeldModules([{}]);
    }
  };
  const getModulesByClassesId = async () => {
    try {
      const res = await modulesByClassesIdService.getAll();
      // setModules(res.data);
    } catch (err) {
      return console.log(err);
    }
  };

  const getCategoryHours = async () => {
    try {
      const res = await categoryHoursService.getAll();
      setCategoryHours(res.data);
      let hasTheoric = res.data.find((q: any) => q.category == "Theoric");
      let hasPratice = res.data.find((q: any) => q.category == "Practice");
      let hasLab = res.data.find((q: any) => q.category == "Lab");
      sethasTheoric(hasTheoric ? true : false);
      sethasPratice(hasPratice ? true : false);
      setHasLab(hasLab ? true : false);
      const { selectedLessonFormats } = lessonFormats(
        hasTheoric,
        hasPratice,
        hasLab,
        res.data
      );
      setLessonFormat(selectedLessonFormats);
      // setLessonFormat();
    } catch (err) {
      setCategoryHours([]);
      sethasTheoric(false);
      sethasPratice(false);
      setHasLab(false);
      return console.log(err);
    }
  };

  const getStudents = async () => {
    try {
      const res = await studentsService.getAll();
      setStudents(res.data);
    } catch (err) {
      return console.log(err);
    }
  };

  const getClassesByWorkerId = async () => {
    try {
      const res = await classesByWorkerIdService.getAll();
      setClasses(res.data);
    } catch (err) {
      return console.log(err);
    }
  };

  useEffect(() => {
    if (selectedClass?.id) {
      setSelectedClassLoading(true);
      Promise.all([
        getModulesByClassesId(),
        getHeldModules(),
        getModules(),
        getStudents(),
        getCategoryHours(),
        getTeacherByClassId()
      ])
        .catch((err) => console.log(err))
        .finally(() => setSelectedClassLoading(false));
    }
  }, [selectedClass]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getClassesByWorkerId(), dispatch(fetchWorkers())])
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [reload]);

  useEffect(() => {
    const currentHour = new Date();
    const localHourFormatter = new Intl.DateTimeFormat("az-AZ", {
      timeZone: "Asia/Baku",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    const localTimeString = localHourFormatter.format(currentHour);

    setSelectedClass(classes[0]);
    classes?.map((clas: any) => {
      const apiStartHourString = `${clas.start?.slice(0, 8)}`;
      const apiEndHourString = `${clas.end?.slice(0, 8)}`;
      if (
        localTimeString >= apiStartHourString &&
        localTimeString <= apiEndHourString
      ) {
        setSelectedClass(clas);
        return;
      }
    });
  }, [classes]);

  useEffect(() => {
    if (modules?.length === 3) {
      setIndex(1);
    } else {
      setIndex(0);
    }
  }, [modules]);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const isGreatherThanCategoryHour = (roleName: string, totalHours: number) => {
    if (roleName === ROLES.MENTOR) {
      return (
        totalHours >
        (categoryHours.find((item) => item.category === "Practice")?.hour ||
          categoryHours.find((item) => item.category === "Lab")?.hour ||
          categoryHours.find((item) => item.category === "Theoric")?.hour ||
          0)
      );
    }
    if (roleName === ROLES.MUELLIM) {
      return (
        totalHours >
        (categoryHours.find((item) => item.category === "Theoric")?.hour ||
          categoryHours.find((item) => item.category === "Practice")?.hour ||
          categoryHours.find((item) => item.category === "Lab")?.hour ||
          0)
      );
    }
  };
  if (loading) {
    return <Progress />;
  }

  return (
    <>
        {classes.length > 0 ? (
          <>
            <Header
              setLessonFormat={setLessonFormat}
              setIndex={setIndex}
              getTeacherByClassId={getTeacherByClassId}
              setLoading={setLoading}
              setAlertInside={setAlertInside}
              setAlertOpen={setAlertOpen}
              selectedClass={selectedClass}
              lessonFormat={lessonFormat}
              index={index}
              students={students}
              modules={modules}
              heldModules={heldModules}
              setHeldModules={setHeldModules}
              getStudents={getStudents}
              today={today}
              userId={userId!}
              categoryHours={categoryHours}
              format={format}
              setFormat={setFormat}
              hasTheoric={hasTheoric}
              hasPratice={hasPratice}
              hasLab={hasLab}
              teachers={teachers}
              teacherChecks={teacherChecks}
              classes={classes}
              setSelectedClass={setSelectedClass}
              isGreatherThanCategoryHour={isGreatherThanCategoryHour}
              reload={reload}
              setReload={setReload}
            />
            {
              !bannerVisible && <InfoBanner setIsLessonsModalOpen={setIsLessonsModalOpen}/>
            }
            
            {selectedClassLoading ? (
              <Progress />
            ) : (
              <>
                <Box sx={{ width: "100%", marginBottom: "20px" }}>
                  <TeacherTableList
                    workers={workers}
                    teachers={teachers}
                    setTeachers={setTeachers}
                    categoryHours={categoryHours}
                    selectedClass={selectedClass}
                    isGreatherThanCategoryHour={isGreatherThanCategoryHour}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <StudentTableList
                    setTeachers={setTeachers}
                    teacherChecks={teacherChecks}
                    hasTheoric={hasTheoric}
                    hasPratice={hasPratice}
                    setHasTheoric={sethasTheoric}
                    hasLab={hasLab}
                    setHasLab={setHasLab}
                    students={students}
                    setStudents={setStudents}
                    categoryHours={categoryHours}
                    setCategoryHours={setCategoryHours}
                    selectedClass={selectedClass}
                    setSelectedClass={setSelectedClass}
                    lessonFormat={lessonFormat}
                    format={format}
                    isLessonsModalOpen={isLessonsModalOpen}
                    setIsLessonsModalOpen={setIsLessonsModalOpen}
                  />
                </Box>
              </>
            )}
          </>
        ) : (
          <Box sx={{textAlign:"center",fontFamily: 'Visby CF Medium, sans-serif'}}>
              Hazırda sizin heç bir aktiv qrupuvuz yoxdur
        </Box>
        )}
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default Continuity;
