import { Box } from '@mui/system';
import styles from './card.module.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export interface IMyrequestModel {
    id: string | null;
    status: string | null;
    enddate: string | null;
    startdate: string | null;
    description: string | null;
    daterange: number | null;
    title: string | null;
    file: boolean | null;
    openModal: (open: boolean) => void;
    type: string | null;
    percentage: number | null;
}

export default function Card({
    status,
    enddate,
    startdate,
    daterange,
    title,
    file,
    openModal,
    description,
    type,
    percentage
}: IMyrequestModel) {
    return ( 
        <>
       {type ==="normal" ? (<Box className={`${styles.card}`} onClick={() => openModal(true)}>
            <Box className={`${styles.titleline}`}>
                <h1 className={`${styles.title}`}>{title}</h1>
                <svg 
                    onClick={() => openModal(true)}
                    xmlns="http://www.w3.org/2000/svg" 
                    width="20" 
                    height="20" 
                    viewBox="0 0 20 20" 
                    fill="none" 
                    className={`${styles.button}`}>
                    <path 
                        d="M8.2806 5.41667L12.291 9.42708L8.2806 13.4375" 
                        stroke="#854693" 
                        strokeWidth="1.5"
                        strokeLinecap="round" 
                        strokeLinejoin="round" />
                </svg>
            </Box>
            <Box style={{ marginTop: "-9px" }}>
                <span className={`${styles.startdate}`}>{startdate}</span> -  
                <span className={`${styles.enddate}`}> {enddate}</span> 
                <span className={`${styles.daterange}`}> ({daterange} gün)</span>
            </Box>
            <Box className={`${styles.statusline}`}>
                <Box className={`${styles.status} ${
                    status === 'apply' ? styles.statusapply :
                    status === 'wait' ? styles.statuswait :
                    status === 'reject' ? styles.statusreject :
                    styles.statusunknown
                }`}>
                    {status === 'apply' ? 'Təsdiqlənib' :
                     status === 'wait' ? 'Gözləmədə' :
                     status === 'reject' ? 'Qəbul edilməyib' :
                     'Naməlum status'} 
                </Box>
                <Box sx={{ position: "relative" }}>
                    <AttachFileIcon 
                        sx={file ? { visibility: "visible" } : { visibility: "hidden" }} 
                        className={`${styles.fileicon}`} />
                </Box>
            </Box>
        </Box> ) :
        
         (<Box className={`${styles.card}`} onClick={()=>{}}>

<Box className={`${styles.titleline}`}>
                <h1 className={`${styles.title}`}>{title}</h1>
                <svg 
                    onClick={()=>{}}
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g clip-path="url(#clip0_13755_23491)">
<path d="M10.0001 18.3337C14.6025 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 5.39795 14.6025 1.66699 10.0001 1.66699C5.39771 1.66699 1.66675 5.39795 1.66675 10.0003C1.66675 14.6027 5.39771 18.3337 10.0001 18.3337Z" stroke="#BABABA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 13.3333V10" stroke="#BABABA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 6.66699H10.01" stroke="#BABABA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_13755_23491">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
                </svg>
            </Box>

            <Box style={{ marginTop: "-9px" }}>
                <span className={`${styles.startdate}`} style={{fontSize:"16px"}}>{percentage}</span>  
                <span className={`${styles.startdate}`} style={{fontSize:"14px"}}>% </span>  
                <span className={`${styles.daterange}`} style={{fontSize:"14px"}}>istifadə edilib</span>
            </Box>
            <Box sx={{width:"100%"}}>
                <div style={{height: "6px",
                alignSelf: "stretch",
                borderRadius:"10px",
                backgroundColor:"#F0F0F0",
              
                }} >
                    <div style={{backgroundColor:"#C24234",height:"6px",width:`${percentage}%`,borderRadius:"10px"}}></div>
                    </div>
                    </Box>
                    <Box sx={{display: '-webkit-box',

alignSelf: 'stretch', fontSize:"12px",marginTop:"-6px"}}>5 icazə haqqı</Box>

         </Box>)
        
        } 
        </> );
}
