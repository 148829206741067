import { Box} from "@mui/material";
import Button from "@mui/material/Button";
import { sxBox } from "./style";
interface FooterProps {
    handleClick:()=>void;
  }
function Footer(FooterProps:FooterProps) {
    const {handleClick}=FooterProps;
    return ( <Box sx={sxBox}>
       <Button
            className="saveBtn"
            sx={{
              background: "#854693",
              color: "#FFF",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "12px 24px",
              borderRadius: "12px",
              fontFamily: "Visby CF Bold, sans-serif",
              textTransform: "none",
              "&:hover": {
                background: "#6e3d7d",
              },
            }}
            onClick={handleClick}
          >
            Yadda saxla
          </Button>
    </Box> );
}
export default Footer;