import { apiRoutes

 } from "../apiRoutes";

import { IStudentAttendanceModel, IStudentModel  } from "../types";

import { useFetch } from "../utils/api/reactQuery";


export const useGetStudents= () => 
    useFetch <IStudentModel[]> (
        apiRoutes.students.getAll,undefined,[apiRoutes.students.base,undefined]
    )

export const useGetStudentDetails = (studentId:any,classId: any) =>
    useFetch <IStudentModel[]> (
        apiRoutes.students.getStudentDetails(studentId,classId),undefined
    );

export const useGetStudentAttendance = (id: any, classId: any) =>
    useFetch<IStudentAttendanceModel[]> (
        apiRoutes.students.getStudentAttendance(id,classId),undefined
    )

