import React from 'react';
import { Button, Grid, Typography } from '@mui/material';

interface ActionButtonsProps {
  handleSubmit: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ handleSubmit }) => {
  return (
    <Grid sx={{ marginLeft: '400px', gap: '16px' }} item xs={12}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{
          width: '105px',
          backgroundColor: '#F3EDF4',
          borderRadius: '12px',
          height: '48px',
          boxShadow: 'none',
          ':hover': {
            boxShadow: ' #a5a19d',
            backgroundColor: '#F3EDF4',
          },
        }}
      >
        <Typography
          sx={{
            color: 'black',
            fontFamily: 'Visby CF Bold',
            fontSize: '16px',
            textTransform: 'none',
          }}
        >
          Ləğv et
        </Typography>
      </Button>

      <Button
        sx={{
          marginLeft: '16px',
          width: '105px',
          backgroundColor: '#E9E9E9',
          borderRadius: '12px',
          height: '48px',
          boxShadow: 'none',
          ':hover': {
            boxShadow: ' #a5a19d',
            backgroundColor: '#E9E9E9',
          },
        }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        <Typography
          sx={{
            color: 'black',
            fontFamily: 'Visby CF Bold',
            textTransform: 'none',
          }}
        >
          Göndər
        </Typography>
      </Button>
    </Grid>
  );
};

export default ActionButtons;
