import { hours } from './pages/app-views/MrpManagement/mrp/data';
import { name } from './pages/app-views/EducationSystemManagement/program/module/add-module/util';
import { ModalData } from "./pages/app-views/EducationProcessesManagement/continuity-result-students/models/ModalData";

export let Months = {
  Yanvar: "january",
  Fevral: "february",
  Mart: "march",
  Aprel: "april",
  May: "may",
  Iyun: "june",
  Iyul: "july",
  Avqust: "august",
  Sentyabr: "september",
  Oktyabr: "october",
  Noyabr: "november",
  Dekabr: "december",
};

export let Days = {
  1: "B.e",
  2: "Ç.a",
  3: "Ç",
  4: "C.a",
  5: "C",
  6: "Ş",
  0: "B.g",
};

export let Years = [2023, 2024, 2025, 2026, 2025];

export interface IClassModel{
    className: string | null;
    currentHour: number | null;
    endDate: string | null;
    id: number | null;
    programId: number | null;
    programName: string | null;
    sessionName: string | null;
    startDate: string | null;
    studyCount: number | null;
    totalHour: number | null;
    totalModules: number | null;
    workers: IWorkerModel | null;
};
export interface IClassHeaderDetail {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  totalHours:number;
  attendanceRate: number;
  program: IProgramModel;
  session: ISessionModel;
  
}
export interface IClassCreateModel{
    name: string;
    programId: number;
    sessionId: number;
    roomId: number
}
export interface IClassModel {
  className: string | null;
  currentHour: number | null;
  endDate: string | null;
  id: number | null;
  programId: number | null;
  programName: string | null;
  sessionName: string | null;
  startDate: string | null;
  studyCount: number | null;
  totalHour: number | null;
  totalModules: number | null;
  workers: IWorkerModel | null;
}

export interface IClassCreateModel {
  name: string;
  programId: number;
  sessionId: number;
  roomId: number;
}

export interface IUpdateClassModel {
  name: string;
  programId: number;
  sessionId: number;
  roomId: number;
}

export interface IWorkerModel{
    email: string | null;
    id: number | null;
    name: string | null;
    role: string | null;
    roleId: number | null;
    surname: string | null;
    avatarColor:string | null;

}
export interface IWorkerModel {
  email: string | null;
  id: number | null;
  name: string | null;
  role: string | null;
  roleId: number | null;
  surname: string | null;
}

export interface ISupportModel {
  id: number | null;
  class: string | null;
  createdDate: string | Date;
  description: string | null;
  images: any;
  note: string | null;
  status: string | null;
  supportCategory: string | null;
  title: string | null;
  user: any;
}
export interface IProgramModel {
  id: number;
  name: string;
  totalHours: number;
  modules: IModuleModel[];
}
export interface IModuleModel {
  id: number;
  name: string;
  hours: number;
  version: number;
  isSurvey: boolean;
  subModules: ISubModuleModel[];
}
export interface ISubModuleModel {
    id: number;
    name: string;
    hours: number;
    version: number;
    topModuleId: number;
    isSurvey: boolean;
}
export interface ISurveyModel{
  id: number;
  className: string;
  moduleId:number;
  moduleName:string;
  date:number
  isSurvey:boolean
}

export interface IWorkerLoginClassModel{
    id: number | null,
    start: string | null,
    end: string | null,
    name: string | null,
    totalHours: number | null,
    programId: number | null,
    attendanceHours: number | null,
}
export interface IPermissionGroupsModel {
    id:number;
    name: string;
    description: string;
    userCount:number
    users:IWorkerModel[]
  }
export interface IProgramModel{
    id: number;
    name: string;
    totalHours: number;
    modules: IModuleModel[];
}

export interface IRoomesPlaning{
    id: number;
    name: string;
    sessions: ISession[]
  }
  export interface ISession{
    id: number;
    name: string;
    classes:IClass[]
  }
  export interface IClass{
    id: number;
    className:string
    programName:string
    programId:number
    sessionName:string
    totalModules:number
    studyCount: number;
    totalHour:number
    currentHour:number
    startDate: string;
    endDate: string;
    workers: IWorkerModel[]
  }
export interface IModuleModel {
    id: number;
    name: string;
    hours: number;
    version: number;
    isSurvey: boolean;
    subModules: ISubModuleModel[];
}
export interface ISubModuleModel {
    id: number;
    name: string;
    hours: number;
    version: number;
    topModuleId: number;
    isSurvey: boolean;
}
export interface IProjectModel{
    id: number | null;
    name: string | null;
};
export interface ISessionModel{
    id: number | null;
    name: string | null;
    details:any
};
export interface IRoomModel{
    id: number | null;
    name: string | null;
};

export interface IStudentModel {
    id: number;
    name:string;
    surname:string;
    email: string;
    phoneNumber: string;
    classId: number
    class: string
    className: string
    attendance: number
   
  }


  export interface IStudentAttendanceModel {
    map(arg0: (attendance: import("./pages/app-views/EducationProcessesManagement/continuity-result-students/models/ModalData").ModalData, index: number) => { id: number; date: string; attendanceHours: number; lessonHours: number; category: string; }): unknown;
    id: string,
    name: string,
    surname: string,
    phone?: string,
    eMail?: string,
    attendancePercent?: number,
    attendances: ModalData[]
}

export interface Attendance {
    date: string,
    attendanceHours: number,
    lessonHours: number,
    category: string
}


export interface StudentDetail {
    id: string;
    name: string;
    surname: string;
    eMail: string;
    phone: string | null;
    classes: Class[];
  }


  interface Class {
    id: string;
    name: string;
  }


//   {
//     "id": 5,
//     "name": "Fidan",
//     "surname": "Süleymanlı",
//     "eMail": "fidants@code.edu.az",
//     "phone": "(050)-802-87-31",
//     "classes": [
//       {
//         "id": 142,
//         "name": "P518"
//       }
//     ]
//   },




export interface ISupportCreateModel {}

export interface ISupportUpdateModel {}

export interface ISupportCategoriesModel {}
