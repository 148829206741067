import { useState } from 'react';
import { useForm, SubmitHandler} from "react-hook-form";
import Header from '../../../../components/core-components/header';
import { APP_PREFIX_PATH } from '../../../../configs/Appconfig';
import { IMentorsRequestModel } from '../types';
import { Grid, TextField } from "@mui/material";
import DatePicker from '../../../../components/core-components/datePicker';
import Button from "../../../../components/core-components/inputs/button";
import { sxField } from '../../MrpManagement/mrp/components/modal/style';
import Progress from '../../../../components/custom-components/progress';
import DataGrid from "../../../../components/custom-components/data-grid";
import { GridColDef } from '@mui/x-data-grid';
import { generateCalendarDates, getContractTypeLabel, isDayBusy } from '../utils';
import { ApiInstance } from '../../../../utils/api/api';
import uuid from 'react-uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { teacherMentorRequestSchema } from '../validations';

export default function Index() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [columns, setColumns] = useState<GridColDef[]>([])


  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMentorsRequestModel>({
    resolver: yupResolver(teacherMentorRequestSchema),
  });

  const onSubmit: SubmitHandler<IMentorsRequestModel> = async (data)=> {
    setIsLoading(true);
    try{
      
      const response = await ApiInstance.get('https://studioapi.code.az/api/workers/filteredDatas');
      const sortedData = response.data
        .filter((teacher: any) => teacher.roles.includes('muellim'))
        .filter((elem: any)=> `${elem.name} ${elem.surname}` === data.teacherName?.trim())
        .sort((a: any, b: any) => {
          const contractTypePriority = (type: any) => {
            if (type === 2) return 1; // Full-time
            if (type === 1) return 2; // Part-time
            if (type === 0) return 3; // Bitib
            return 4; // Bilinmir (undefined)
          };
          return contractTypePriority(a.contractType) - contractTypePriority(b.contractType);
        })
      setColumns([
        {
          field: 'date',
          headerName: '',
          flex: 1,
        },
        {
          field: 'field1',
          headerName: sortedData[0]?.name + " " + sortedData[0]?.surname,
          flex: 1,
          renderCell: (params) => {
            return (
              <div style={{ width: '100%', height: '100%', backgroundColor: params.row.field1 ? 'green' : 'red'}}>
              </div>
            );
          },
        },
        {
          field: "field2",
          headerName: sortedData[0]?.eMail,
          flex: 1,
          renderCell: (params) => {
            return (
              <div style={{ width: '100%', height: '100%', backgroundColor: params.row.field1 ? 'green' : 'red'}}>
              </div>
            );
          },
        },
        {
          field: "field3",
          headerName: getContractTypeLabel(sortedData[0]?.contractType),
          flex: 1,
          renderCell: (params) => {
            return (
              <div style={{ width: '100%', height: '100%', backgroundColor: params.row.field1 ? 'green' : 'red'}}>
              </div>
            );
          },
        },
      ])
      const dates = generateCalendarDates(data.startDate, data.endDate);
      const res: any = [];
      dates.forEach((elem: any)=> {
        const dayBusy = isDayBusy(elem, sortedData[0]);
        res.push({date: elem.toDateString(), field1: dayBusy, field2: dayBusy, field3: dayBusy, id: uuid()})
      })

      setData(res);
    }catch(err){
      console.error(err);
    }finally{
      setIsLoading(false)
    }
  }

  return (
    <>
      <Header links={[{name: 'Home', url: APP_PREFIX_PATH}]} current='Müəllimlər Availability'/>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4} sx={{mb:3}}>
          <Grid item xs={12}>
              <TextField
                label="Ad Soyad"
                sx={{...sxField, width: '100%'}}
                error={errors.teacherName?.message !== undefined}
                {...register("teacherName")}
              />
          </Grid>
          <Grid item xs={6}>
            <DatePicker error={errors.startDate?.message !== undefined} control={control} {...register("startDate")} label="Tarixdən" name="startDate"/>
            <span style={{fontSize: '14px', color: 'red'}}>{errors.startDate?.message}</span>
          </Grid>
          <Grid item xs={6}>
            <DatePicker error={errors.endDate?.message !== undefined} control={control} {...register("endDate")} label="Tarixinədək" name="endDate"/>
            <span style={{fontSize: '14px', color: 'red'}}>{errors.endDate?.message}</span>
          </Grid>
        </Grid>
        <Button type="submit" >Axtar</Button>
      </form>
      {
        isLoading ? (
          <Progress/>
        ) :
        data.length > 0 ? (
          <>
            <div style={{marginTop: '24px'}}>
              <DataGrid
                data={data}
                columnsData={columns}
                rowsPerPageOptions={[10]}
                pageSizeData={pageSize}
                setPageSize={setPageSize}
                autoHeight
              />
            </div>
          </>
        ) :null
      }
    </>
  )
}
