import React, { useContext, useEffect, useState } from 'react';
import ModuleModal from '../module-modal';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip
} from "@mui/material";
import ModuleModalImage from '../../assets/moduleModal.svg';
import LessonHours from '../../assets/lessonHours.svg';
import { AttendanceStudentsContext } from '../../../../../../context/AttendanceStudentsContext';
import Alert from '../../../../../../components/custom-components/alert';
import { AlertColor } from "@mui/material/Alert";
import PresenceModal from '../PresenceModal';
import { AttendanceContext } from '../../../../../../context/AttendanceContext';
import { useEndAttendance, useJoinClass, useSaveAttendance, useStartAttendance } from '../../../../../../api/attendance';
import { apiRoutes } from '../../../../../../apiRoutes';
import { useQueryClient } from 'react-query';

function ModalOpen({classId, classTimeSheetId, isLessonActive, setOpenModal, refetch, setAlertInside, setAlertOpen,} : any) {
  const mutation = useStartAttendance(classId,(oldData: any, newData: any) => newData);
  const {isActive, setIsActive} = useContext(AttendanceContext);
  const endLessonMutation = useEndAttendance(classTimeSheetId,(oldData: any, newData: any) => newData);
  const queryClient = useQueryClient();

  const handleLabStart = async () => {
    try{
      const res = await mutation.mutateAsync({
        sessionCategory: "Lab",
        heldModules: []
      })
      if(res.status === 204){
        setIsActive(true);
        refetch()
      }
    }catch(err){
      console.error(err)
    }finally{
      setOpenModal(false)
    }
  }

  const handleLabEnd = async ()=> {
    try{
      const res = await endLessonMutation.mutateAsync({
        classTimeSheetId: classTimeSheetId
      })
      // if(res.status === 201){
        setIsActive(false);
        setOpenModal(false);
        refetch()
      // }
    }catch(err){
      console.error(err);
      setAlertOpen(true);
      setAlertInside({
        text: 'Xəta baş verdi!',
        severity: 'error',
      });
    }
  }


 return(
  <>
  {
    !isLessonActive ? (
      <>
        {
          isActive ? (
            <Button
              onClick={() => handleLabEnd()}
              variant="contained"
              className="saveBtn"
              sx={{
                background: "#854693",
                color: "#FFF",
                padding: "8px 24px",
                borderRadius: "12px",
                fontFamily: "Visby CF Bold, sans-serif",
                textTransform: "none",
                mt: '21px',
                mb: '12px',
                width: '100%',
                "&:hover": {
                  background: "#6e3d7d",
                },
              }}
            >
              Labı bitir
            </Button>
          ) : (
            <Button
              onClick={() => handleLabStart()}
              variant="contained"
              className="saveBtn"
              sx={{
                background: "#854693",
                color: "#FFF",
                padding: "8px 24px",
                borderRadius: "12px",
                fontFamily: "Visby CF Bold, sans-serif",
                textTransform: "none",
                mt: '21px',
                mb: '12px',
                width: '100%',
                "&:hover": {
                  background: "#6e3d7d",
                },
              }}
            >
              Labı başlat
            </Button>
          )
        }
      
      </>
    ) : 'Hal hazırda bu qrupda lab başladıla bilməz.'
  }
  </>
  )
  }

export default function Index({isLessonActive, classTimeSheetId, classId, teacher, mentor, refetch} : any) {
    const {students, setIsChangeInAttendance, isChangeInAttendance} = useContext(AttendanceStudentsContext);
    const mutation = useSaveAttendance(classTimeSheetId,(oldData: any, newData: any) => newData);
    const {currentNum, isActive} = useContext(AttendanceContext)

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [activeHoursOpenModal, setActiveHoursOpenModal] = useState<boolean>(false);
    const [alertInside, setAlertInside] = useState({
      text: "",
      severity: "success" as AlertColor,
    });

    const handleSave = async () => {
      try{
        const res = await mutation.mutateAsync( students.map((st: any)=> {return {studyId: st.studyId, note: st.note, totalAttendanceHours: st.totalHours}}))
        if(res.status){
          setAlertInside({text: 'Yadda saxlanildi', severity: "success"});
          setAlertOpen(true);
          setIsChangeInAttendance(false);
        }
      }catch(err){
        setAlertInside({text: 'Xəta baş verdi', severity: "error"});
        setAlertOpen(true)
      }
    }

  return (
    <div>
      <Typography sx={{mb: '20px'}}>
        <span><b style={{fontWeight: 'bold'}}>Müəllim:</b> {teacher?.name} {teacher?.surname}</span>
        <br/>
        <span><b style={{fontWeight: 'bold'}}>Mentor: </b>{mentor?.name} {mentor?.surname}</span>
      </Typography>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}>
        <div style={{display: 'flex'}}>
          <ModuleModal setOpenModal={setOpenModal} openModal={openModal} cardModel={{image: ModuleModalImage, title: 'Dərs qeydiyyatı', subtitle: '', color: '#C6DBEA'}} title="" width={490} children={<ModalOpen setOpenModal={setOpenModal} refetch={refetch} setAlertOpen={setAlertOpen} setAlertInside={setAlertInside} isLessonActive={isLessonActive} classId={classId} classTimeSheetId={classTimeSheetId}/>}/>
          <ModuleModal setOpenModal={setActiveHoursOpenModal} openModal={activeHoursOpenModal} cardModel={{image: LessonHours, title: 'Aktivlik', subtitle: `İştirak saatı: ${currentNum}`, color: '#C7E3CB'}} title='' width={232} isSelect children={<PresenceModal setOpenModal={activeHoursOpenModal}/>}/>
        </div>
        <Tooltip title={ !isActive ? "Dərs aktiv deyil!" : !isChangeInAttendance ? "Davamiyyət daxil edin!" : ''}>
          <span>
          <Button
              variant="contained"
              className="saveBtn"
              onClick={handleSave}
              // disabled={true}
              disabled={isLessonActive || !isActive || !isChangeInAttendance}
              sx={{
                background: "#854693",
                color: "#FFF",
                padding: "8px 24px",
                borderRadius: "12px",
                fontFamily: "Visby CF Bold, sans-serif",
                textTransform: "none",
                mt: '21px',
                "&:hover": {
                  background: "#6e3d7d",
                },
              }}
            >
            Yadda saxla
            </Button>
            </span>
        </Tooltip>
      </div>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={()=> setAlertOpen(false)}
        />
      )}
    </div>
  )
}
