import { useRef, useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
import CheckIcon from "@mui/icons-material/Check";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Filter from "../../../../../../components/layout-components/filter";
import { CustomSelectModal } from "../CustomSelectModal";
import { lessonFormats } from "../../data";
import { sxFiltetText } from "./style";
import { AddModuleModal } from "../../../../../../components/custom-components/module-modal";
import { useNavigate, useParams } from "react-router-dom";
import { Module } from "../../../../shared/continuity/models/Module";
import { LessonFormat } from "../../../../shared/continuity/models/LessonFormat";
import { CategoryHours } from "../../../../shared/continuity/models/CategoryHours";
import { HeldModule } from "../../../../shared/continuity/models/HeldModule";
import { AddInput } from "../../../../shared/continuity/models/AddInput";
import { BaseService } from "../../../../../../network/services/base/BaseService";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

interface PropsModel {
  modules: Module[];
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  lessonFormat: LessonFormat;
  setLessonFormat: React.Dispatch<React.SetStateAction<LessonFormat>>;
  totalHours: any;
  categoryHours: CategoryHours[];
  hasTheoric: boolean;
  hasPratice: boolean;
  hasLab: boolean;
  heldModules: HeldModule[];
  setHeldModules: React.Dispatch<React.SetStateAction<HeldModule[]>>;
  hasCalendar: boolean;
  classId?: string;
}

function Filters(props: PropsModel) {
  const today = new Date();
  const params = useParams();
  const navigate = useNavigate();
  const {
    modules,
    lessonFormat,
    setLessonFormat,
    categoryHours,
    hasTheoric,
    hasPratice,
    hasLab,
    heldModules,
    setHeldModules,
    hasCalendar,
    classId,
  } = props;
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    params.date ? dayjs(params.date) : null
  );
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [unMarkedAttendance, setUnMarkedAttendance] = useState<any[]>([]);

  const formatDate = (date: Dayjs | null): string => {
    return date ? date.format("DD MMM") : "23 Aug"; // Gün ve ay, örn: '24 Aug'
  };

  const formatYear = (date: Dayjs | null): string => {
    return date ? date.format("YYYY") : "2024"; // Yalnızca yıl
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
    setOpenDatePicker(false);
    navigate(
      `/admin/app/classes/${classId}/class-sessions/${newDate?.format(
        "YYYY-MM-DD"
      )}`
    );
  };

  useEffect(() => {
    async function getData() {
      try {
        const classService = new BaseService(`api/Classes`);
        const res = await classService.getAll(
          `api/Classes/${classId}/unmarked-attendance-days`
        );
        if (res.statusCode === 200) {
          setUnMarkedAttendance(res.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
      }
    }
    if (classId != null || classId != undefined) {
      getData();
    }
  }, [classId]);

  const handleCalendarClick = () => {
    setOpenDatePicker(true);
  };

  const [addInputs, setAddInputs] = useState<AddInput[]>([{}]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLessonModalOpen, setIsLessonModalOpen] = useState(false);
  const { formats } = lessonFormats(hasTheoric, hasPratice, hasLab);

  useEffect(() => {
    setAddInputs(heldModules.map((item) => ({ ...item })));
  }, [isModalOpen]);
  const handleFilterClick = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleLessonFilterClick = () => {
    setIsLessonModalOpen(!isLessonModalOpen);
  };
  const handleChange = (value: string) => {
    const element = formats.find((item) => item.name === value);
    setLessonFormat(element!);
    setIsLessonModalOpen(false);
  };
  const modalContainerRef = useRef<HTMLDivElement | null>(null); 
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
        const modalContainer = modalContainerRef.current;
        if (isLessonModalOpen && modalContainer && !modalContainer.contains(event.target as Node)) {
          handleLessonFilterClick()
        }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
        document.removeEventListener("click", handleOutsideClick);
    };
}, [handleChange]);

  return (
    <Box display="flex" alignItems="flex-start" gap="16px">
      {
        hasLab && !hasTheoric?(
          ""
        ):(
          <>
          <Filter
            disabled={categoryHours.length > 0 ? false : true}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              padding: "12px 16px 12px 16px",
            }}
            onClick={handleFilterClick}
            open={isModalOpen}
          >
            <Typography
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="42px"
              width="42px"
              sx={{
                background:
                  heldModules[0]?.moduleName !== undefined ? "#4BA458" : "#FBEDEB",
                borderRadius: "50%",
              }}
            >
              {heldModules[0]?.moduleName !== undefined ? (
                <CheckIcon
                  sx={{ width: "20px", height: "20px", color: "#FFFFFF" }}
                />
              ) : (
                <ErrorOutlineSharpIcon
                  sx={{ width: "16px", height: "16px" }}
                  color="error"
                />
              )}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap="2px"
            >
              <Typography
                variant="h3"
                sx={sxFiltetText}
                lineHeight="24px"
                maxWidth="300px"
              >
                {heldModules[0]?.moduleName !== undefined
                  ? heldModules[0]?.moduleName.length > 20
                    ? heldModules[0]?.moduleName.slice(0, 28) + "..."
                    : heldModules[0]?.moduleName
                  : "Modul"}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "12px",
                  textTransform: "none",
                  fontWeight: "500",
                  color: "#6B696A",
                  lineHeight: "16px",
                }}
              >
                {heldModules[0]?.moduleName !== undefined
                  ? `Əlavə ${heldModules.length} modul, cəmi ${heldModules.reduce(
                      (sum: number, module: HeldModule) =>
                        sum + module.totalHours! || 0,
                      0
                    )} saat`
                  : "Qeyd edilməmişdir"}
              </Typography>
            </Box>
          </Filter>
          
          <AddModuleModal
            categoryHours={categoryHours}
            addInputs={addInputs}
            setAddInputs={setAddInputs}
            modules={modules}
            open={isModalOpen}
            onClose={() => handleFilterClick()}
            setHeldModules={setHeldModules}
          />
        </>
        )
      }
      <Filter
        modalContainerRef={modalContainerRef}
        onClick={handleLessonFilterClick}
        open={isLessonModalOpen}
        disabled={categoryHours.length > 0 ? false : true}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          padding: "12px 16px 12px 16px",
          zIndex:2
        }}
      >
        <Typography
          className="schoolOutlinedIconBox"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="42px"
          width="42px"
          sx={{
            background: "#EDF4F8",
            borderRadius: "50%",
            transition: "0.5s ease all",
          }}
        >
          <SchoolOutlinedIcon
            className="schoolOutlinedIcon"
            sx={{
              width: "16px",
              height: "16px",
              color: "#468CBC",
              transition: "0.5s ease all",
            }}
          />
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="2px"
        >
          <Typography
            variant="h3"
            sx={sxFiltetText}
            lineHeight="24px"
            maxWidth="300px"
          >
            {categoryHours.length > 0 ? lessonFormat.name : "Offline"}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "12px",
              textTransform: "none",
              fontWeight: "500",
              color: "#6B696A",
              lineHeight: "16px",
            }}
          >
            Dərs formatı
          </Typography>
        </Box>
        <ArrowDropDownIcon
          sx={{ width: "24px", height: "24px", color: "#6B696A" }}
        />
       <CustomSelectModal isOpen={isLessonModalOpen} handleChange={handleChange} data={formats} />
      </Filter>
      {hasCalendar && (
        <div style={{ position: "relative" }}> 
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              onChange={handleDateChange}
              open={openDatePicker}
              onClose={() => setOpenDatePicker(false)}
              sx={{ visibility: "hidden" }}
              showDaysOutsideCurrentMonth={false}
              slotProps={{
                rightArrowIcon: { color: "primary" },
                previousIconButton: { color: "primary" },
              }}
              slots={{
                day: (elem) => {
                  return (
                    <PickersDay
                      day={elem.day}
                      onDaySelect={(e) => {
                        handleDateChange(e);
                      }}
                      sx={
                        elem.selected
                          ? {
                              backgroundColor: "#854693",
                              color: "white",
                              display: "inline",
                            }
                          : unMarkedAttendance.some(
                              (x) =>
                                dayjs(x.date).toString() === elem.day.toString()
                            )
                          ? { backgroundColor: "#fbedeb", display: "inline" }
                          : { display: "inline" }
                      }
                      outsideCurrentMonth={elem.outsideCurrentMonth}
                      showDaysOutsideCurrentMonth={false}
                      isFirstVisibleCell={(elem.day as any).$D === 1}
                      isLastVisibleCell={(elem.day as any).$D === 31}
                    >
                      {(elem.day as any).$D}
                      {unMarkedAttendance.some(
                        (x) => dayjs(x.date).toString() === elem.day.toString()
                      ) ? (
                        <div
                          style={{
                            backgroundColor: "#d75345",
                            width: "5px",
                            height: "5px",
                            borderRadius: "50%",
                            margin: "auto",
                          }}
                        ></div>
                      ) : null}
                    </PickersDay>
                  );
                },
              }}
            />
          </LocalizationProvider>
          <Filter
            onClick={handleCalendarClick}
            disabled={categoryHours.length > 0 ? false : true}
            sx={{
              position: "absolute",
              left: "0",
              top: "0",
              display: "flex",
              alignItems: "center",
              gap: "12px",
              padding: "12px 16px 12px 16px",
            }}
          >
            <Typography
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="42px"
              width="42px"
              sx={{
                background: "#F3EDF4",
                borderRadius: "50%",
                transition: "0.5s ease all",
              }}
            >
              <CalendarTodayOutlinedIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  color: "#854693",
                  transition: "0.5s ease all",
                }}
              />
            </Typography>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap="2px"
              width="75px"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Visby CF Bold, sans-serif",
                }}
              >
                {formatDate(selectedDate)} {/* Gün ve Ay */}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "12px",
                  textTransform: "none",
                  fontWeight: "500",
                  color: "#6B696A",
                  lineHeight: "16px",
                }}
              >
                {formatYear(selectedDate)} {/* Yıl */}
              </Typography>
            </Box>
          </Filter>
        </div>
      )}
    </Box>
  );
}

export default Filters;
